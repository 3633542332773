import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const RectangleBox = styled(Box)(({ theme }) => ({
  // border: `2px solid ${alpha(theme.palette.primary.light, 1)}`,
  borderRadius: 50,
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%'
}));

const CircleBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.light,
  margin: 4,
  borderRadius: '50%',
  width: 20,
  height: 20,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const INC_VALUE = 10;

export default function SmallTimeSelector({ time, setTime }) {
  const controls = useAnimation();
  const { t } = useTranslation();

  const secondsToTime = (secs) => {
    secs = Math.round(secs);

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      m: minutes,
      s: seconds
    };
    return obj;
  };

  const twoDigit = (number) => {
    const twodigit = number >= 10 ? number : `0${number.toString()}`;
    return twodigit;
  };

  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <Stack spacing={0.5} justifyContent="space-between" alignItems="space-betweeen">
        <Typography
          variant="caption"
          color="primary.dark"
          sx={{ fontWeight: 100, marginBottom: 0 }}
          paragraph
        >
          {t('time')}
        </Typography>
        <Stack direction="row" justifyContent="center">
          <Box sx={{ minWidth: 48 }}>
            <motion.div animate={controls}>
              <Typography variant="h7" sx={{ fontWeight: 300, marginBottom: 0 }} paragraph>
                {twoDigit(secondsToTime(time).m)}:{twoDigit(secondsToTime(time).s)}
              </Typography>
            </motion.div>
          </Box>

          <Typography variant="h7" sx={{ fontWeight: 300, marginBottom: 0 }} paragraph>
            {secondsToTime(time).m ? 'min' : t('sec')}
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <CircleBox
          onClick={() => {
            if (time <= 3600) setTime(time + INC_VALUE);
            controls.start({
              y: [0, -5, 0],
              transition: { type: 'spring', duration: 0.5, bounce: 1 }
            });
          }}
        >
          <IconButton aria-label="increase-time" size="small">
            <KeyboardArrowUpIcon fontSize="small" />
          </IconButton>
        </CircleBox>
        <CircleBox
          onClick={() => {
            if (time > INC_VALUE) setTime(time - INC_VALUE);
            controls.start({
              y: [0, 5, 0],
              transition: { type: 'spring', duration: 0.5 }
            });
          }}
        >
          <IconButton aria-label="decrease-time" size="small">
            <KeyboardArrowDownIcon fontSize="small" />
          </IconButton>
        </CircleBox>
      </Stack>
    </Stack>
  );
}
