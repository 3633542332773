import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import Iconify from './Iconify';
import Timer from './Timer';

const CircleBox = styled(Box)(({ theme, size }) => {
  let wh = 80;
  if (size === 'm-large') wh = 90;
  else if (size === 'large') wh = 100;
  else if (size === 'small') wh = 60;

  return {
    borderRadius: '50%',
    width: wh,
    height: wh,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

export default function EmojiPersonaTime({
  persona,
  time,
  inactive,
  onClick,
  size = 'small',
  type
}) {
  const theme = useTheme();

  const variants = {
    selected: {
      opacity: 1,
      outlineOffset: -5,
      // border: `3px solid ${theme.palette.primary.dark}`,
      transition: {
        type: 'spring'
      }
    },
    unselected: {
      opacity: 0.5,
      border: 'none',
      transition: {
        type: 'spring'
      }
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'good':
        return 'emojione:crown';
      case 'bad':
        return 'emojione:crying-cat-face';
      case 'very-bad':
        return 'noto:fire';
      case 'slow':
        return 'fluent:animal-turtle-24-filled';
      case 'fast':
        return 'mdi:rabbit';
      case 'on-time':
        return 'bxs:time';
      default:
        return '';
    }
  };

  return (
    <AnimatePresence>
      {persona && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
        >
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Box
              sx={{ position: 'relative' }}
              component={motion.div}
              initial="selected"
              animate={inactive ? 'unselected' : 'selected'}
              variants={variants}
              whileHover={{ scale: 1.1 }}
              onClick={onClick}
            >
              <CircleBox size={size}>
                {persona.avatar ? (
                  <Box
                    component="img"
                    src={persona.avatar}
                    sx={{ height: '100%', clipPath: 'circle()' }}
                  />
                ) : (
                  <Typography
                    variant="h2"
                    color="primary.dark"
                    sx={{ fontWeight: 700, marginBottom: 0 }}
                    paragraph
                  >
                    {persona.name[0].toUpperCase()}
                  </Typography>
                )}
              </CircleBox>
              <Box
                sx={{
                  position: 'absolute',
                  top: -15,
                  width: size === 'small' ? 25 : 32,
                  height: size === 'small' ? 25 : 32,
                  left: 0,
                  right: 0,
                  margin: 'auto',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Iconify
                  icon={getIcon()}
                  width={size === 'small' ? 20 : 23}
                  height={size === 'small' ? 20 : 23}
                />
              </Box>
            </Box>
            <Timer time={persona.time} size={size} isPositive={time >= persona.time} />
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
