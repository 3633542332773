import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Skeleton, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createRoulette } from '../api/api';

const DEFAULT_ROOM_NAME = 'main-room';

export default function NewGroupRulexDialog({ open, handleClose, setOpenAlert, group }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const { t } = useTranslation();

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleCreate = () => {
    setLoading(true);
    createRoulette(group.id, name || t(DEFAULT_ROOM_NAME)).then((res) => {
      if (res.data) {
        navigate(`roulettes/${res.data.id}`);
      } else {
        setLoading(false);
        setOpenAlert(true);
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 8,
          px: 5,
          pt: 3,
          pb: 4
        }
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Typography variant="subtitle1">{t('new-rulex')}</Typography>

        <Stack justifyContent="center" alignItems="center" mt={5} mb={3} spacing={1}>
          <Avatar
            color="primary"
            sx={{ backgroundColor: (theme) => theme.palette.primary.main, width: 56, height: 56 }}
          >
            {group && group.name[0]}
          </Avatar>
          <Typography variant="h5" sx={{ fontWeight: 500 }} color="text.secondary">
            {group ? group.name : <Skeleton />}
          </Typography>
        </Stack>

        <Box mt={1} sx={{ width: '100%' }}>
          <TextField
            inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            size="small"
            placeholder={t(DEFAULT_ROOM_NAME)}
            value={name}
            onChange={handleChange}
          />
        </Box>
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} mt={6}>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <LoadingButton
          variant="contained"
          disableElevation
          loading={loading}
          onClick={handleCreate}
        >
          {t('create')}
        </LoadingButton>
      </Stack>
    </Dialog>
  );
}
