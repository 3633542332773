import { Box, Grid, Skeleton, styled, Tab, Tabs, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGroups } from '../../api/api';
import GeneralContext from '../../contexts/GeneralContext';
import UserContext from '../../contexts/UserContext';
import MeetingAvatar from '../MeetingAvatar/MeetingAvatar';
import NewGroupAvatar from '../MeetingAvatar/NewGroupAvatar';
import NewGroupRulexDialog from '../NewGroupRulexDialog';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { scale: 0 },
  show: { scale: 1 }
};

export default function Groups({ setOpenBackdrop }) {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);
  const generalContext = useContext(GeneralContext);

  const [tabValue, setTabValue] = useState('own');
  const [visibleGroups, setVisibleGroups] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);

    if (newValue === 'own') setVisibleGroups(generalContext.groups);
    else setVisibleGroups(generalContext.allGroups);
  };

  const handleClickOpenNewDialog = (group) => {
    setSelectedGroup(group);
    setOpenDialog(true);
  };

  const handleCloseNewDialog = () => {
    setSelectedGroup(null);
    setOpenDialog(false);
  };

  useEffect(() => {
    getGroups().then((res) => {
      if (res.data) {
        setVisibleGroups(res.data);
        generalContext.setGroups(res.data);
      }
    });
  }, []);

  return (
    <Box data-tut="groups" mt={10}>
      <Box mb={3}>
        <Typography variant="h3" noWrap sx={{ fontWeight: 100 }}>
          {t('grupos')}
        </Typography>

        {generalContext.allGroups && (
          <AntTabs value={tabValue} onChange={handleChangeTab}>
            <AntTab label={t('my-groups')} value="own" />
            <AntTab label={t('all-groups')} value="all" />
          </AntTabs>
        )}
      </Box>
      <Grid
        container
        spacing={6}
        component={motion.ol}
        variants={container}
        initial="hidden"
        animate="show"
      >
        <Grid item xs="auto" component={motion.div}>
          <NewGroupAvatar />
        </Grid>

        {!visibleGroups && (
          <Grid item xs component={motion.div}>
            <Skeleton variant="circular" width={70} height={70} />
          </Grid>
        )}
        {visibleGroups &&
          Boolean(visibleGroups.length) &&
          visibleGroups
            .sort((a, b) => {
              if (a.active === b.active) return 0;
              if (a.active) return -1;
              return 1;
            })
            .map((group, i) => (
              <Grid key={`avatar-${i}`} item xs="auto" component={motion.div} variants={item}>
                <MeetingAvatar
                  id={group.id}
                  title={group.name}
                  index={i}
                  setOpenBackdrop={setOpenBackdrop}
                  onClickNew={() => handleClickOpenNewDialog(group)}
                  setOpenAlert={() => {}}
                />
              </Grid>
            ))}

        {visibleGroups && visibleGroups.length === 0 && !userContext.user?.isAdmin && (
          <NoGroupMessage />
        )}
      </Grid>
      <NewGroupRulexDialog
        open={openDialog}
        handleClose={handleCloseNewDialog}
        groups={userContext.user?.isAdmin ? generalContext.allGroups : generalContext.groups}
        setOpenBackdrop={setOpenBackdrop}
        setOpenAlert={() => {}}
        group={selectedGroup}
      />
    </Box>
  );
}

const NoGroupMessage = () => {
  const { t } = useTranslation();
  return (
    <Grid item xs>
      <Typography variant="body2" color="text.secondary">
        {t('no-groups-long')}...
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {t('assigned-soon')}
      </Typography>
    </Grid>
  );
};

const AntTabs = styled(Tabs)(({ theme }) => ({
  // borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main
  }
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:hover': {
    color: theme.palette.primary.dark,
    opacity: 1
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.main
  }
}));
