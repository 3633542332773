import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStatistics } from '../../api/statisticsAPI';
import SatisfactionGrowth from './SatisfactionGrowth';
import TeamStatistics from './TeamStatistics';

export default function Statistics() {
  const [statistics, setStatistics] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const toStatistic = new Date();
    const from15PrevStatistic = new Date();
    from15PrevStatistic.setDate(from15PrevStatistic.getDate() - 105);

    getStatistics(from15PrevStatistic.toISOString(), toStatistic.toISOString()).then((res) => {
      if (res.data) setStatistics(res.data);
    });
  }, []);

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: 500 }} mb={3}>
        {t('statistics')}
      </Typography>

      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
        {statistics ? (
          statistics
            .filter((g) => g.stats?.avg.mood)
            .map((group, i) => {
              const { stats } = group;

              const avgSatisfaction = stats.avg.mood;

              const avgTime = stats.avg.time;

              const { totalTime } = stats.avg;

              const showStatistics =
                avgSatisfaction !== undefined || avgTime !== undefined || totalTime !== undefined;

              return (
                <TeamStatistics
                  groupName={group.name}
                  avgSatisfaction={avgSatisfaction}
                  avgTime={avgTime}
                  key={`stat-${i}`}
                />
              );
            })
        ) : (
          <>
            <Skeleton variant="rounded" width={'100%'} height={100} sx={{ borderRadius: 2 }} />
            <Skeleton variant="rounded" width={'100%'} height={100} sx={{ borderRadius: 2 }} />
          </>
        )}
      </Stack>

      <Box>
        <Box mt={3}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {t('satisfaction-evolution')}
          </Typography>
        </Box>

        <SatisfactionGrowth />
      </Box>
    </>
  );
}
