import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { childrenAnimation } from '../utils/animations';

function CurvedText({ text, arc, radius }) {
  const characters = text.split('');
  const degree = arc / characters.length;

  return (
    <Box
      component={motion.ol}
      variants={childrenAnimation.container}
      initial="hidden"
      animate="show"
    >
      {characters.map((char, i) => (
        <Box
          key={`curv-char-${i}`}
          component={motion.div}
          variants={childrenAnimation.item}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="subtitle2"
            key={`heading-span-${i}`}
            sx={{ color: (theme) => theme.palette.primary.darker }}
            style={{
              position: 'absolute',
              height: `${radius}px`,
              transform: `rotate(${degree * i - arc / 2}deg)`,
              transformOrigin: `0 ${radius}px 0`,
              fontSize: '10px'
            }}
          >
            {char}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

CurvedText.propTypes = {
  text: PropTypes.string.isRequired,
  arc: PropTypes.number, // how curved do you want the text
  radius: PropTypes.number // how big do you want the curve
};

CurvedText.defaultProps = {
  arc: 120,
  radius: 400
};

export default CurvedText;
