import axios from 'axios';
import env from '../env';
import resolve from './APIUtils';

const end = '/v1';

export async function getStatistics(from, to) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/groups/stats`, { params: { from, to } })
      .then((res) => res.data)
  );
}

export async function getPersonalMood(from, to) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/users/me/mood`, { params: { from, to } })
      .then((res) => res.data)
  );
}
