import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#acbfe2',
  light: '#92a6e7',
  main: '#788eed',
  dark: '#5f76f3',
  darker: '#4253b3',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#484848',
  main: '#212121',
  dark: '#000000',
  darker: '#091A7A',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#a5d6a7',
  light: '#81c784',
  main: '#66bb6a',
  dark: '#4caf50',
  darker: '#43a047',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#ef6c00',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const BACKGROUNDS = [PRIMARY.dark, SECONDARY.main, PRIMARY.light];

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const palette = {
  mode: 'light',
  common: { black: '#000', white: '#fff', paper: '#f3f3f3' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  backgrounds: BACKGROUNDS,
  divider: GREY[500_24],
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
