import socketIOClient, { io } from 'socket.io-client';
import env from '../env';
import { getLocalSession } from './setupAxios';

export function getTracer() {
  return socketIOClient(`${env.REACT_APP_SOCKET_URL}/events/tracer`, {
    reconnectionDelayMax: 10000,
    autoConnect: false,
    extraHeaders: {
      Authorization: `Bearer ${getLocalSession()}`
    }
  });
}

export function getRouletteEvents() {
  return io(`${env.REACT_APP_SOCKET_URL}/events/roulettes`, {
    reconnectionDelayMax: 10000,
    extraHeaders: {
      Authorization: `Bearer ${getLocalSession()}`
    }
  });
}
