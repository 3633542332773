import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import LanguagePopover from './LanguagePopover';

export default function SimpleLayout() {
  return (
    <>
      <AppBar position="static" elevation={0} color="transparent">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }} />
            <Box ml={4} display="flex" alignItems="center">
              <LanguagePopover />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
}
