import axios from 'axios';
import env from '../env';
import resolve from './APIUtils';

const end = '/v1';

export function getUnreadSuggestions() {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/notifications`)
      .then((res) => res.data)
  );
}

export function removeUnreadSuggestions() {
  return resolve(
    axios
      .delete(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/notifications`)
      .then((res) => res.data)
  );
}

export function removeUnreadActiveSuggestions() {
  return resolve(
    axios
      .delete(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/notifications/active`)
      .then((res) => res.data)
  );
}

export function removeUnreadSentSuggestions() {
  return resolve(
    axios
      .delete(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/notifications/sent`)
      .then((res) => res.data)
  );
}

export function removeUnreadClosedSuggestions() {
  return resolve(
    axios
      .delete(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/notifications/closed`)
      .then((res) => res.data)
  );
}

export function getActiveSuggestions(filters) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/suggestions`, {
        params: { ...filters, status: [0] }
      })
      .then((res) => res.data)
  );
}

export function getSentSuggestions(filters) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/suggestions`, { params: { ...filters, me: true } })
      .then((res) => res.data)
  );
}

export function getClosedSuggestions(filters) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/suggestions`, {
        params: { ...filters, status: [1, 2] }
      })
      .then((res) => res.data)
  );
}

export function getOwnSuggestions(id) {
  return resolve(
    axios.get(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/${id}`).then((res) => res.data)
  );
}

export function newSuggestion(name, description, priority) {
  return resolve(
    axios
      .post(`${env.REACT_APP_BACKEND_URL}${end}/suggestions`, { name, description, priority })
      .then((res) => res.data)
  );
}

export function removeSuggestion(id) {
  return resolve(
    axios.delete(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/${id}`).then((res) => res.data)
  );
}

export function acceptSuggestion(id, reviewedComment) {
  return resolve(
    axios
      .put(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/${id}/accept`, {
        reviewedComment
      })
      .then((res) => res.data)
  );
}

export function rejectSuggestion(id, reviewedComment) {
  return resolve(
    axios
      .put(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/${id}/reject`, {
        reviewedComment
      })
      .then((res) => res.data)
  );
}

export function likeSuggestion(id) {
  return resolve(
    axios.post(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/${id}/likes`).then((res) => res.data)
  );
}

export function unlikeSuggestion(id) {
  return resolve(
    axios
      .delete(`${env.REACT_APP_BACKEND_URL}${end}/suggestions/${id}/likes`)
      .then((res) => res.data)
  );
}
