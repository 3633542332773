import { useContext, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { getGroups } from '../../api/api';
import GeneralContext from '../../contexts/GeneralContext';
import TraceContext from '../../contexts/TraceContext';
import UserContext from '../../contexts/UserContext';
import ResponsiveAppBar from './ResponsiveAppBar';

export default function MainLayout() {
  const traceContext = useContext(TraceContext);
  const generalContext = useContext(GeneralContext);
  const userContext = useContext(UserContext);
  const openSugRef = useRef({}).current;
  openSugRef.value = generalContext;

  const rouletteListener = (args) => {
    getGroups().then((res) => {
      if (res.data) generalContext.setGroups(res.data);
    });
  };

  const suggestionCreatedListener = (args) => {
    if (!openSugRef.value.openSuggestions)
      openSugRef.value.setUnreadSuggestions({
        totalNum: openSugRef.value.unreadSuggestions?.totalNum || 0 + 1,
        active: openSugRef.value.unreadSuggestions?.active || 0 + 1
      });
    else {
      const newSuggestion = args.message.suggestion;

      switch (args.message.suggestion.status) {
        case 0:
          openSugRef.value.appendSuggestion(newSuggestion, 'activeSuggestions');
          if (userContext.user.id === newSuggestion.user.id)
            openSugRef.value.appendSuggestion(newSuggestion, 'sentSuggestions');

          break;
        case 1:
        case 2:
          openSugRef.value.appendSuggestion(newSuggestion, 'closedSuggestions');
          break;
        default:
          break;
      }
    }
  };

  const suggestionDeletedListener = (args) => {
    if (openSugRef.value.openSuggestions) {
      const { suggestionId, userId } = args.message;

      openSugRef.value.removeSuggestion(suggestionId, 'activeSuggestions');
      openSugRef.value.removeSuggestion(suggestionId, 'closedSuggestions');

      if (userContext.user.id === userId)
        openSugRef.value.removeSuggestion(suggestionId, 'sentSuggestions');
    }
  };

  const suggestionUpdatedListener = (args) => {
    if (!openSugRef.value.openSuggestions)
      openSugRef.value.setUnreadSuggestions({
        totalNum: openSugRef.value.unreadSuggestions?.totalNum || 0 + 1,
        closed: openSugRef.value.unreadSuggestions?.closed || 0 + 1
      });
    else {
      const { suggestion, userId } = args.message;

      openSugRef.value.removeSuggestion(suggestion.id, 'activeSuggestions');

      if (userContext.user.id === userId)
        openSugRef.value.updateSuggestion(suggestion, 'sentSuggestions');

      openSugRef.value.appendSuggestion(suggestion, 'closedSuggestions');
    }
  };

  const suggestionLikeChangeListener = (args) => {
    if (openSugRef.value.openSuggestions) {
      const { suggestion } = args.message;

      if (userContext.user.id === suggestion.user.id)
        openSugRef.value.updateSuggestion(suggestion, 'sentSuggestions');

      switch (suggestion.status) {
        case 0:
          openSugRef.value.updateSuggestion(suggestion, 'activeSuggestions');
          break;
        case 1:
        case 2:
          openSugRef.value.updateSuggestion(suggestion, 'closedSuggestions');
          break;
        default:
          break;
      }
    }
  };

  const socketEvents = [
    { id: 'rouletteCreated', callback: rouletteListener },
    { id: 'rouletteClosed', callback: rouletteListener },
    { id: 'suggestionCreated', callback: suggestionCreatedListener },
    { id: 'suggestionUpdated', callback: suggestionUpdatedListener },
    { id: 'suggestionDeleted', callback: suggestionDeletedListener },
    { id: 'suggestionLiked', callback: suggestionLikeChangeListener },
    { id: 'suggestionUnliked', callback: suggestionLikeChangeListener }
  ];

  useEffect(() => {
    if (traceContext.socketConnected) {
      socketEvents.forEach(({ id, callback }) => traceContext.join(id, callback));
    }

    return () => {};
  }, [traceContext.socketConnected]);

  return (
    <>
      <ResponsiveAppBar />
      <Outlet />
    </>
  );
}
