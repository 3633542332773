import { capitalize } from 'lodash';

// ----------------------------------------------------------------------

export function capitalizeEachWord(sentence) {
  return sentence
    .split(' ')
    .map((w) => capitalize(w))
    .join(' ');
}
