import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import UserContext from '../../contexts/UserContext';
import LoadingBox from '../LoadingBox';
import SuggestionCard from './SuggestionCard';
import { container, item, NoItemsFound } from './SuggestionsBox';

export function TabPanel(props) {
  const {
    children,
    value,
    title,
    subtitle,
    index,
    suggestions,
    onDelete,
    refresh,
    removeUnread,
    ...other
  } = props;

  const loading = !suggestions;
  const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    if (value === index && removeUnread) removeUnread();
  }, [value]);

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ width: '100%', height: '100%', p: 2 }}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 2, py: 2, height: '100%', overflow: 'scroll' }}>
          <Box mb={4}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          </Box>
          {loading ? (
            <LoadingBox />
          ) : (
            <Box>
              <Grid
                container
                spacing={3}
                component={motion.ul}
                variants={container}
                layout
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                <AnimatePresence>
                  {suggestions && suggestions.length ? (
                    suggestions.map((s, i) => (
                      <Grid
                        key={`${title}-${s.id}`}
                        item
                        sm={12}
                        md={12}
                        component={motion.div}
                        variants={item}
                        exit="hidden"
                        layout
                      >
                        <SuggestionCard
                          item={s}
                          isOwner={s.user.id === userContext.user.id}
                          onDelete={onDelete}
                          refresh={refresh}
                          isAdmin={userContext.user.isAdmin}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <NoItemsFound />
                    </Grid>
                  )}
                </AnimatePresence>
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
