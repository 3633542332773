import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Grid, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getPersonalMood } from '../../api/statisticsAPI';
import BajajAreaChartCard from './BajajAreaChartCard';

const SatisfactionGrowth = ({ isLoading }) => {
  const theme = useTheme();

  const [yourMood, setYourMood] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const toStatistic = new Date();
    const from30PrevStatistic = new Date();
    from30PrevStatistic.setDate(from30PrevStatistic.getDate() - 90);
    getPersonalMood(from30PrevStatistic.toISOString(), toStatistic.toISOString()).then((res) => {
      if (res.data) setYourMood(res.data);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ pt: '16px !important' }}>
            {yourMood && <BajajAreaChartCard data={yourMood} />}
          </Grid>
        </Grid>
      )}
    </>
  );
};

SatisfactionGrowth.propTypes = {
  isLoading: PropTypes.bool
};

export default SatisfactionGrowth;
