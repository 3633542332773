import React, { useState } from 'react';
import {
  getActiveSuggestions,
  getClosedSuggestions,
  getSentSuggestions
} from '../api/SuggestionsAPI';

const GeneralContext = React.createContext();

export const GeneralProvider = (props) => {
  const setGroups = (groups) => {
    setState((prevState) => ({
      ...prevState,
      groups
    }));
  };

  const setAllGroups = (allGroups) => {
    setState((prevState) => ({
      ...prevState,
      allGroups
    }));
  };

  const setLoadingSuggestions = (loading) => {
    setState((prevState) => ({
      ...prevState,
      loadingSuggestions: loading
    }));
  };

  const setSuggestions = (suggestions) => {
    setState((prevState) => ({
      ...prevState,
      suggestions
    }));
  };

  const setActiveSuggestions = (suggestions) => {
    setState((prevState) => ({
      ...prevState,
      activeSuggestions: suggestions
    }));
  };

  const setSentSuggestions = (suggestions) => {
    setState((prevState) => ({
      ...prevState,
      sentSuggestions: suggestions
    }));
  };

  const setClosedSuggestions = (suggestions) => {
    setState((prevState) => ({
      ...prevState,
      closedSuggestions: suggestions
    }));
  };

  const setUnreadSuggestions = (update) => {
    setState((prevState) => ({
      ...prevState,
      unreadSuggestions: { ...prevState.unreadSuggestions, ...update }
    }));
  };

  const setOpenSuggestions = (open) => {
    setState((prevState) => ({
      ...prevState,
      openSuggestions: open
    }));
  };

  const refreshAllSuggestions = () =>
    Promise.all([
      getSentSuggestions().then((res) => {
        if (res.data)
          setState((prevState) => ({
            ...prevState,
            sentSuggestions: res.data.items
          }));
      }),
      getActiveSuggestions().then((res) => {
        if (res.data)
          setState((prevState) => ({
            ...prevState,
            activeSuggestions: res.data.items
          }));
      }),
      getClosedSuggestions().then((res) => {
        if (res.data)
          setState((prevState) => ({
            ...prevState,
            closedSuggestions: res.data.items
          }));
      })
    ]);

  const appendSuggestion = (suggestion, selectedList) => {
    setState((prevState) => ({
      ...prevState,
      [selectedList]: [suggestion, ...prevState[selectedList]]
    }));
  };

  const removeSuggestion = (suggestionId, selectedList) => {
    setState((prevState) => {
      const list = { ...prevState }[selectedList];

      const index = list.map((s) => s.id).indexOf(suggestionId);

      if (index !== -1) list.splice(index, 1);

      return {
        ...prevState,
        [selectedList]: list
      };
    });
  };

  const updateSuggestion = (suggestion, selectedList) => {
    setState((prevState) => {
      const list = { ...prevState }[selectedList];

      if (list) {
        const index = list.map((s) => s.id).indexOf(suggestion.id);
        list[index] = suggestion;

        return {
          ...prevState,
          [selectedList]: list
        };
      }
      return prevState;
    });
  };

  const initialState = {
    groups: null,
    allGroups: null,
    suggestions: null,
    activeSuggestions: null,
    sentSuggestions: null,
    closedSuggestions: null,
    unreadSuggestions: null,
    openSuggestions: false,
    loadingSuggestions: false,
    setOpenSuggestions,
    setGroups,
    setAllGroups,
    setSuggestions,
    setUnreadSuggestions,
    setSentSuggestions,
    setActiveSuggestions,
    setClosedSuggestions,
    refreshAllSuggestions,
    setLoadingSuggestions,
    appendSuggestion,
    removeSuggestion,
    updateSuggestion
  };

  const [state, setState] = useState(initialState);

  return <GeneralContext.Provider value={state}>{props.children}</GeneralContext.Provider>;
};

export default GeneralContext;
