import { Box } from '@mui/material';
import Lottie from 'react-lottie-player';
import LottieLoading from '../assets/loadingLottie.json';

export default function LoadingBox() {
  return (
    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
      <Lottie
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        loop
        animationData={LottieLoading}
        play
        style={{ width: 450, height: 225 }}
      />
    </Box>
  );
}
