import React, { useState } from 'react';

const RouletteContext = React.createContext({
  winner: null,
  setWinner: () => {},
  participants: [],
  setParticipants: () => {},
  timer: {},
  setTimer: () => {}
});

export const RouletteProvider = (props) => {
  const setWinner = (w) => {
    setState((prevState) => ({
      ...prevState,
      winner: w
    }));
  };

  const setParticipants = (p) => {
    setState((prevState) => ({
      ...prevState,
      participants: p
    }));
  };

  const setTimer = (t) => {
    setState((prevState) => ({
      ...prevState,
      timer: t
    }));
  };

  const initialState = {
    winner: null,
    setWinner,
    participants: [],
    setParticipants,
    timer: null,
    setTimer
  };

  const [state, setState] = useState(initialState);

  return <RouletteContext.Provider value={state}>{props.children}</RouletteContext.Provider>;
};

export const RouletteConsumer = RouletteContext.Consumer;
export default RouletteContext;
