import { alpha, Box, Grid, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Roulette from '../components/Roulette/Roulette';
import TotalTimer from '../components/TotalTimer';
import WinnerInfo from '../components/WinnerInfo';
import RouletteContext from '../contexts/RouletteContext';

export const rouletteMobileVariants = {
  selected: () => ({
    x: '50%',
    y: '30%',
    scale: 0.8,
    transition: {
      type: 'spring'
    }
  }),
  unselected: {
    x: 0,
    scale: 1,
    transition: {
      type: 'spring'
    }
  }
};

export const mobileWinnerVariants = {
  selected: () => ({
    opacity: 1,
    scale: 1,
    x: -70,
    transition: {
      type: 'spring'
    }
  }),
  unselected: {
    x: 0,
    scale: 0,
    opacity: 0,
    transition: {
      type: 'spring'
    }
  }
};

export const statisticsMobileVariants = {
  selected: () => ({
    x: 200,
    y: 200,
    transition: {
      type: 'spring'
    }
  }),
  unselected: {
    x: 200,
    y: 0,
    transition: {
      type: 'spring'
    }
  }
};

export function MobileRoulette({
  startGlobalTimer,
  time,
  participants,
  winnerSelected,
  setWinnerSelected,
  setWinnerIndex,
  winnerIndex,
  isAdmin
}) {
  const rouletteContext = useContext(RouletteContext);

  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        {rouletteContext.winner !== null && (
          <Box
            component={motion.div}
            initial="unselected"
            animate={winnerSelected ? 'selected' : 'unselected'}
            sx={{ position: 'absolute' }}
            variants={mobileWinnerVariants}
          >
            <WinnerInfo persona={winnerIndex != null && participants[winnerIndex]} time={time} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box
          component={motion.div}
          exit={{ opacity: 0, x: 500, transition: { duration: 1 } }}
          display="flex"
          initial="unselected"
          sx={{ minWidth: 150, width: '100%', position: 'relative' }}
          justifyContent="center"
          mt={6}
        >
          <Box
            component={motion.div}
            animate={winnerSelected ? 'selected' : 'unselected'}
            sx={{ position: 'absolute' }}
            variants={statisticsMobileVariants}
            alignItems="flex-end"
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="overline"
              sx={{ color: (theme) => alpha(theme.palette.primary.main, 1) }}
            >
              {t('total-time')}
            </Typography>
            <TotalTimer start={startGlobalTimer} expectedTime={time * participants?.length} />
          </Box>

          <AnimatePresence>
            <Box
              component={motion.div}
              animate={winnerSelected ? 'selected' : 'unselected'}
              variants={rouletteMobileVariants}
            >
              <Roulette
                key={`${participants?.length}-${winnerIndex}`}
                participants={participants}
                setWinnerSelected={setWinnerSelected}
                winnerIndex={winnerIndex}
                setWinnerIndex={setWinnerIndex}
                time={time}
                isAdmin={isAdmin}
              />
            </Box>
          </AnimatePresence>
        </Box>
      </Grid>
    </Grid>
  );
}
