import { Box, Button, Stack, styled, Typography, useTheme } from '@mui/material';
import { amber, deepOrange, deepPurple, grey, pink } from '@mui/material/colors';
import { AnimatePresence, motion, useAnimation, useMotionValue } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserNameAudio } from '../../api/api';
import RouletteContext from '../../contexts/RouletteContext';
import SoundContext from '../../contexts/SoundContext';
import UserContext from '../../contexts/UserContext';
import WSContext from '../../contexts/WSContext';
import Iconify from '../Iconify';
import './roulette.scss';

const StyledMotion = styled(motion.ul)(
  ({ theme }) => `
  position: relative;
  width: 330px;
  height: 330px;
  padding: 0;
  border-radius: 50%;
`
);

const StyledLi = ({ children, numParticipants, mKey, index }) => {
  const angle = 360 / numParticipants;
  const rot = -90 + index * angle;
  const circleSize = 400 / 2;

  return (
    <Box
      key={mKey}
      component={motion.li}
      sx={{
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '10rem',
        height: '10rem',
        margin: 'calc(-10rem / 2)',
        transform: `rotate(${rot}deg) translate(${circleSize}px) rotate(90deg)`
      }}
    >
      {children}
    </Box>
  );
};

const Card = ({ el, active, index, time, winnerControl, winnerIndex, numParticipants }) => {
  const theme = useTheme();
  const backgroundColors = [
    theme.palette.primary.main,
    deepPurple.A100,
    amber[400],
    deepOrange[200],
    pink[200]
  ];

  return (
    <Box
      component={motion.div}
      {...(index === winnerIndex && { animate: winnerControl })}
      className="card"
      sx={{
        position: 'relative',
        opacity: active ? 1 : 0.2,
        width: 1000 / numParticipants,
        height: 1000 / numParticipants,
        maxWidth: 105,
        maxHeight: 105
      }}
    >
      {el.avatar ? (
        <Box
          component="img"
          src={el.avatar}
          sx={{
            height: '100%',
            width: '100%',
            clipPath: 'circle()',
            ...((!el.status || el.status === 'disconnected' || el.status === 'left') && {
              filter: 'grayscale(90%)'
            })
          }}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor:
              !el.status || el.status === 'disconnected' || el.status === 'left'
                ? grey[500]
                : backgroundColors[index % backgroundColors.length]
          }}
        >
          <Stack>
            {el.name.split(' ').map((word, i) => (
              <Typography
                key={`name-part-${i}`}
                variant="overline"
                sx={{ fontWeight: 700, marginBottom: 0 }}
                align="center"
                paragraph
                color="white"
              >
                {word}
              </Typography>
            ))}
          </Stack>
        </Box>
      )}

      {el.status === 'finished' && (
        <Box
          sx={{
            position: 'absolute',
            top: -18,
            width: 34,
            height: 34,
            border: '3px solid white',
            borderRadius: '50%',
            backgroundColor: 'white'
          }}
        >
          <Iconify
            icon={time - el.time >= 0 ? 'emojione:crown' : 'noto:fire'}
            width={24}
            height={24}
            color="teal"
          />
        </Box>
      )}
    </Box>
  );
};

const vPulse = {
  scale: [0.95, 1.1],
  transition: { duration: 0.6, yoyo: Infinity }
};

const rotateV = {
  start: ({ rotate }) => ({
    rotate,
    transition: { type: 'tween', duration: 2, ease: [0.5, 0.1, 0.15, 1] }
  }),
  stop: ({ endDeg }) => ({
    rotate: endDeg,
    transition: { type: 'tween', duration: 0 }
  })
};

const vWinner = {
  scale: [1, 1.1, 1],
  transition: { type: 'tween' }
};

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export default function Roulette({
  participants,
  setWinnerSelected,
  winnerIndex,
  setWinnerIndex,
  time,
  isAdmin
}) {
  const { t } = useTranslation();

  const soundContext = useContext(SoundContext);
  const rouletteContext = useContext(RouletteContext);
  const wsContext = useContext(WSContext);
  const userContext = useContext(UserContext);

  const isYourTurn = userContext.user?.id === participants[winnerIndex]?.id;

  const [isAnimationStart, setIsAnimationStart] = useState(false);

  const pulseControl = useAnimation();
  const rotateControl = useAnimation();
  const winnerControl = useAnimation();

  const spinCount = 2;
  const offset = (360 / participants.length) * winnerIndex;
  const endValue = useMotionValue(360 * spinCount - offset);

  const onAnimationStart = async () => {
    if (!isAnimationStart) {
      // get name del ganador
      // soundContext.playRouletteSpin();
      setIsAnimationStart(true);
      rotateControl.start('start');
      pulseControl.stop();

      setWinnerSelected(false);
    }

    setTimeout(() => {
      winnerControl.start(vWinner);
    }, 1600);

    setTimeout(() => {
      rotateControl.start('stop');
      pulseControl.start(vPulse);
      setIsAnimationStart(false);
      setWinnerSelected(true);
      wsContext.eventsSocket.emit('startTimer');

      soundContext.playWinner();

      getUserNameAudio(rouletteContext.winner.id).then((res) => {
        if (res.data) {
          const blob = new Blob([res.data], { type: 'audio/wav' });
          soundContext.playWAV(res.data);
        }
      });
    }, 2200);
  };

  useEffect(() => {
    if (participants && participants.length && rouletteContext.winner) {
      const wIndex = participants.find((p) => p.id === rouletteContext.winner.id)?.index;
      if (wIndex !== null) setWinnerIndex(wIndex);
    }
    return () => {};
  }, [participants, rouletteContext.winner]);

  useEffect(() => {
    if (winnerIndex !== null) {
      onAnimationStart();
    }

    return () => {};
  }, [winnerIndex]);

  useEffect(() => {
    pulseControl.start(vPulse);
  }, []);

  const isFirstSpin = !participants.filter((p) => ['finished', 'winner'].includes(p.status)).length;

  const isFinished =
    participants.filter((p) => p.status === 'finished').length ===
    participants.filter((p) => p.status !== 'left' && p.status !== 'disconnected').length;

  const isLastSpin =
    participants.filter((p) => p.status === 'finished').length ===
    participants.filter((p) => p.status !== 'left' && p.status !== 'disconnected').length - 1;

  const getSpinMessage = () => {
    if (isFirstSpin && !rouletteContext.winner) return t('rotate');
    if (!isFinished) return t('next');
    return t('view-results');
  };

  return (
    <motion.div
      className="container roulette"
      initial={{
        opacity: 1
      }}
      animate={{
        opacity: 1,
        rotate: [0, -20, 0],
        transition: {
          type: 'spring',
          bounce: 1,
          duration: 2
        }
      }}
      exit={{
        opacity: 1
      }}
    >
      <div className="circleWrap">
        <motion.div className="circleInner">
          {/* //ERROR */}
          <AnimatePresence>
            {!isAnimationStart && (
              <Button
                disabled={!isYourTurn && !isAdmin}
                disableFocusRipple
                disableRipple
                onClick={() => {
                  if (isYourTurn || isAdmin) {
                    setWinnerSelected(false);
                    if (isFinished) wsContext.eventsSocket.emit('close', { type: 'roulette' });
                    else if (winnerIndex !== undefined)
                      wsContext.eventsSocket.emit('next', { winnerId: rouletteContext.winner?.id });
                  }
                }}
                variant="overline"
                component={motion.div}
                whileHover={{ scale: 1.1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: [1, 0] }}
                className="cta"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                style={{
                  x: '-50%',
                  y: -30
                }}
              >
                {getSpinMessage()}
              </Button>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {!isAnimationStart && rouletteContext.winner && !isFinished && !isLastSpin && (
              <Button
                variant="subtitle"
                color="text.secondary"
                disabled={!isYourTurn && !isAdmin}
                onClick={() => {
                  if (isYourTurn || isAdmin) {
                    setWinnerSelected(false);
                    setWinnerIndex(null);

                    wsContext.eventsSocket.emit('skip', { winnerId: rouletteContext.winner?.id });
                  }
                }}
                component={motion.div}
                whileHover={{ scale: 1.1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="skip"
                style={{
                  x: '-50%',
                  y: 30
                }}
                sx={{
                  fontSize: 10,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                {t('skip-turn')}
              </Button>
            )}
          </AnimatePresence>

          {/* //ERROR */}

          {/* <motion.div className="cta" style={{ x: '-50%', y: '-50%' }} animate={pulseControl}>
            {!isAnimationStart && (winnerIndex !== undefined ? 'Siguiente' : '¡Fin!')}
          </motion.div> */}
        </motion.div>

        <StyledMotion
          className="circle"
          custom={{
            rotate: [0, endValue.current],
            endDeg: endValue.current
          }}
          animate={rotateControl}
          variants={rotateV}
          style={{ x: '-50%', y: '-50%' }}
        >
          {participants.map((el, i) => (
            <StyledLi
              key={`${el.id}-part-li-${i}`}
              mKey={`${el.id}-part-li-${i}`}
              index={i}
              numParticipants={participants.length}
            >
              <Card
                el={el}
                active={!el.speaked}
                index={i}
                time={time}
                winnerControl={winnerControl}
                winnerIndex={winnerIndex}
                numParticipants={participants.length}
              />
            </StyledLi>
          ))}
        </StyledMotion>

        {/* <Box
          className="circle"
          component={motion.div}
          animate={{ transition: { duration: 0.6, yoyo: Infinity } }}
        >
          <StyledWinner numParticipants={participants.length}>
            <WinnerCircle />
          </StyledWinner>
        </Box> */}
      </div>
    </motion.div>
  );
}
