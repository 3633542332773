import axios from 'axios';
import env from '../env';

const setAxiosToken = (token) => {
  if (!token) axios.defaults.headers.Authorization = undefined;
  else axios.defaults.headers.Authorization = `Bearer ${token}`;
};

export const getLocalSession = () => localStorage.getItem('authentication-token');

export const setLocalSession = (token) => {
  setAxiosToken(token);
  localStorage.setItem('authentication-token', token);
};

export const removeLocalSession = () => {
  setAxiosToken(null);
  localStorage.removeItem('authentication-token');
};

export const setupAxios = () => {
  if (env.REACT_APP_API_HOST) axios.defaults.baseURL = env.REACT_APP_API_HOST;
  const existingToken = getLocalSession();
  if (existingToken) setAxiosToken(existingToken);

  const axiosInterceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) window.location.href = '/no-connection';

      if (window.location.pathname === '/login')
        axios.interceptors.response.eject(axiosInterceptor);
      else if (error.response && error.response.status === 401) {
        removeLocalSession();
        localStorage.setItem('originLink', window.location.pathname);
        if (window.location.pathname !== '/login') window.location.href = '/login';
      } else throw error;
    }
  );
};
