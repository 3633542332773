import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { amber, deepOrange, green, pink, red } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './meeting-avatar.scss';

const backgroundColors = [red[400], green[400], amber[400], deepOrange[200], pink[200]];

const pressVariants = {
  rest: { scale: 1, right: 0 },
  hover: {
    scale: 1.15,
    transition: { type: 'spring' }
  }
};

const textBoxPressVariants = {
  rest: { scale: 1, opacity: 1, right: 0 },
  hover: {
    right: 150
  }
};

const textPressVariants = {
  rest: { scale: 1, opacity: 1 },
  hover: {
    fontWeight: 700,
    right: 150
  }
};

const joinVariants = {
  rest: { opacity: 0 },
  hover: { opacity: 1 }
};

const titleVariants = {
  rest: { opacity: 1 },
  hover: { opacity: 0 }
};

const backgroundVariants = {
  rest: {
    color: 'grey',
    x: 0,
    transition: {
      duration: 2,
      type: 'tween',
      ease: 'easeIn'
    }
  },
  hover: {
    scale: 1.08,
    transition: {
      type: 'spring',
      duration: 0.1
    }
  }
};

const Pulse = ({ index }) => {
  const theme = useTheme();

  const backgroundColors = [red[200], green[300], amber[400], deepOrange[200], pink[200]];

  return (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: alpha(backgroundColors[index % backgroundColors.length], 0.4),
        borderRadius: 3
      }}
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ repeat: Infinity, duration: 2 }}
      variants={backgroundVariants}
    />
  );
};

const ActiveMeetingAvatar = ({ title, subtitle, active, index, redirectId }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Stack
      component={motion.div}
      sx={{ position: 'relative', cursor: 'pointer', width: 130 }}
      spacing={2.5}
      justifyContent="center"
      alignItems="center"
      initial="rest"
      whileHover="hover"
      animate="rest"
      exit="rest"
      onClick={() => navigate(`/roulettes/${redirectId}`)}
    >
      <Box
        sx={{ position: 'relative', width: 100, height: 70 }}
        component={motion.div}
        variants={pressVariants}
      >
        <Box component={motion.div} variants={titleVariants}>
          <Typography
            variant="overline"
            align="center"
            noWrap
            sx={{
              color: 'white',
              opacity: 0.8,
              cursor: 'pointer',
              position: 'absolute',
              width: '100%',
              zIndex: 99,
              px: 2,
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          component={motion.div}
          variants={titleVariants}
          sx={{
            position: 'absolute',
            zIndex: 99,
            left: 0,
            right: 0,
            textAlign: 'center',
            margin: 'auto',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        >
          {/* <Typography variant="h2" sx={{ color: 'white', opacity: 0.8, cursor: 'pointer' }}>
            {title && title[0]}
          </Typography> */}
          {/* <Typography variant="overline" sx={{ color: 'white', opacity: 0.8, cursor: 'pointer' }}>
            {subtitle}
          </Typography> */}
        </Box>
        <Box
          component={motion.div}
          variants={joinVariants}
          sx={{
            position: 'absolute',
            zIndex: 99,
            left: 0,
            right: 0,
            textAlign: 'center',
            margin: 'auto',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        >
          <Typography variant="overline" sx={{ color: 'white', opacity: 0.8, cursor: 'pointer' }}>
            Unirse
          </Typography>
        </Box>

        <Box
          component={motion.div}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: backgroundColors[index % backgroundColors.length],
            borderRadius: 3
          }}
          whileTap={{ scale: 0.95 }}
          animate="rest"
          variants={backgroundVariants}
        />

        <Pulse index={index} />
      </Box>
      <Box
        component={motion.div}
        variants={textBoxPressVariants}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Typography
          component={motion.div}
          variants={textPressVariants}
          variant="overline"
          sx={{ color: 'text.secondary', fontWeight: 200, cursor: 'pointer' }}
          align="center"
        >
          {title}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ActiveMeetingAvatar;
