import React, { useState } from 'react';
import { getUserInfo } from '../api/AuthenticationAPI';
import { capitalizeEachWord } from '../utils/formatString';

const UserContext = React.createContext({
  user: null,
  error: false,
  getUserData: () => {}
});

const verifiedRoles = [
  'chapter-bc',
  'chapter-data',
  'chapter-dev',
  'chapter-design',
  'chapter-social',
  'admin'
];

export const UserProvider = (props) => {
  const getUserData = () =>
    getUserInfo().then((res) => {
      if (res.data) {
        const userData = { ...res.data };
        const extraRoles = [];

        verifiedRoles.forEach((v) => {
          if (userData.roles?.includes(v)) {
            extraRoles.push(v);
          }
        });

        userData.name = capitalizeEachWord(userData.name);
        userData.isAdmin = userData.roles?.includes('admin');
        userData.isEditor = userData.roles?.includes('editor');
        userData.isScrumLeader = userData.roles?.includes('scrum-leader');
        userData.coder = userData.roles?.includes('coder');

        userData.extraRoles = extraRoles;

        setState((prevState) => ({
          ...prevState,
          user: userData
        }));
      } else
        setState((prevState) => ({
          ...prevState,
          error: true
        }));

      return res;
    });

  const initialState = {
    user: null,
    error: false,
    getUserData
  };

  const [state, setState] = useState(initialState);

  return <UserContext.Provider value={state}>{props.children}</UserContext.Provider>;
};

export const UserConsumer = UserContext.Consumer;
export default UserContext;
