import { Typography, Box, useTheme, alpha } from '@mui/material';
import React, { useState, useEffect } from 'react';

const twoDigit = (number) => {
  const twodigit = number >= 10 ? number : `0${number.toString()}`;
  return twodigit;
};

const Timer = ({ time, size, isPositive, color, variant }) => {
  const theme = useTheme();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (time === undefined || time === null) return;
    const tmpTime = Math.abs(time);
    const min = Math.trunc(tmpTime / 60);
    const sec = tmpTime - min * 60;
    setMinutes(min);
    setSeconds(sec);
  }, [time]);

  const getTextSize = () => {
    switch (size) {
      case 'small':
        return 'caption';
      case 'm-large':
        return 'h5';
      case 'large':
        return 'h3';
      case 'extra-large':
        return 'h2';
      default:
        return 'h4';
    }
  };

  const getTimerPadding = (theme) => {
    switch (size) {
      case 'small':
        return theme.spacing(0, 1, 0.1, 1);
      case 'm-large':
        return theme.spacing(0.5, 1.5, 0.5, 1.5);
      case 'large':
        return theme.spacing(1, 2, 1, 2);
      default:
        return theme.spacing(1, 2, 1, 2);
    }
  };

  return (
    <Box
      sx={{
        width: 'fit-content',
        textAlign: 'center',
        borderRadius: 50,
        padding: (theme) => getTimerPadding(theme),
        ...(variant !== 'standard' && {
          backgroundColor: (theme) =>
            isPositive
              ? alpha((color && color.light) || theme.palette.primary.light, 0.4)
              : alpha(theme.palette.error.light, 0.4)
        })
      }}
    >
      <Typography
        variant={getTextSize()}
        sx={{
          marginTop: '0 !important',
          fontWeight: 700,
          color: isPositive
            ? alpha((color && color.main) || theme.palette.primary.main, 0.75)
            : alpha(theme.palette.error.main, 0.75)
        }}
      >
        {twoDigit(minutes)}:{twoDigit(seconds)}
      </Typography>
    </Box>
  );
};

export default Timer;
