import axios from 'axios';
import env from '../env';
import resolve from './APIUtils';

const end = '/v1';

export async function getLoginEndpoint() {
  return resolve(axios.get(`${env.REACT_APP_BACKEND_URL}${end}/login`).then((res) => res.data));
}

export async function login(username, password) {
  return resolve(
    axios
      .post(`${env.REACT_APP_BACKEND_URL}${end}/login`, {
        username,
        password
      })
      .then((res) => res.data)
  );
}

export async function generateLoginToken(code, codeVerifier) {
  return resolve(
    axios
      .post(`${env.REACT_APP_BACKEND_URL}${end}/login`, { code, codeVerifier })
      .then((res) => res.data)
  );
}

export async function getUserInfo() {
  return resolve(axios.get(`${env.REACT_APP_BACKEND_URL}${end}/users/me`).then((res) => res.data));
}

const authenticationAPI = { login, generateLoginToken, getUserInfo };

export default authenticationAPI;
