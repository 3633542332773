import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import { blueGrey, deepOrange, red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import WSContext from '../contexts/WSContext';
import SmallTimeSelector from './SmallTimeSelector';

export default function CreatePollForm(props) {
  const { t } = useTranslation();
  const { onClose, open } = props;

  const wsContext = React.useContext(WSContext);
  const userContext = React.useContext(UserContext);

  const [question, setQuestion] = React.useState('');
  const [answers, setAnswers] = React.useState(['', '']);
  const [time, setTime] = React.useState(10);

  const clear = () => {
    setQuestion('');
    setAnswers(['', '']);
    setTime(10);
  };

  const handleClose = () => {
    clear();
    onClose();
  };

  const handleChangeAnswer = (event, i) => {
    const newAnswers = [...answers];
    newAnswers[i] = event.target.value;
    setAnswers(newAnswers);
  };

  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const handleAddAnswer = () => {
    setAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers.push('');
      return newAnswers;
    });
  };

  const handleRemoveAnswer = (index) => (index) => {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1);
    setAnswers(newAnswers);
  };

  const isDisabled = answers.filter((a) => a !== '').length < 2 || !question;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 5,
          p: 1
        }
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        {t('new-poll')}
        <Stack direction="row" spacing={1} alignItems="center" mt={1}>
          <Avatar alt="creator picture" sx={{ width: 28, height: 28, bgcolor: deepOrange[300] }}>
            {userContext.user?.name[0].toUpperCase()}
          </Avatar>

          <Typography variant="subtitle2" color="text.secondary">
            {t('created-by')} {userContext.user?.name}
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                fullWidth
                color="primary"
                label={t('question')}
                value={question}
                onChange={handleChangeQuestion}
              />
            </Grid>
            <Grid item xs="auto" sx={{ height: '100%' }}>
              <SmallTimeSelector time={time} setTime={setTime} />
            </Grid>
          </Grid>
        </Box>
        <Stack spacing={4} mt={5} display="flex" justifyContent="center" alignItems="center">
          {answers.map((answer, index) => (
            <Stack
              key={`an-${index}`}
              direction="row"
              spacing={1}
              sx={{ width: '100%' }}
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                size="small"
                fullWidth
                label={`${t('answer')} ${index + 1}`}
                value={answer}
                onChange={(e) => handleChangeAnswer(e, index)}
              />
              <Box>
                <IconButton
                  size="small"
                  onClick={handleRemoveAnswer(index)}
                  aria-label="remove answer"
                  disabled={answers.length === 2}
                  sx={{ color: red[300] }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            </Stack>
          ))}
          <Box>
            <IconButton
              size="small"
              onClick={handleAddAnswer}
              aria-label="add members"
              sx={{ border: (theme) => `1px solid ${blueGrey[100]}` }}
            >
              <AddIcon fontSize="small" sx={{ color: blueGrey[400] }} />
            </IconButton>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ mt: 3, mb: 1 }}>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button
          onClick={() => {
            wsContext.eventsSocket.emit('createPoll', {
              title: question,
              answers,
              time,
              type: 'default'
            });
            handleClose();
          }}
          variant="contained"
          disableElevation
          disabled={isDisabled}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
