import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { ChatProvider } from './contexts/ChatContext';
import { RouletteProvider } from './contexts/RouletteContext';
import { TraceProvider } from './contexts/TraceContext';
import UserContext from './contexts/UserContext';
import { WSProvider } from './contexts/WSContext';
import MainLayout from './layouts/main/MainLayout';
import SimpleLayout from './layouts/main/SimpleLayout';
import EditGroupPage from './pages/EditGroupPage';
import LaunchPage from './pages/LaunchPage';
import LoginPage from './pages/LoginPage';
import LogoLoadingPage from './pages/LogoLoadingPage';
import MeetingPage from './pages/MeetingPage';
import NewGroupPage from './pages/NewGroupPage';
import NoConnectionPage from './pages/NoConnectionPage';
import Page404 from './pages/Page404';
import RedirectPage from './pages/RedirectPage';

function AuthConfig({ unprotected, children }) {
  const [loading, setLoading] = useState(true);

  const userContext = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    if (!unprotected && !userContext.user) userContext.getUserData().then(() => setLoading(false));
    else setLoading(false);
  }, []);

  if (process.env.REACT_APP_DEV_MODE) return children;

  if (loading) return <LogoLoadingPage />;

  if (!userContext.user && !unprotected) {
    console.error("Redirecting to login because user didn't load");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <TraceProvider>
          <WSProvider>
            <AuthConfig>
              <MainLayout />
            </AuthConfig>
          </WSProvider>
        </TraceProvider>
      ),
      children: [
        { path: '/', element: <LaunchPage /> },
        { path: 'news/:id/link', element: <RedirectPage /> },
        { path: 'groups/new', element: <NewGroupPage /> },
        { path: 'groups/:id', element: <EditGroupPage /> },
        {
          path: 'roulettes/:id',
          element: (
            <RouletteProvider>
              <ChatProvider>
                <MeetingPage />
              </ChatProvider>
            </RouletteProvider>
          )
        }
      ]
    },
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: '404', element: <Page404 /> },
        { path: 'no-connection', element: <NoConnectionPage /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
