import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import GroupsIcon from '@mui/icons-material/Groups';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createRoulette } from '../api/api';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

export default function NewMeetingMenu({
  disabled,
  handleClickOpen,
  setOpenBackdrop,
  setOpenAlert
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateMeeting = () => {
    handleClose();
    setOpenBackdrop(true);
    createRoulette().then((res) => {
      if (res.data) {
        navigate(`roulettes/${res.data.id}`);
      } else {
        setOpenBackdrop(false);
        setOpenAlert(true);
      }
    });
  };

  const handleCreateGroupMeeting = () => {
    handleClickOpen();
    handleClose();
  };

  return (
    <Box display="flex">
      <Button
        data-tut="new_rulex"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleCreateGroupMeeting}
        disabled={disabled}
        sx={{ whiteSpace: 'nowrap', minWidth: 'auto', height: '56px' }}
        startIcon={<MeetingRoomIcon />}
        size="large"
      >
        {t('new-rulex')}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateMeeting} disableRipple>
          <CloudQueueIcon />
          {t('new-group-room')}
        </MenuItem>
        <MenuItem onClick={handleCreateGroupMeeting} disableRipple disabled={disabled}>
          <GroupsIcon />
          {t('new-group-unique-room')}
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}
