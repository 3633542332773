import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { alpha, Box, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const RectangleBox = styled(Box)(({ theme }) => ({
  border: `4px solid ${alpha(theme.palette.secondary.light, 1)}`,
  borderRadius: 50,
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const CircleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: 4,
  borderRadius: '50%',
  width: 40,
  height: 40,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export default function TimeSelector({ time, setTime }) {
  const { t } = useTranslation();
  const controls = useAnimation();
  const theme = useTheme();

  const variants = {
    selected: (height = 1000) => ({
      opacity: 1,
      border: `3px solid ${theme.palette.secondary.dark}`,
      transition: {
        type: 'spring'
      }
    }),
    unselected: {
      opacity: 0.5,
      border: 'none',
      transition: {
        type: 'spring'
      }
    }
  };

  const secondsToTime = (secs) => {
    secs = Math.round(secs);

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      m: minutes,
      s: seconds
    };
    return obj;
  };

  const twoDigit = (number) => {
    const twodigit = number >= 10 ? number : `0${number.toString()}`;
    return twodigit;
  };

  return (
    <RectangleBox component={motion.div} initial={false} animate="hi" variants={variants}>
      <CircleBox
        onClick={() => {
          if (time > 15) setTime(time - 15);
          controls.start({
            y: [0, 5, 0],
            transition: { type: 'spring', duration: 0.5 }
          });
        }}
      >
        <IconButton aria-label="decrease-time" sx={{ color: 'white' }}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </CircleBox>

      <Stack direction="row" justifyContent="center" spacing={1} sx={{ mx: 2 }}>
        <Box>
          <motion.div animate={controls}>
            <Typography
              variant="h4"
              color="white"
              sx={{ fontWeight: 600, marginBottom: 0 }}
              paragraph
            >
              {twoDigit(secondsToTime(time).m)}:{twoDigit(secondsToTime(time).s)}
            </Typography>
          </motion.div>
        </Box>

        <Typography variant="h4" color="white" sx={{ fontWeight: 600, marginBottom: 0 }} paragraph>
          {secondsToTime(time).m ? 'min' : t('sec')}
        </Typography>
      </Stack>
      <CircleBox
        onClick={() => {
          if (time <= 3600) setTime(time + 15);
          controls.start({
            y: [0, -5, 0],
            transition: { type: 'spring', duration: 0.5, bounce: 1 }
          });
        }}
      >
        <IconButton aria-label="increase-time" sx={{ color: 'white' }}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </CircleBox>
    </RectangleBox>
  );
}
