import axios from 'axios';
import env from '../env';
import resolve from './APIUtils';

const end = '/v1';

const voice = 'es-ES_LauraV3Voice';

export async function getInternationalDay() {
  return resolve(
    axios.get(`${env.REACT_APP_BACKEND_URL}${end}/internationalDay/today`).then((res) => res.data)
  );
}

export async function getUsers() {
  return resolve(axios.get(`${env.REACT_APP_BACKEND_URL}${end}/users`).then((res) => res.data));
}

export async function getGroupParticipants(id) {
  return resolve(
    axios.get(`${env.REACT_APP_BACKEND_URL}${end}/groups/${id}/users`).then((res) => res.data)
  );
}

export async function getGroups() {
  return resolve(axios.get(`${env.REACT_APP_BACKEND_URL}${end}/groups`).then((res) => res.data));
}

export async function getAllGroups() {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/groups`, { params: { all: true } })
      .then((res) => res.data)
  );
}

export async function getGroup(id) {
  return resolve(
    axios.get(`${env.REACT_APP_BACKEND_URL}${end}/groups/${id}`).then((res) => res.data)
  );
}

export async function createGroup(name, id, users) {
  return resolve(
    axios
      .post(`${env.REACT_APP_BACKEND_URL}${end}/groups`, { name, id, users })
      .then((res) => res.data)
  );
}

export async function updateGroup(name, id, users) {
  return resolve(
    axios
      .put(`${env.REACT_APP_BACKEND_URL}${end}/groups/${id}`, { name, users })
      .then((res) => res.data)
  );
}

export async function deleteGroup(id) {
  return axios.delete(`${env.REACT_APP_BACKEND_URL}${end}/groups/${id}`).then((res) => res.data);
}

export async function leaveGroup(id, userId) {
  return axios
    .delete(`${env.REACT_APP_BACKEND_URL}${end}/groups/${id}/users/${userId}`)
    .then((res) => res.data);
}

export async function getUserNameAudio(userId) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/users/${userId}/voice?voice=${voice}`, {
        responseType: 'blob'
      })
      .then((res) => res.data)
  );
}

export async function getSpeech(text) {
  return resolve(
    axios
      .get(`${env.REACT_APP_BACKEND_URL}${end}/voice?text=${text}&voice=${voice}`, {
        responseType: 'blob'
      })
      .then((res) => res.data)
  );
}

export async function createRoulette(groupId, name, time = 45) {
  return resolve(
    axios
      .post(`${env.REACT_APP_BACKEND_URL}${end}/roulettes`, { name, time, groupId })
      .then((res) => res.data)
  );
}

export async function createRoll(rouletteId, user, time) {
  return resolve(
    axios
      .post(`${env.REACT_APP_BACKEND_URL}${end}/roulettes/${rouletteId}/users`, {
        userId: user,
        time
      })
      .then((res) => res.data)
  );
}
