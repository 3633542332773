import { Stack } from '@mui/material';
import { useContext } from 'react';
import RouletteContext from '../contexts/RouletteContext';
import PersonaAvatar from './PersonaAvatar';
import Timer from './Timer';

export default function WinnerInfo({ persona, time }) {
  const rouletteContext = useContext(RouletteContext);

  const timeCal = time - rouletteContext.timer.roulette.userTime;

  return (
    <Stack spacing={1} justifyContent="center" alignItems="center">
      <PersonaAvatar persona={persona} size="large" />
      <Timer time={timeCal} key={persona && persona?.id} isPositive={timeCal >= 0} />
    </Stack>
  );
}
