import Iconify from '../Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const suggestionsNavConfig = [
  {
    title: 'active',
    id: 'active',
    icon: getIcon('bi:inbox-fill')
  },
  {
    title: 'sent',
    id: 'sent',
    icon: getIcon('bx:send')
  },
  {
    title: 'solved',
    id: 'closed',
    icon: getIcon('ic:baseline-done-all')
  }
];

export default suggestionsNavConfig;
