import { Newspaper, VerifiedUser, Webhook } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Menu,
  Skeleton,
  Typography
} from '@mui/material';
import { amber } from '@mui/material/colors';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { removeLocalSession } from '../../api/setupAxios';
import UserContext from '../../contexts/UserContext';

export default function AccountPopover() {
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    removeLocalSession();
    handleCloseUserMenu();
    localStorage.removeItem('originLink');
    navigate('/login');
  };

  return (
    <>
      {userContext.user ? (
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            sx={{ bgcolor: amber[600] }}
            alt="Your profile picture"
            src={userContext.user?.avatar}
          >
            {userContext.user?.name && userContext.user?.name[0]}
          </Avatar>
        </IconButton>
      ) : (
        <Skeleton variant="circular" width={40} height={40} />
      )}
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,

            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ p: (theme) => theme.spacing(1, 5, 1.5, 5) }}
        >
          <Box sx={{ position: 'relative' }}>
            <Avatar
              sx={{ bgcolor: amber[600], width: 56, height: 56, fontSize: 32 }}
              alt="user avatar picture"
              src={userContext.user?.avatar}
            >
              {userContext.user?.name && userContext.user?.name[0]}
            </Avatar>
          </Box>

          <Typography variant="overline" sx={{ fontWeight: 600 }} mt={2.5}>
            {userContext.user?.name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {userContext.user?.email}
          </Typography>

          {userContext.user?.roles?.includes('editor') && (
            <Chip
              icon={<Newspaper sx={{ color: 'white !important', fontSize: '14px !important' }} />}
              size="small"
              label={t('editor')}
              color="error"
              sx={{ mt: 1, width: '100%', color: 'white' }}
            />
          )}

          {userContext.user?.roles?.includes('coder') && (
            <Chip
              icon={<Webhook sx={{ color: 'white !important', fontSize: '14px !important' }} />}
              size="small"
              label={t('coder')}
              color="success"
              sx={{ mt: 1, width: '100%', color: 'white' }}
            />
          )}

          {userContext.user?.roles?.includes('scrum-leader') && (
            <Chip
              icon={<Webhook sx={{ color: 'white !important', fontSize: '14px !important' }} />}
              size="small"
              label={t('scrum-leader')}
              color="secondary"
              sx={{ mt: 1, width: '100%', color: 'white' }}
            />
          )}

          {userContext.user?.roles?.includes('admin') && (
            <Chip
              icon={
                <VerifiedUser sx={{ color: 'white !important', fontSize: '14px !important' }} />
              }
              size="small"
              label={t('administrator')}
              color="primary"
              sx={{ mt: 1, width: '100%', color: 'white' }}
            />
          )}
        </Box>
        <Divider />

        <Box sx={{ mx: 1, my: 1 }}>
          <Button onClick={handleLogout} fullWidth variant="outlined">
            {t('logout')}
          </Button>
        </Box>
      </Menu>
    </>
  );
}
