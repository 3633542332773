import { Box, Container, useTheme } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import Lottie from 'react-lottie-player';
import LottieLoading from '../assets/loadingLottie.json';
import { varBounceIn } from '../components/animate';

export default function LogoLoadingPage({ withLogo }) {
  const controls = useAnimation();
  const theme = useTheme();

  const variants = {
    selected: {
      opacity: 1,
      outlineOffset: -5,
      border: `3px solid ${theme.palette.primary.dark}`,
      transition: {
        type: 'spring'
      }
    },
    unselected: {
      opacity: 0.5,
      border: 'none',
      transition: {
        type: 'spring'
      }
    }
  };

  return (
    <Container
      sx={{ height: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={{ maxWidth: 650, margin: 'auto', textAlign: 'center' }}>
        <motion.div
          variants={varBounceIn}
          animate={{ scale: [1, 2, 2, 1, 1], rotate: [0, 0, 360, 360, 0] }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          {/* <Box
            component="img"
            src="/static/logo.svg"
            sx={{ height: 55, mx: 'auto', my: { xs: 1, sm: 1 } }}
          /> */}
        </motion.div>

        <Lottie
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          loop
          animationData={LottieLoading}
          play
          style={{ width: 350, height: 225 }}
        />
      </Box>
    </Container>
  );
}
