import { alpha, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { varBounceIn } from '../components/animate';
import Roulette from '../components/Roulette/Roulette';
import TotalTimer from '../components/TotalTimer';
import WinnerInfo from '../components/WinnerInfo';
import RouletteContext from '../contexts/RouletteContext';
import { MobileRoulette } from './MobileRoulette';

const rouletteVariants = {
  selected: () => ({
    x: 160,
    scale: 0.8,
    transition: {
      type: 'spring'
    }
  }),
  unselected: {
    x: 0,
    scale: 1,
    transition: {
      type: 'spring'
    }
  }
};

const winnerVariants = {
  selected: () => ({
    opacity: 1,
    scale: 1,
    x: -200,
    transition: {
      type: 'spring'
    }
  }),
  unselected: {
    x: 0,
    scale: 0,
    opacity: 0,
    transition: {
      type: 'spring'
    }
  }
};

const statisticsVariants = {
  selected: () => ({
    x: 300,
    y: -30,
    transition: {
      type: 'spring'
    }
  }),
  unselected: {
    x: 0,
    scale: 0,
    opacity: 0,
    transition: {
      type: 'spring'
    }
  }
};

export default function RoulettePage({ participants, time, isAdmin }) {
  const { t } = useTranslation();
  const rouletteContext = useContext(RouletteContext);

  const [winnerSelected, setWinnerSelected] = useState(false);
  const [winnerIndex, setWinnerIndex] = useState(null);

  const [soundOn, setSoundOn] = useState(
    localStorage.getItem('default_muted') ? Boolean(!localStorage.getItem('default_muted')) : true
  );
  const [startGlobalTimer, setStartGlobalTimer] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{ margin: 'auto', textAlign: 'center' }}
      mt={8}
      component={motion.div}
      variants={varBounceIn}
    >
      <AnimatePresence>
        <Box>
          {matches ? (
            <Box
              component={motion.div}
              exit={{ opacity: 0, x: 500, transition: { duration: 1 } }}
              display="flex"
              sx={{ minWidth: 150, width: '100%', position: 'relative' }}
              justifyContent="center"
              mt={6}
            >
              <Box
                component={motion.div}
                animate="selected"
                sx={{ position: 'absolute' }}
                variants={statisticsVariants}
                alignItems="flex-end"
                display="flex"
                flexDirection="column"
              >
                <Typography
                  variant="overline"
                  sx={{ color: (theme) => alpha(theme.palette.primary.main, 1) }}
                >
                  {t('total-time')}
                </Typography>
                <TotalTimer start={startGlobalTimer} expectedTime={time * participants.length} />
              </Box>
              {rouletteContext.winner !== null &&
                rouletteContext.timer &&
                rouletteContext.timer.roulette && (
                  <Box
                    component={motion.div}
                    initial="unselected"
                    animate={winnerSelected ? 'selected' : 'unselected'}
                    sx={{ position: 'absolute' }}
                    variants={winnerVariants}
                  >
                    <WinnerInfo
                      persona={winnerIndex != null && participants[winnerIndex]}
                      time={time}
                    />
                  </Box>
                )}

              <AnimatePresence>
                <Box
                  component={motion.div}
                  animate={winnerSelected ? 'selected' : 'unselected'}
                  variants={rouletteVariants}
                >
                  <Roulette
                    key={`${participants.length}-${winnerIndex}`}
                    participants={participants}
                    setWinnerSelected={setWinnerSelected}
                    winnerIndex={winnerIndex}
                    setWinnerIndex={setWinnerIndex}
                    time={time}
                    isAdmin={isAdmin}
                  />
                </Box>
              </AnimatePresence>
            </Box>
          ) : (
            <MobileRoulette
              startGlobalTimer={startGlobalTimer}
              time={time}
              participants={participants}
              winnerSelected={winnerSelected}
              setWinnerSelected={setWinnerSelected}
              setWinnerIndex={setWinnerIndex}
              winnerIndex={winnerIndex}
              isAdmin={isAdmin}
            />
          )}
          {/* <Stack
            key="otherB"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ x: -3000, transition: { duration: 0.5 } }}
            direction="row"
            spacing={3}
            justifyContent="center"
            alignItems="center"
            mt={5}
          >
            {participants
              .filter((p) => p.status === 'finished')
              .map((p, i) => (
                <PersonaTime key={`pt-${i}`} persona={p} time={time} />
              ))}
          </Stack> */}
        </Box>
      </AnimatePresence>
    </Box>
  );
}
