import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { newSuggestion } from '../../api/SuggestionsAPI';
import Iconify from '../Iconify';

const CustomModal = ({ children }) => (
  <Box
    component={motion.div}
    initial={{ scale: 0, transition: { type: 'tween', stiffness: 60 } }}
    animate={{ scale: 1, transition: { type: 'tween', stiffness: 60 } }}
    exit={{ scale: 0, transition: { type: 'tween', stiffness: 60 } }}
    id="popup"
    sx={{
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: 99999,
      overflow: 'scroll'
    }}
  >
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0
      }}
    >
      <Box sx={{ pt: 3, pb: 0, pl: 3, pr: 2, height: '100%' }}>
        <Paper
          variant="outlined"
          sx={{
            backgroundColor: '#fafafa',
            pt: 3,
            pl: 3,
            pr: 3,
            pb: 3,
            minHeight: '100%',
            borderRadius: 3
          }}
        >
          {children}
        </Paper>
      </Box>
    </Box>
  </Box>
);

const MenuItemContent = ({ title, icon }) => (
  <Stack direction="row" spacing={2}>
    <Iconify icon={icon} width={22} height={22} />
    <Typography>{title}</Typography>
  </Stack>
);

export default function NewSuggestionDialog({ setOpen, onAdd }) {
  const [age, setAge] = useState('');

  const { t } = useTranslation();

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required('Es necesario introducir un título'),
    description: Yup.string().required('Es necesario introducir una descripción'),
    priority: Yup.string().required('Es necesario seleccionar una prioridad')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      description: '',
      priority: 1
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      newSuggestion(values.title, values.description, values.priority).then((res) => {
        if (res.data) onAdd();
        setSubmitting(false);
      });
    }
  });

  const { errors, touched, values, dirty, isValid, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CustomModal>
          <Box mb={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: (theme) => theme.palette.primary.dark, fontWeight: 700 }}
            >
              {t('new-suggestion')}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{}}>
              {t('new-suggestion-description')}
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{}}>
              {t('admin-review')}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                required
                autoCapitalize="true"
                fullWidth
                color="primary"
                label={t('title')}
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item xs="auto">
              <FormControl sx={{ minWidth: 140 }}>
                <InputLabel id="demo-select-small">{t('priority')}</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={age}
                  label={t('priority')}
                  onChange={handleChangeAge}
                  {...getFieldProps('priority')}
                  error={Boolean(touched.priority && errors.priority)}
                >
                  <MenuItem value={1}>
                    <MenuItemContent title={t('low')} icon="flat-color-icons:low-priority" />
                  </MenuItem>
                  <MenuItem value={2}>
                    <MenuItemContent title={t('medium')} icon="flat-color-icons:medium-priority" />
                  </MenuItem>
                  <MenuItem value={3}>
                    <MenuItemContent title={t('high')} icon="flat-color-icons:high-priority" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                color="primary"
                label={t('description')}
                multiline
                rows={4}
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
            <Grid item container xs={12} mt={2} justifyContent="flex-end" spacing={3}>
              <Grid item xs="auto">
                <Button size="large" disableElevation onClick={() => setOpen(false)}>
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item xs="auto">
                <LoadingButton
                  loading={isSubmitting}
                  disabled={!dirty || !isValid}
                  type="submit"
                  size="large"
                  variant="contained"
                  disableElevation
                >
                  {t('send')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </CustomModal>
      </Form>
    </FormikProvider>
  );
}
