import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(2)
}));

export default function Page404() {
  return (
    <RootStyle title="Page Not Found">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                ¡Ups!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Lo sentimos, no pudimos encontrar la página que estás buscando. ¿Quizás has escrito
              mal la URL?
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: 'auto', my: { xs: 4, sm: 6 } }}
              />
            </motion.div>

            <Button to="/" size="large" variant="contained" component={RouterLink} disableElevation>
              Ir a la página de inicio
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
