import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return [Math.floor(interval), 'years'];
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return [Math.floor(interval), 'months'];
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return [Math.floor(interval), 'days'];
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return [Math.floor(interval), 'hours'];
  }
  interval = seconds / 60;
  if (interval > 1) {
    return [Math.floor(interval), 'minutes'];
  }
  return [Math.floor(interval), 'seconds'];
}

export function toHHMMSS(secNum) {
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) - hours * 60;
  const seconds = secNum - minutes * 60 - hours * 3600;

  return [hours, minutes, seconds].map((v) => (v < 10 ? `0${v}` : v));
}
