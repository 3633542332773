import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  Avatar,
  Box,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  Stack,
  styled,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.MuiOutlinedInput-root': { background: theme.palette.background.default },
  input: { background: theme.palette.background.default },
  '&.Mui-focused': { width: 380 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

export default function AddMembersDialog({
  selected,
  setSelected,
  users,
  open,
  handleClose,
  handleToggle
}) {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');

  const filteredUsers =
    users &&
    users
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((user) => user.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ color: 'text.secondary', fontWeight: 500 }}>{t('add-people')}</DialogTitle>
      <DialogContent sx={{ height: 450 }}>
        <Box display="flex" justifyContent="space-between" mt={2} mb={3}>
          <SearchStyle
            id="project_lists_search"
            size="small"
            value={search}
            onChange={(ev) => {
              setSearch(ev.target.value);
            }}
            placeholder={t('search-user')}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: (theme) => theme.palette.grey[400] }} />
              </InputAdornment>
            }
          />

          <Button
            size="small"
            sx={{ cursor: 'pointer' }}
            underline="none"
            onClick={() => {
              setSelected(users.map((user) => user.id));
            }}
          >
            {t('select-all')}
          </Button>
        </Box>
        <List subheader={<li />}>
          {filteredUsers && filteredUsers.length ? (
            filteredUsers.map((user, index) => {
              const labelId = `checkbox-list-secondary-label-${user.id}`;

              return (
                <ListItem
                  key={user.id}
                  onClick={() => {
                    handleToggle(user.id);
                  }}
                  secondaryAction={
                    <Checkbox
                      key={selected.length}
                      edge="end"
                      checked={selected.indexOf(user.id) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar alt={`${t('avatar')} n°${index + 1}`} src={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={user.name} />
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <Stack mt={4} spacing={2} justifyContent="center" alignItems="center">
              <Avatar sizes="10" variant="rounded">
                <SearchOffIcon sx={{ width: '100%', height: '70%' }} fontSize="inherit" />
              </Avatar>
              <Typography variant="body2" align="center">
                {t('no-search-results')}.
              </Typography>
            </Stack>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="large">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
