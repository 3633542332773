import HowToVoteIcon from '@mui/icons-material/HowToVote';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Badge, Stack, useMediaQuery, useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getUnreadSuggestions } from '../../api/SuggestionsAPI';
import Logo from '../../components/Logo';
import SuggestionForm from '../../components/SuggestionsInbox/SuggestionsDialog';
import GeneralContext from '../../contexts/GeneralContext';
import SoundContext from '../../contexts/SoundContext';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { TodayDate } from './TodayDate';

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 10 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired
};

const LogoContainer = styled('header')(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 4, 0, 0)
  }
}));

const SoundButton = () => {
  const soundContext = useContext(SoundContext);

  return (
    <IconButton
      onClick={() => {
        soundContext.toggleMute();
      }}
    >
      {!soundContext.muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
    </IconButton>
  );
};

const SuggestionsButton = ({ handleOpenSuggestions }) => {
  const generalContext = useContext(GeneralContext);

  return (
    <IconButton onClick={handleOpenSuggestions} data-tut="suggestions">
      <Badge badgeContent={generalContext.unreadSuggestions?.totalNum} color="error">
        <HowToVoteIcon />
      </Badge>
    </IconButton>
  );
};

const ResponsiveAppBar = () => {
  const generalContext = useContext(GeneralContext);

  const hiddenFileInput = useRef(null);

  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    getUnreadSuggestions().then((res) => {
      if (res.data && res.data.length) {
        const totalNum = res.data ? res.data.length : 0;
        const active = res.data ? res.data.filter((s) => s.suggestion.status === 0) : 0;
        const closed = res.data ? res.data.filter((s) => s.suggestion.status !== 0) : 0;

        generalContext.setUnreadSuggestions({ totalNum, active, closed });
      }
    });
  }, []);

  const handleOpenSuggestions = () => {
    generalContext.setUnreadSuggestions({ totalNum: 0 });
    generalContext.setOpenSuggestions(true);
  };

  const handleCloseSuggestions = () => {
    generalContext.setOpenSuggestions(false);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
  };

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <LogoContainer>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </LogoContainer>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            component={motion.div}
            animate={{ opacity: [0, 1] }}
            sx={{ flexGrow: 0 }}
            display="flex"
            alignItems="center"
          >
            {matches && <TodayDate />}

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <SuggestionsButton handleOpenSuggestions={handleOpenSuggestions} />

              <SoundButton />
            </Stack>

            <Box ml={4} display="flex" alignItems="center">
              <LanguagePopover />
            </Box>

            <Box ml={4}>
              <AccountPopover />
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <SuggestionForm open={generalContext.openSuggestions} onClose={handleCloseSuggestions} />
    </AppBar>
  );
};
export default ResponsiveAppBar;
