import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getGroup, getUsers, updateGroup } from '../api/api';
import { MotionContainer, varBounceIn } from '../components/animate';
import AddMembersDialog from '../components/animate/AddMembersDialog';
import UserContext from '../contexts/UserContext';
import { capitalizeEachWord } from '../utils/formatString';

export default function EditGroupPage() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');
  const [groupId, setGroupId] = useState(id);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [users, setUsers] = useState(null);

  const [loading, setLoading] = useState(false);

  const userContext = useContext(UserContext);

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeId = (event) => {
    setGroupId(event.target.value);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const controls = useAnimation();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    getGroup(id).then((res) => {
      if (res.data) {
        setSelected(res.data.members.map((m) => m.id));
        setName(res.data.name);
      }
    });

    getUsers().then((res) => {
      if (res.data) {
        const formattedUsers = res.data.map((u) => ({ ...u, name: capitalizeEachWord(u.name) }));
        setUsers(formattedUsers);
      }
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (value) => {
    const currentIndex = selected.indexOf(value);
    const newselected = [...selected];

    if (currentIndex === -1) {
      newselected.push(value);
    } else {
      newselected.splice(currentIndex, 1);
    }

    setSelected(newselected);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        height: 'calc(100% - 64px)'
      }}
    >
      <Container maxWidth="sm" sx={{ pt: 6 }}>
        <Card variant="outlined" sx={{ p: 3 }}>
          <AnimatePresence>
            <CardContent>
              <MotionContainer
                open
                initial={{ opacity: 0, x: -100 }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { type: 'spring', bounce: 0.3 }
                }}
                exit={{ opacity: 0, scasle: 0 }}
              >
                <motion.div variants={varBounceIn} animate={controls}>
                  <Box>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {t('edit-group')}
                    </Typography>
                  </Box>
                </motion.div>

                <Divider sx={{ mt: 2, mb: 4 }} />

                <Stack spacing={4}>
                  <TextField
                    required
                    value={name}
                    onChange={handleChangeName}
                    label={t('group-name')}
                  />

                  <TextField
                    disabled
                    required
                    value={groupId}
                    onChange={handleChangeId}
                    label={t('group-id')}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Stack spacing={2}>
                    <Typography variant="body1">{t('add-members')}</Typography>
                    <Box sx={{ height: 100, overflow: 'scroll' }}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs="auto">
                          <IconButton
                            size="small"
                            onClick={handleClickOpen}
                            aria-label="add members"
                            sx={{ border: (theme) => `1px solid ${blueGrey[100]}` }}
                          >
                            <AddIcon sx={{ color: blueGrey[400] }} />
                          </IconButton>
                        </Grid>
                        {users ? (
                          users
                            .filter((user) => selected.includes(user.id))
                            .map((user) => (
                              <Grid key={`t-${user.id}`} item xs="auto">
                                <Chip
                                  onDelete={() => handleToggle(user.id)}
                                  avatar={<Avatar alt={user.name} src={user.avatar} />}
                                  label={user.name.split(' ')[0]}
                                />
                              </Grid>
                            ))
                        ) : (
                          <Grid item xs>
                            <LinearProgress />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Stack>
                </Stack>

                <Divider sx={{ mb: 4, mt: 0.5 }} />

                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Button sx={{ color: theme.palette.grey[600] }} onClick={() => navigate('/')}>
                      {t('back-home')}
                    </Button>
                  </Box>

                  <LoadingButton
                    loading={loading}
                    disabled={!name || !groupId || !selected.length}
                    disableElevation
                    variant="contained"
                    onClick={() => {
                      setLoading(true);
                      setOpenAlert(false);

                      const filteredSelected = selected.filter(
                        (s) => s !== userContext.user.groupId
                      );

                      updateGroup(name, groupId, filteredSelected).then((res) => {
                        setLoading(false);
                        if (!res.data) setOpenAlert(true);
                      });
                    }}
                    sx={{ width: 100 }}
                  >
                    {t('save')}
                  </LoadingButton>
                </Stack>
              </MotionContainer>
            </CardContent>
          </AnimatePresence>

          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
              {t('group-updating-error')}
            </Alert>
          </Snackbar>
        </Card>
      </Container>

      <AddMembersDialog
        selected={selected}
        setSelected={setSelected}
        users={users}
        open={open}
        handleClose={handleClose}
        handleToggle={handleToggle}
      />
    </Box>
  );
}
