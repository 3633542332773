async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  return promise
    .then((res) => {
      resolved.data = res;
      return resolved;
    })
    .catch((error) => {
      resolved.error = error.response;
      return resolved;
    });
}

export default resolve;
