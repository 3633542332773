import { useContext, useEffect } from 'react';
import './App.scss';
import ScrollToTop from './components/ScrollToTop';
import SoundContext from './contexts/SoundContext';
import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

export default function App() {
  const soundContext = useContext(SoundContext);

  useEffect(() => {
    soundContext.loadAudio();
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Router />
    </ThemeConfig>
  );
}
