import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

const CircleBox = styled(Box)(({ theme, size }) => {
  let wh = 80;
  if (size === 'large') wh = 100;
  else if (size === 'small') wh = 50;

  return {
    backgroundColor: 'white',
    borderRadius: '50%',
    width: wh,
    height: wh,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

export default function PersonaAvatar({ persona, inactive, onClick, size }) {
  const theme = useTheme();

  const variants = {
    selected: () => ({
      opacity: 1,
      border: `4px solid ${theme.palette.primary.main}`,
      transition: {
        type: 'spring'
      }
    }),
    unselected: {
      opacity: 0.5,
      border: 'none',
      transition: {
        type: 'spring'
      }
    }
  };

  return (
    <AnimatePresence>
      {persona && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <CircleBox
              size={size}
              component={motion.div}
              initial="selected"
              animate={inactive ? 'unselected' : 'selected'}
              variants={variants}
              whileHover={{ scale: 1.1 }}
              onClick={onClick}
            >
              {persona.avatar ? (
                <Box
                  component="img"
                  src={persona.avatar}
                  sx={{ height: '100%', clipPath: 'circle()' }}
                />
              ) : (
                <Typography
                  variant="h2"
                  color="primary.dark"
                  sx={{ fontWeight: 700, marginBottom: 0 }}
                  paragraph
                >
                  {persona.name[0].toUpperCase()}
                </Typography>
              )}
            </CircleBox>
            <Typography variant="h7" color="secondary" sx={{ marginBottom: 0 }} paragraph>
              {persona.name}
            </Typography>
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
