import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  getActiveSuggestions,
  getClosedSuggestions,
  getSentSuggestions,
  removeSuggestion,
  removeUnreadActiveSuggestions,
  removeUnreadClosedSuggestions
} from '../../api/SuggestionsAPI';
import GeneralContext from '../../contexts/GeneralContext';
import UserContext from '../../contexts/UserContext';
import NavSection from '../NavSection';
import NewSuggestionDialog from './NewSuggestionDialog';
import suggestionsNavConfig from './SuggestionsNavConfig';
import { TabPanel } from './TabPanel';

export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

export const item = {
  hidden: { scale: 0 },
  show: { scale: 1 }
};

const mockSuggestions = [
  {
    title: 'Necesito algo',
    date: 'September 14, 2016',
    from: { name: 'Ras Alungei', mail: 'ras.alungei@ibm.com' },
    description: 'A long description that explains the suggestion or incidence'
  },
  {
    title: 'Necesito otra cosa',
    date: 'September 14, 2016',
    from: { name: 'Andres Mareca', mail: 'andres.mareca@ibm.com' },
    description: 'A long description that explains the suggestion or incidence'
  }
];

export const NoItemsFound = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        {t('no-suggestions')}
      </Typography>
    </Box>
  );
};

export default function SuggestionsBox() {
  const { t } = useTranslation();

  const userContext = React.useContext(UserContext);
  const generalContext = React.useContext(GeneralContext);

  const [value, setValue] = React.useState(0);
  const [openNew, setOpenNew] = React.useState(false);

  const { activeSuggestions, sentSuggestions, closedSuggestions } = generalContext;

  const retrieveSuggestions = () => {
    getSentSuggestions().then((res) => {
      if (res.data) generalContext.setSentSuggestions(res.data.items);
    });

    getActiveSuggestions().then((res) => {
      if (res.data) generalContext.setActiveSuggestions(res.data.items);
    });

    getClosedSuggestions().then((res) => {
      if (res.data) generalContext.setClosedSuggestions(res.data.items);
    });
  };

  React.useEffect(() => {
    retrieveSuggestions();
  }, []);

  const handleSelectMenuItem = (newValue) => {
    setOpenNew(false);
    setValue(newValue);
  };

  const handleSuggestionAdded = () => {
    setOpenNew(false);
  };

  const handleDelete = (id) => {
    removeSuggestion(id).then((res) => {
      if (!res.error) refresh();
    });
  };

  const refresh = () => {
    generalContext.setSuggestions(null);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', minHeight: '100%', mt: 2.5 }}
    >
      <Box
        sx={{
          width: 380,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
        }}
      >
        <Box sx={{ width: '100%', px: 2.5, py: 3, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">{t('suggestions-box')}</Typography>
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Button
            size="large"
            sx={{ width: '100%' }}
            variant="contained"
            disableElevation
            onClick={() => setOpenNew(true)}
            startIcon={<AddIcon />}
          >
            {t('new-suggestion')}
          </Button>
        </Box>

        <NavSection
          onChange={handleSelectMenuItem}
          navConfig={suggestionsNavConfig}
          selected={value}
        />

        <Box sx={{ flexGrow: 1 }} />
      </Box>

      <Box id="tabs-container" sx={{ width: '100%', position: 'relative' }}>
        <AnimatePresence>
          {openNew && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 99999
              }}
            >
              <NewSuggestionDialog setOpen={setOpenNew} onAdd={handleSuggestionAdded} />{' '}
            </Box>
          )}
        </AnimatePresence>

        <TabPanel
          title={t('active-suggestions')}
          subtitle={t('pending-review')}
          value={value}
          index={0}
          suggestions={activeSuggestions}
          onDelete={handleDelete}
          refresh={refresh}
          removeUnread={removeUnreadActiveSuggestions}
        />
        <TabPanel
          title={t('sent-suggestions')}
          subtitle={t('sent-suggestions-subtitle')}
          value={value}
          index={1}
          suggestions={sentSuggestions}
          onDelete={handleDelete}
          refresh={refresh}
        />
        <TabPanel
          title={t('solved-suggestions')}
          subtitle={t('suggestions-are-reviewed')}
          value={value}
          index={2}
          suggestions={closedSuggestions}
          onDelete={handleDelete}
          refresh={refresh}
          removeUnread={removeUnreadClosedSuggestions}
        />
      </Box>
    </Box>
  );
}
