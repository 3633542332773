import React, { useState } from 'react';

const WSContext = React.createContext({
  eventsSocket: null,
  setEventsSocket: () => {}
});

export const WSProvider = (props) => {
  const setEventsSocket = (eventsSocket) => {
    setState((prevState) => ({
      ...prevState,
      eventsSocket
    }));
  };

  const initialState = {
    eventsSocket: null,
    setEventsSocket
  };

  const [state, setState] = useState(initialState);

  return <WSContext.Provider value={state}>{props.children}</WSContext.Provider>;
};

export const WSConsumer = WSContext.Consumer;
export default WSContext;
