import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeLocalSession } from '../api/setupAxios';
import { getTracer } from '../api/SocketAPI';

const TraceContext = React.createContext({ socket: null });

export const TraceProvider = (props) => {
  const socket = useRef(getTracer());
  const [connections, setConnections] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    socket.current.connect();

    socket.current.on('login', (args) => {
      if (args.statusCode !== 200) {
        removeLocalSession();
        localStorage.setItem('originLink', window.location.pathname);
        navigate('/login', { state: { from: window.location.pathname } });
      }

      setSocketConnected(true);
    });

    return () => {
      if (socket && socket.current) {
        socket.current.removeAllListeners();
        socket.current.close();
      }
    };
  }, []);

  const setSocketConnected = (socketConnected) => {
    setState((prevState) => ({
      ...prevState,
      socketConnected
    }));
  };

  const join = (event, callback) => {
    const newConnections = { ...connections };

    joinLogic(newConnections, event, callback);

    setConnections(newConnections);
  };

  const joinLogic = (myConnections, event, callback) => {
    if (!myConnections[event]) {
      myConnections[event] = 0;
      socket.current.emit('join', { ids: [event] });
    }

    myConnections[event] += 1;

    socket.current.on(event, callback);
  };

  const leave = (event, callback) =>
    setState((state) => {
      const { connections } = { ...state };
      const { socket } = state;

      connections[event] -= 1;
      socket.off(event, callback);

      if (!connections[event]) {
        socket.emit('leave', { ids: [event] });
        delete connections[event];
      }
      return { ...state, connections };
    });

  const close = () => {
    if (socket) socket.current.close();
  };

  const [state, setState] = useState({
    socketConnected: false,
    setSocketConnected,
    join,
    leave,
    close
  });

  return <TraceContext.Provider value={state}>{props.children}</TraceContext.Provider>;
};

export default TraceContext;
