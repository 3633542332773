import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useAnimation } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import { getAllGroups, getGroups } from '../api/api';
import { MotionContainer } from '../components/animate';
import Page from '../components/Page';
import StartTutorialDialog from '../components/StartTutorialDialog';
import GeneralContext from '../contexts/GeneralContext';
import UserContext from '../contexts/UserContext';

import Groups from '../components/Groups/Groups';
import Meetings from '../components/Meetings';
import Statistics from '../components/Statistics/Statistics';

const RootStyle = styled(Page)(() => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center'
}));

const HeaderTitle = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 4 }} maxWidth={700}>
      <Typography variant="h2" sx={{ fontWeight: 450 }}>
        {t('meetings')}
      </Typography>
      <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 200 }} paragraph>
        {t('perfect-daily-on-time')}
      </Typography>
    </Box>
  );
};

export default function LaunchPage() {
  const userContext = useContext(UserContext);
  const generalContext = useContext(GeneralContext);

  const { t } = useTranslation();

  const [startTutorialDialog, setStartTutorialDialog] = useState(
    !localStorage.getItem('seen_tutorial')
  );

  const [openTutorial, setOpenTutorial] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseTutorial = () => {
    setOpenTutorial(false);
    localStorage.setItem('seen_tutorial', true);
  };

  const handleOpenTutorial = () => setOpenTutorial(true);

  const handleCloseStartTutorial = () => {
    setStartTutorialDialog(false);
    localStorage.setItem('seen_tutorial', true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const controls = useAnimation();
  const theme = useTheme();

  useEffect(() => {
    if (userContext.user) {
      getGroups().then((res) => {
        if (res.data) {
          generalContext.setGroups(res.data);
        }
      });

      if (userContext.user.isAdmin)
        getAllGroups().then((res) => {
          if (res.data) generalContext.setAllGroups(res.data);
        });
    }

    return () => {};
  }, [userContext.user]);

  const steps = [
    {
      selector: '[data-tut="groups"]',
      content: t('onboarding-groups')
    },
    {
      selector: '[data-tut="new_rulex"]',
      content: t('onboarding-new_rulex')
    },
    {
      selector: '[data-tut="statistics"]',
      content: t('onboarding-statistics')
    },
    {
      selector: '[data-tut="suggestions"]',
      content: t('onboarding-suggestions')
    },
    {
      selector: '[data-tut="contributors"]',
      content: t('onboarding-contributors')
    }
  ];

  return (
    <Container maxWidth="xl">
      <Tour
        steps={steps}
        isOpen={openTutorial}
        onRequestClose={handleCloseTutorial}
        accentColor={theme.palette.primary.main}
        rounded={30}
      />

      <RootStyle title="Ruulex" sx={{ pt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7} lg>
            <Box sx={{ py: 2 }}>
              <MotionContainer
                open
                sx={{ width: '100%' }}
                initial={{ opacity: 0, x: -100 }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { type: 'spring', bounce: 0.3 }
                }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <HeaderTitle />

                <Grid item container rowSpacing={6} columnSpacing={8}>
                  <Grid item md={12} lg>
                    <MotionContainer
                      open
                      sx={{ width: '100%' }}
                      initial={{ opacity: 0, x: 100 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: { type: 'spring', bounce: 0.3, delay: 0.3 }
                      }}
                      exit={{ opacity: 0, scale: 0 }}
                    >
                      <Meetings setOpenBackdrop={setOpenBackdrop} setOpenAlert={setOpenAlert} />

                      <Groups setOpenBackdrop={setOpenBackdrop} />
                    </MotionContainer>
                  </Grid>
                  {/* <Grid item md={12} lg>
                    <AppTasks
                      title="Your tasks"
                      list={[
                        { id: '1', label: 'Create project Logo' },
                        { id: '2', label: 'Add SCSS and JS files if required' },
                        { id: '3', label: 'Stakeholder Meeting' },
                        { id: '4', label: 'Scoping & Estimations' },
                        { id: '5', label: 'Sprint Showcase' },
                        { id: '6', label: 'Medical appointment' }
                      ]}
                    />
                  </Grid> */}
                </Grid>
              </MotionContainer>
            </Box>
          </Grid>

          {/* <Grid item md={8} lg={5}>
            <MotionContainer
              open
              sx={{ width: '100%' }}
              initial={{ opacity: 0, x: 100 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { type: 'spring', bounce: 0.3 }
              }}
              exit={{ opacity: 0, scale: 0 }}
            >
              <motion.div variants={varBounceIn} animate={controls}>
                <Paper
                  variant="outlined"
                  sx={{
                    backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.05),
                    borderRadius: 10,
                    py: 4,
                    height: 'calc(100vh - 98px)',
                    overflow: 'hidden'
                  }}
                >
                  <News />
                </Paper>
              </motion.div>
            </MotionContainer>
          </Grid> */}

          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Paper
              data-tut="statistics"
              // variant="outlined"
              elevation={0}
              sx={{
                // backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
                backgroundColor: 'transparent',
                borderRadius: 10,
                px: 3,
                py: 4,
                height: 'calc(100vh - 98px)',
                mb: 1
              }}
            >
              <Statistics />
            </Paper>
          </Grid>
        </Grid>
      </RootStyle>

      <StartTutorialDialog
        open={startTutorialDialog}
        handleClose={handleCloseStartTutorial}
        handleOpenTutorial={handleOpenTutorial}
      />
    </Container>
  );
}
