import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import es from './translations/es.json';
import cat from './translations/cat.json';

i18next.use(initReactI18next).init({
  detection: { order: ['path', 'navigator'] },
  resources: {
    es: {
      translation: es
    },
    en: {
      translation: en
    },
    cat: {
      translation: cat
    }
  },
  lng: localStorage.getItem('language') || 'en'
});

export default i18next;
