import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  alpha,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { deepOrange, purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChatContext from '../contexts/ChatContext';
import UserContext from '../contexts/UserContext';
import WSContext from '../contexts/WSContext';
import Iconify from './Iconify';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 8,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: deepOrange.A100,
    opacity: 1,
    color: 'white'
  }
}));

const StyledQuestionsBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 12,
    top: 8,
    border: `1px solid black`,
    padding: '0 4px',
    backgroundColor: 'white',
    opacity: 1,
    color: 'black'
  }
}));

const StyledIconButton = ({ children, size, highlight, sx, onClick, ...props }) => (
  <IconButton
    component={motion.div}
    onClick={onClick}
    animate={{ rotate: [360, 0] }}
    whileHover={{ scale: 1.2 }}
    sx={{
      backdropFilter: 'blur(6px)',
      backgroundColor: 'white',
      '&:hover': { backgroundColor: 'white' },
      '&.Mui-disabled': { backgroundColor: '#eeeeee', opacity: 1 },
      ...(highlight && { boxShadow: `0px 0px 12px ${purple.A200}` }),

      ...sx
    }}
    size={size || 'large'}
    {...props}
  >
    {children}
  </IconButton>
);

const LightTooltip = styled(({ className, type, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.secondary.dark,
    backgroundColor: alpha('#fff', 0.9),
    backdropFilter: 'blur(3px)',
    boxShadow: theme.customShadows.z20,

    opacity: 1,
    fontSize: 14,
    p: 0,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.1,
    textTransform: 'uppercase'
  }
}));

const GroupAvatars = ({ users, sx }) => (
  <AvatarGroup max={6} sx={sx}>
    {users.map((user, i) => (
      <Avatar key={`av-${i}`} sx={{ width: 24, height: 24 }} alt={user.name} src={user.avatar}>
        {user.name[0].toUpperCase()}
      </Avatar>
    ))}
  </AvatarGroup>
);

export default function ConfigBottomMenu({
  sx,
  onClickChat,
  onClickPoll,
  onClickQuestion,
  onClickBuzz,
  openSnackbar,
  status,
  isAdmin,
  usersQuestions,
  numQuestions,
  satisfactionResults
}) {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const [buzzCount, setBuzzCount] = useState(2);

  const chatContext = useContext(ChatContext);
  const wsContext = useContext(WSContext);

  const userContext = useContext(UserContext);

  const showQuestionsButton = status === 'results' && satisfactionResults && Boolean(numQuestions);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  const onClickCopy = () => {
    copyToClipboard();
    openSnackbar();
  };

  const onClickClose = () => {
    wsContext.eventsSocket.emit('close', {});
  };

  const onClickSeeQuestions = () => {
    wsContext.eventsSocket.emit('close', { type: 'results' });
  };

  const finishDisabled = status === 'running' || status === 'notes' || !satisfactionResults;

  const getQuestionsColor = () => {
    if (status === 'notes') return '#9e9e9e';
    return theme.palette.primary.main;
  };

  return (
    <Box sx={{ ...sx }}>
      {showQuestionsButton && (
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 45 }}
          animate={{ y: 0, opacity: 1 }}
          whileHover={{ y: -10, repeat: 0 }}
          transition={{ duration: 2, type: 'spring' }}
          sx={{
            position: 'absolute',
            top: -42,
            left: 0,
            right: 0,
            textAlign: 'center',
            zIndex: -1,
            width: '100%'
          }}
        >
          <StyledQuestionsBadge badgeContent={numQuestions}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                pt: 1.5,
                pb: 4,
                px: 4,
                borderRadius: (theme) => theme.spacing(5, 5, 2, 2),
                borderBottom: 'none'
              }}
              disableElevation
              onClick={onClickSeeQuestions}
              endIcon={<PlayArrowIcon />}
            >
              {t('start-round-questions')}
            </Button>
          </StyledQuestionsBadge>
        </Box>
      )}
      <Stack
        direction="row"
        spacing={{ xs: 1.2, sm: 3 }}
        px={2}
        py={1.5}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.4),
          borderRadius: '40px',
          backdropFilter: 'blur(4px)',
          zIndex: 2
        }}
      >
        <LightTooltip title={t('end-meeting')} placement="top" type="error">
          <Box>
            <StyledIconButton
              size="small"
              aria-label="finish"
              onClick={onClickClose}
              disabled={!isAdmin}
            >
              <Iconify width={24} height={24} icon="eva:close-outline" color="#e57373" />
            </StyledIconButton>
          </Box>
        </LightTooltip>

        <LightTooltip title={t('share-link')} placement="top">
          <Box>
            <StyledIconButton aria-label="copy" onClick={onClickCopy}>
              <Iconify icon="akar-icons:link-on" color={'#A3B4A2'} width={24} height={24} />
            </StyledIconButton>
          </Box>
        </LightTooltip>

        <LightTooltip title={t('send-buzz')} placement="top">
          <Box>
            <StyledBadge badgeContent={buzzCount}>
              <StyledIconButton
                disabled={!buzzCount}
                aria-label="copy"
                onClick={() => {
                  if (buzzCount) {
                    setBuzzCount(Math.max(buzzCount - 1, 0));
                    onClickBuzz();
                  }
                }}
              >
                <Iconify
                  icon="fluent:megaphone-loud-16-filled"
                  color={!buzzCount ? '#E2856E' : deepOrange.A100}
                  width={24}
                  height={24}
                />
              </StyledIconButton>
            </StyledBadge>
          </Box>
        </LightTooltip>

        <LightTooltip title={t('poll')} placement="top">
          <Box sx={{ position: 'relative' }}>
            <StyledIconButton aria-label="poll" onClick={onClickPoll}>
              <Iconify icon="gg:poll" color={'#B388FF'} width={24} height={24} />
            </StyledIconButton>
          </Box>
        </LightTooltip>

        <Box sx={{ position: 'relative' }}>
          <LightTooltip title={t('question')} placement="top">
            <Box sx={{ position: 'relative' }} component={motion.div}>
              <Box sx={{ position: 'relative' }}>
                {usersQuestions && (
                  <Box sx={{ position: 'absolute', top: -5, right: -5 }}>
                    <GroupAvatars users={usersQuestions} />
                  </Box>
                )}

                <StyledIconButton aria-label="question" onClick={onClickQuestion}>
                  <Iconify
                    icon="ant-design:question-circle-outlined"
                    color={'#788EED'}
                    width={24}
                    height={24}
                  />
                </StyledIconButton>
              </Box>
            </Box>
          </LightTooltip>
        </Box>

        <LightTooltip title={t('chat')} placement="top-start">
          <Box>
            <Badge invisible={!chatContext.unreadMessages} variant="dot" color="error">
              <StyledIconButton
                size="small"
                aria-label="chat"
                onClick={(ev) => {
                  if (matches) chatContext.setAnchorEl(ev.currentTarget);
                  else onClickChat();
                }}
                sx={{ p: 1 }}
              >
                <Iconify icon="ep:chat-round" color={'#5B5B5B'} width={24} height={24} />
              </StyledIconButton>
            </Badge>
          </Box>
        </LightTooltip>
      </Stack>
    </Box>
  );
}
