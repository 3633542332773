import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  Box,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectionMeetingAvatar from '../components/MeetingAvatar/ConnectionMeetingAvatar';
import MeetingSelector from '../components/MeetingSelector/MeetingSelector';
import './ConfigPage.scss';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { scale: 0 },
  show: { scale: 1 }
};

export default function ConfigPage({
  meetingName,
  time,
  setTime,
  connectedParticipants,
  chatOpened,
  meetingCreatorId,
  isAdmin,
  startTime
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const { t } = useTranslation();

  const [orderedParticipants, setOrderedParticipants] = useState([]);

  useEffect(() => {
    const copy = [...connectedParticipants];
    setOrderedParticipants(
      copy.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    );

    return () => {};
  }, [connectedParticipants]);

  return (
    <>
      <Grid
        item
        container
        component={motion.ol}
        variants={container}
        initial="hidden"
        animate="show"
        direction={'row'}
        xs={12}
        sm={5}
        lg={chatOpened ? 3.5 : 4}
        justifyContent="flex-start"
        sx={{
          position: 'relative',
          heigth: '100%',
          maxHeight: '100%',
          overflow: 'hidden'
        }}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
          <Stack direction="row" spacing={3} alignItems="center">
            <Stack>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                {meetingName}
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 400 }}>
                {startTime}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" mt={4} spacing={2}>
            <Typography variant="h7" sx={{ fontWeight: 500 }}>
              Participants
            </Typography>
            <Chip
              icon={<PeopleAltIcon sx={{ width: 16, height: 16 }} />}
              label={
                orderedParticipants.filter((p) => p.status && p.status !== 'disconnected').length
              }
              color="secondary"
              variant="outlined"
              size="small"
              sx={{ pl: 1, pr: 0.5 }}
            />
          </Stack>
        </Box>
        <Box
          className="hide-scrollbar"
          sx={{
            position: 'relative',
            heigth: '100%',
            maxHeight: '100%',
            overflow: 'scroll'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
              height: 0,
              zIndex: 999
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: (theme) => theme.spacing(-2),
                left: -10,
                right: 0,
                width: '100%',
                height: 40,
                background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(243,243,243,1) 72%)',
                zIndex: 99999
              }}
            />
          </Box>
          <Grid
            item
            container
            xs={12}
            sx={{
              my: 2,
              p: 1,
              height: '100%',

              '&:after': {
                content: '""',
                position: 'sticky',
                bottom: -2,
                left: 0,
                right: 0,
                zIndex: 100,
                width: '100%',
                height: 50,
                background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(243,243,243,1) 72%)'
              }
            }}
            spacing={2.5}
          >
            {orderedParticipants.length ? (
              orderedParticipants.map((el, i) => {
                const isActive = el.status && el.status !== 'disconnected';

                return (
                  <Grid
                    key={`participant-${el.id}`}
                    item
                    xs="auto"
                    sx={{ position: 'relative' }}
                    component={motion.div}
                    variants={item}
                  >
                    <ConnectionMeetingAvatar
                      title={el.name}
                      avatar={el.avatar}
                      index={i}
                      status={el.status}
                      isAdmin={el.isAdmin}
                      isEditor={el.roles?.includes('editor')}
                      isScrumLeader={el.roles?.includes('scrum-leader')}
                      isCoder={el.roles?.includes('coder')}
                      isCreator={el.id === meetingCreatorId}
                      isActive={isActive}
                      isAway={el.status === 'left'}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid
                item
                xs="auto"
                component={motion.div}
                variants={item}
                sx={{ position: 'relative' }}
              >
                <Skeleton variant="circular" width={70} height={70} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid
        component={motion.div}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        item
        xs={12}
        sm={6}
        lg={chatOpened ? 4.5 : 6}
        sx={{
          zIndex: 102,
          position: 'relative',
          height: '100%',
          minHeight: 100,
          py: 1
        }}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        <MeetingSelector
          time={time}
          setTime={setTime}
          connectedParticipants={connectedParticipants}
          isAdmin={isAdmin}
        />
      </Grid>
    </>
  );
}
