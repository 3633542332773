import { alpha, Box, Popover } from '@mui/material';
import { useContext } from 'react';
import ChatContext from '../contexts/ChatContext';
import Chat from './Chat/Chat';

export default function ChatPopover({ messages }) {
  const chatContext = useContext(ChatContext);
  const id = chatContext.anchorEl ? 'simple-popover' : undefined;

  return (
    <Popover
      sx={{
        '& .MuiPopover-paper': {
          borderRadius: 5,
          backgroundColor: alpha('#fff', 0.9),
          backdropFilter: 'blur(5px)'
        }
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      id={id}
      open={Boolean(chatContext.anchorEl)}
      anchorEl={chatContext.anchorEl}
      onClose={() => chatContext.setAnchorEl(null)}
    >
      <Box sx={{ width: 360, maxHeight: 550 }}>
        <Chat key="popover-chat" messages={messages} />
      </Box>
    </Popover>
  );
}
