export const childrenAnimation = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        duration: 0.1
      }
    }
  },
  item: {
    hidden: { scale: 0, y: 20 },
    show: { scale: 1, y: 0 }
  }
};
