import { CircularProgress, Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLink } from '../api/newsAPI';

export default function RedirectPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    getLink(id).then((res) => {
      if (res.data) {
        let url = res.data.link;

        if (!url.startsWith('http')) {
          url = `https://${url}`;
        }

        window.location.assign(url);
      }
    });
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: 'calc(90vh - 64px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <CircularProgress />

        <Typography variant="body2" color="text.secondary">
          {t('redirecting')}
        </Typography>
      </Stack>
    </Container>
  );
}
