import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { amber, deepOrange, deepPurple, grey, pink } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './meeting-avatar.scss';

const Pulse = ({ index }) => {
  const theme = useTheme();

  const backgroundVariants = {
    rest: {
      color: 'grey',
      x: 0,
      transition: {
        duration: 2,
        type: 'tween',
        ease: 'easeIn'
      }
    },
    hover: {
      scale: 1.08,
      transition: {
        type: 'spring',
        duration: 0.1
      }
    }
  };

  const backgroundColors = [
    theme.palette.primary.main,
    deepPurple.A100,
    amber[400],
    deepOrange[200],
    pink[200]
  ];

  return (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: (theme) =>
          `3px solid ${alpha(backgroundColors[index % backgroundColors.length], 0.4)}`
      }}
      className="idea-card"
      animate={{ scale: 1.2 }}
      transition={{ type: 'spring', duration: 0.5 }}
      variants={backgroundVariants}
    />
  );
};

const SelectableMeetingAvatar = ({ title, index, isSelected, onSelect, disabled }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backgroundColors = [
    theme.palette.primary.main,
    deepPurple.A100,
    amber[400],
    deepOrange[200],
    pink[200]
  ];

  const backgroundVariants = {
    rest: {
      color: 'grey',
      x: 0,
      transition: {
        duration: 2,
        type: 'tween',
        ease: 'easeIn'
      }
    },
    hover: {
      scale: 1.08,
      transition: {
        type: 'spring',
        duration: 0.1
      }
    }
  };

  const textBoxPressVariants = {
    rest: { scale: 1, opacity: 1, right: 0 },
    hover: {
      right: 150
    }
  };

  return (
    <Stack
      component={motion.div}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      initial="rest"
      whileHover="hover"
      animate="rest"
      {...(onSelect && { onClick: onSelect })}
    >
      <Box
        sx={{
          position: 'relative',
          width: 100,
          height: 70,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{ position: 'relative', width: 70, height: 70 }}
          whileTap={{ scale: 1.1 }}
          component={motion.div}
        >
          <Box>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 99,
                left: 0,
                right: 0,
                textAlign: 'center',
                margin: 'auto',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
            >
              {title ? (
                <Typography variant="h2" sx={{ color: 'white', opacity: 0.8, cursor: 'pointer' }}>
                  {title[0].toUpperCase()}
                </Typography>
              ) : (
                <DoNotDisturbAltIcon
                  sx={{
                    color: 'white',
                    opacity: 0.8,
                    fontSize: 52,
                    marginTop: 0.5,
                    cursor: 'pointer'
                  }}
                />
              )}
            </Box>

            <Box
              component={motion.div}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: isSelected
                  ? backgroundColors[index % backgroundColors.length]
                  : grey[400]
              }}
              className="idea-card"
              whileTap={{ scale: 0.95 }}
              animate="rest"
              variants={backgroundVariants}
            />
          </Box>

          {isSelected && <Pulse index={index} />}
        </Box>
      </Box>
      <Box
        component={motion.div}
        variants={textBoxPressVariants}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: 80 }}
      >
        <Typography
          variant="overline"
          sx={{
            color: 'text.secondary',
            fontWeight: isSelected ? 800 : 400,
            cursor: 'pointer'
          }}
          align="center"
        >
          {title || t('no-group')}
        </Typography>
      </Box>
    </Stack>
  );
};

export default SelectableMeetingAvatar;
