import { Box, useTheme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Iconify from '../Iconify';
import './meeting-avatar.scss';

const NewGroupAvatar = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const pressVariants = {
    rest: { scale: 1, right: 0 },
    hover: {
      scale: 0.9,
      transition: { type: 'spring' },
      rotate: 360
      // right: 15
    }
  };

  return (
    <Box
      component={motion.div}
      variants={pressVariants}
      whileHover="hover"
      whileTap={{ scale: 0.8 }}
      onClick={() => navigate('/groups/new')}
      sx={{ position: 'relative', width: 70, height: 70 }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 99,
          left: 0,
          right: 0,
          textAlign: 'center',
          margin: 'auto',
          top: '55%',
          transform: 'translateY(-50%)',
          cursor: 'pointer'
        }}
      >
        <Box component={motion.div}>
          <Iconify
            color="white"
            icon="carbon:add"
            width="80%"
            height="80%"
            style={{ opacity: 0.8 }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: blueGrey[200]
        }}
        className="idea-card"
      />
    </Box>
  );
};

export default NewGroupAvatar;
