import { Stack, useTheme } from '@mui/material';
import { useContext } from 'react';
import RouletteContext from '../contexts/RouletteContext';
import Timer from './Timer';

export default function TotalTimer({ start, expectedTime }) {
  const rouletteContext = useContext(RouletteContext);

  const theme = useTheme();

  return (
    <Stack
      sx={{ position: 'relative' }}
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
    >
      <Timer
        time={rouletteContext.timer ? rouletteContext.timer.roulette.totalTime : 0}
        isPositive
        size="small"
        color={theme.palette.secondary}
        variant="standard"
      />
    </Stack>
  );
}
