import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WSContext from '../contexts/WSContext';

export default function QuestionsPage({ socket, isAdmin }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const wsContext = useContext(WSContext);

  const [question, setQuestion] = useState(null);

  const [finished, setFinished] = useState(false);

  const handleNextQuestion = (noteId) => {
    wsContext.eventsSocket.emit('nextNote', { noteId: noteId || null });
  };

  const handleBackToMeetingRoom = () => {
    wsContext.eventsSocket.emit('close', { type: 'notes' });
  };

  const handleCloseMeeting = () => {
    wsContext.eventsSocket.emit('close', {});
  };

  const questionListener = (args) => {
    const note = args.message;
    if (note) setQuestion(note);
    else setFinished(true);
  };

  useEffect(() => {
    if (socket) socket.on('note', questionListener);

    return () => {
      if (socket) socket.off('note', questionListener);
    };
  }, []);

  return (
    <Grid item xs={12} md sx={{ height: '90%' }} display="flex" alignItems="center">
      <Container maxWidth="sm">
        {question && !finished ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="start">
            <Stack spacing={1} mb={3} alignItems="start">
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  alt="creator picture"
                  src={question.sender?.avatar}
                  sx={{ bgcolor: deepOrange[500] }}
                >
                  {question.sender?.name[0].toUpperCase()}
                </Avatar>
                <Typography variant="body1">{question.sender?.name}</Typography>
              </Stack>
              <Typography
                component={motion.div}
                animate={{ y: [200, 0] }}
                variant="h4"
                sx={{ fontWeight: 300 }}
                mb={3}
              >
                {question.name}
              </Typography>
            </Stack>

            {question.users && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography color="text.secondary" variant="overline">
                  {t('for')}
                </Typography>
                <AvatarGroup>
                  {question.users.map((user, i) => (
                    <Avatar
                      key={`av-${i}`}
                      sx={{ width: 30, height: 30 }}
                      alt={user.name}
                      src={user.avatar}
                    />
                  ))}
                </AvatarGroup>
              </Stack>
            )}

            <Box mt={8}>
              <Button
                endIcon={<NavigateNextIcon />}
                color="secondary"
                variant="outlined"
                disableElevation
                disabled={!isAdmin}
                onClick={() => handleNextQuestion(question.id)}
              >
                {t('next-question')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              component={motion.div}
              animate={{ y: [200, 0] }}
              variant="h4"
              sx={{ fontWeight: 300 }}
              mb={3}
            >
              {finished ? t('all-questions-answered') : t('soon-start')}
            </Typography>

            <Box component={motion.div} animate={{ y: [200, 0] }}>
              {finished ? (
                <Button
                  variant="contained"
                  disableElevation
                  disabled={!isAdmin}
                  onClick={() => handleCloseMeeting()}
                >
                  {t('Finalizar reunión')}
                </Button>
              ) : (
                <Button
                  component={motion.div}
                  endIcon={<ArrowRightAltIcon />}
                  animate={{ scale: [1, 1.1, 1.1, 1] }}
                  transition={{ repeat: Infinity, duration: 2 }}
                  variant="contained"
                  disabled={!isAdmin}
                  disableElevation
                  onClick={() => handleNextQuestion()}
                >
                  {t('start')}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Container>
    </Grid>
  );
}
