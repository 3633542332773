import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import UserContext from '../../contexts/UserContext';
import SuggestionsBox from './SuggestionsBox';

export default function SuggestionsDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const userContext = React.useContext(UserContext);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
          borderRadius: 2,
          p: 1,
          height: '100%'
        }
      }}
    >
      <Box sx={{ position: 'absolute', right: 8, zIndex: 9999999 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <SuggestionsBox />
    </Dialog>
  );
}
