import React, { useState } from 'react';

const ChatContext = React.createContext({
  anchorEl: null,
  setAnchorEl: () => {},
  unreadMessages: false
});

export const ChatProvider = (props) => {
  const setAnchorEl = (target) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: target,
      unreadMessages: false
    }));
  };

  const setUnreadMessages = (value) => {
    setState((prevState) => ({
      ...prevState,
      unreadMessages: value
    }));
  };

  const initialState = {
    anchorEl: null,
    unreadMessages: false,
    setAnchorEl,
    setUnreadMessages
  };

  const [state, setState] = useState(initialState);

  return <ChatContext.Provider value={state}>{props.children}</ChatContext.Provider>;
};

export const ChatConsumer = ChatContext.Consumer;
export default ChatContext;
