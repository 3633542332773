import CloseIcon from '@mui/icons-material/Close';
import { alpha, AvatarGroup, Box, buttonClasses, Chip, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import RouletteContext from '../../contexts/RouletteContext';
import WSContext from '../../contexts/WSContext';
import Timer from '../Timer';

export default function GeneralPoll({ poll, handleClose }) {
  const { t } = useTranslation();

  const wsContext = React.useContext(WSContext);
  const rouletteContext = React.useContext(RouletteContext);

  const [selected, setSelected] = React.useState(null);

  const handleListItemClick = (answerId) => {
    if (selected === null && poll.status !== 'close') {
      setSelected(answerId);
      wsContext.eventsSocket.emit('submitPollAnswers', { pollId: poll.id, pollAnswerId: answerId });
    }
  };

  return (
    <>
      <Stack direction="row" display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography variant="overline" color="text.secondary">
          Encuesta
        </Typography>
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center" mt={2}>
        <Avatar
          alt="creator picture"
          src={poll.user && poll.user.avatar}
          sx={{ width: 28, height: 28, bgcolor: poll.user && deepOrange[500] }}
        >
          {poll.user?.name && poll.user?.name[0].toUpperCase()}
        </Avatar>

        <Typography variant="subtitle2">Pregunta de {poll.user && poll.user.name}</Typography>
        <Typography variant="h7" sx={{ color: 'text.secondary' }}>
          •
        </Typography>
        {rouletteContext.timer &&
          (rouletteContext.timer.polls[poll.id] ? (
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('ends-in')}
              </Typography>

              <Timer
                size="small"
                time={rouletteContext.timer && rouletteContext.timer.polls[poll.id]}
              />
            </Stack>
          ) : (
            <Chip label="Finalizada" color="primary" size="small" />
          ))}
      </Stack>

      <Typography variant="h3" mt={2}>
        {poll.title}
      </Typography>

      <List
        sx={{
          pt: 3,
          [`& .${buttonClasses.disabled}`]: { opacity: '0.9 !important' }
        }}
      >
        {poll.answers &&
          poll.answers.map((answer, index) => (
            <ListItem
              disabled={poll.status === 'close'}
              alignItems="center"
              button
              onClick={() => handleListItemClick(answer.id)}
              key={answer.id}
              sx={{
                border: '1px solid lightGrey',
                ...(answer.id === selected && {
                  border: (theme) => `3px solid ${theme.palette.primary.main}`
                }),
                borderRadius: 20,
                mb: answer.id === poll.answers.length - 1 ? 0 : 2,
                '&:hover': {
                  backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.2)
                }
              }}
            >
              <ListItemText primary={answer.title} />

              {answer.users && (
                <>
                  <Typography variant="overline">
                    {((answer.users.length / poll.total) * 100).toFixed(2)}%
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Box
                      component={motion.div}
                      animate={{
                        width: [
                          '0%',
                          `${((answer.users.length / poll.total) * 100).toFixed(2) || '-'}%`
                        ]
                      }}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: `${(answer.users.length / poll.total) * 100}%`,
                        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
                        borderRadius: '30px',
                        zIndex: 0
                      }}
                    />
                    <AvatarGroup max={4}>
                      {answer.users.map((user) => (
                        <Avatar sx={{ width: 30, height: 30 }} alt={user.name} src={user.avatar} />
                      ))}
                    </AvatarGroup>
                  </Stack>
                </>
              )}
            </ListItem>
          ))}
      </List>
      {Boolean(poll.total) && (
        <Box display="flex" justifyContent="flex-end" mr={0.5} mb={2}>
          <Typography mt={1} variant="overline" align="right" color="text.secondary">
            {t('number-of-responses')}: {poll.total}
          </Typography>
        </Box>
      )}
    </>
  );
}
