import { Avatar, Box, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { Fragment, useRef } from 'react';
import ChatInput from './ChatInput';

const Message = ({ user, time, content }) => {
  // const timeStr = date.toISOString().split('T')[1];

  const formatTime = (time) =>
    time.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });

  const date = new Date(time);

  const seconds = date.getSeconds();
  const minutes = date.getMinutes();
  const hours = date.getHours();

  const timeStr = `${formatTime(hours)}:${formatTime(minutes)}`;

  return (
    <Grid container spacing={1.5} component={motion.div} animate={{}}>
      <Grid item xs="auto">
        <Avatar src={user && user.avatar} alt="photoURL" sx={{ width: 28, height: 28 }} />
      </Grid>
      <Grid item xs container direction="column">
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle1" color="inherit">
                {user.name}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="caption" sx={{ color: grey[500] }}>
                    {`${timeStr.slice(0, 5)}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2">{content}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function Chat({ messages, outlined }) {
  const theme = useTheme();
  const messagesRef = useRef(null);

  return (
    <Box
      sx={{ width: '100%', height: '100%', borderRadius: 4 }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      {...(outlined && { component: Paper, variant: 'outlined' })}
    >
      <Box sx={{ pt: 2.5, px: 3 }}>
        <Typography variant="h5">Chat</Typography>
      </Box>

      <Divider sx={{ mt: 1.5 }} />

      <Box
        ref={messagesRef}
        sx={{
          px: 3,
          height: '100%',
          overflowY: 'scroll',
          flex: '1',
          display: 'flex',
          flexDirection: 'column-reverse',
          minHeight: 300,
          position: 'relative'
        }}
      >
        {messages &&
          messages.map((m, i) => (
            <Fragment key={`chat-message-${i}`}>
              <Box sx={{ my: 0.5 }}>
                <Message content={m.content} time={m.time} user={m.user} />
              </Box>
              {i !== messages.length - 1 && <Divider sx={{ my: 0.5, opacity: 0.5 }} />}
            </Fragment>
          ))}

        {/* <Box sx={{ position: 'absolute', left: 0, right: 0, margin: 'auto', mx: 2 }}>
          <Alert
            severity="info"
            icon={false}
            sx={{
              '& .MuiAlert-message': {
                display: 'flex',
                jusitfyContent: 'center',
                alignItems: 'center'
              }
            }}
          >
            <Box display="inline-flex" justifyContent="center" alignItems="center">
              <IconButton color="inherit" aria-label="scroll to new messages" size="small">
                <ArrowDownwardIcon />
              </IconButton>
            </Box>
            <Typography
              variant="overline"
              noWrap
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 210 }}
            >
              Hay mensajes nuevos!
            </Typography>
          </Alert>
        </Box> */}
      </Box>

      <Box
        sx={{ height: 100, mb: 0.5, p: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ChatInput />
      </Box>
    </Box>
  );
}
