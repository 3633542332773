import axios from 'axios';
import env from '../env';
import resolve from './APIUtils';

const end = '/v1';

export async function getNews() {
  return resolve(axios.get(`${env.REACT_APP_BACKEND_URL}${end}/news`).then((res) => res.data));
}

export async function getLink(newsId) {
  return resolve(
    axios.get(`${env.REACT_APP_BACKEND_URL}${end}/news/${newsId}/link`).then((res) => res.data)
  );
}

export async function likeNews(newsId) {
  return resolve(
    axios.post(`${env.REACT_APP_BACKEND_URL}${end}/news/${newsId}/like`).then((res) => res.data)
  );
}

export async function dislikeNews(newsId) {
  return resolve(
    axios.delete(`${env.REACT_APP_BACKEND_URL}${end}/news/${newsId}/like`).then((res) => res.data)
  );
}

export async function createNews(news) {
  return resolve(
    axios.post(`${env.REACT_APP_BACKEND_URL}${end}/news`, news).then((res) => res.data)
  );
}

export async function removeNews(newsId) {
  return resolve(
    axios.delete(`${env.REACT_APP_BACKEND_URL}${end}/news/${newsId}`).then((res) => res.data)
  );
}
