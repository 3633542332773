import { List, ListItem, ListItemAvatar, ListItemText, Popover } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';

export default function LanguagePopover() {
  const { i18n } = useTranslation();

  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    handleClose();
  };

  const currentLanguage = localStorage.getItem('language') || 'en';

  const languages = {
    es: { icon: 'emojione:flag-for-spain', title: 'Español' },
    en: { icon: 'circle-flags:uk', title: 'English' },
    cat: { icon: 'circle-flags:es-ct', title: 'Català' }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Iconify
        icon={languages[currentLanguage].icon}
        aria-describedby={id}
        onClick={handleClick}
        sx={{ width: 25, height: 25, '&:hover': { cursor: 'pointer' } }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{ mt: 2, ml: -7 }}
      >
        <List>
          {Object.keys(languages).map((key, index) => (
            <ListItem button key={index} onClick={() => handleChangeLang(key)}>
              <ListItemAvatar>
                <Iconify icon={languages[key].icon} sx={{ width: 25, height: 25 }} />
              </ListItemAvatar>
              <ListItemText primary={languages[key].title} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
