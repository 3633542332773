import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { alpha, Box, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { amber, deepOrange, deepPurple, grey, pink, red } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteGroup } from '../../api/api';
import UserContext from '../../contexts/UserContext';
import './meeting-avatar.scss';

const CircleMenuItem = ({ sx, icon, tooltip, ...props }) => {
  const itemVariants = {
    rest: { opacity: 0 },
    hover: { opacity: 1 }
  };

  return (
    <Tooltip component={motion.div} whileTap={{ scale: 0.8 }} title={tooltip} placement="right">
      <Paper
        component={motion.li}
        variants={itemVariants}
        elevation={8}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 30,
          height: 30,
          backgroundColor: 'white',
          borderRadius: '50%',
          backdropFilter: 'blur(6px)',
          ...sx
        }}
        {...props}
      >
        {icon}
      </Paper>
    </Tooltip>
  );
};

const CircularMenu = ({ handleNewRulexClick, handleLeaveGroupClick, handleRemoveGroupClick }) => {
  const userContext = useContext(UserContext);

  const { t } = useTranslation();

  const menuVariants = {
    rest: { opacity: 0 },
    hover: {
      opacity: 1,
      transition: {
        type: 'spring',
        delayChildren: 0.1,
        staggerChildren: 0.1
      }
    }
  };

  return (
    <Box
      component={motion.ul}
      variants={menuVariants}
      sx={{
        position: 'absolute',
        zIndex: 1000,
        right: -15,
        top: -20,
        bottom: 0,
        width: 30,
        height: 100
      }}
    >
      <CircleMenuItem
        onClick={handleNewRulexClick}
        sx={{ position: 'absolute', right: 5, top: 8 }}
        color="primary"
        icon={<AddIcon sx={{ fontSize: 16, color: grey[700] }} size="small" />}
        tooltip={t('new-rulex')}
      />

      <CircleMenuItem
        onClick={handleLeaveGroupClick}
        sx={{ position: 'absolute', right: -8, top: 40 }}
        icon={<EditIcon sx={{ fontSize: 14, color: grey[700] }} size="small" color="secondary" />}
        tooltip={t('edit-group')}
      />
      <CircleMenuItem
        onClick={handleRemoveGroupClick}
        sx={{ position: 'absolute', right: 5, top: 72, backgroundColor: red[400] }}
        icon={
          <DeleteOutlineIcon sx={{ fontSize: 16, color: 'white' }} size="small" color="secondary" />
        }
        tooltip={t('delete-group')}
      />
    </Box>
  );
};

const Pulse = ({ backgroundColor }) => {
  const theme = useTheme();

  const backgroundVariants = {
    rest: {
      color: 'grey',
      x: 0,
      transition: {
        duration: 2,
        type: 'tween',
        ease: 'easeIn'
      }
    },
    hover: {
      scale: 1.08,
      transition: {
        type: 'spring',
        duration: 0.1
      }
    }
  };

  return (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor
      }}
      className="idea-card"
      animate={{ scale: [1.1, 1.3, 1.1] }}
      transition={{ repeat: Infinity, duration: 2 }}
      variants={backgroundVariants}
    />
  );
};

const MeetingAvatar = ({ title, id, active, index, setOpenBackdrop, setOpenAlert, onClickNew }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNewRulexClick = () => {
    onClickNew();
  };

  const handleEditGroupClick = () => {
    navigate(`/groups/${id}`);
  };

  const handleRemoveGroupClick = () => {
    deleteGroup(id).then((res) => {
      if (!res.data) setOpenAlert(true);
    });
  };

  const backgroundColors = [
    theme.palette.primary.main,
    deepPurple.A100,
    amber[400],
    deepOrange[200],
    pink[200]
  ];

  const backgroundVariants = {
    rest: {
      color: 'grey',
      x: 0,
      transition: {
        duration: 2,
        type: 'tween',
        ease: 'easeIn'
      }
    },
    hover: {
      scale: 1.08,
      transition: {
        type: 'spring',
        duration: 0.1
      }
    }
  };

  const pressVariants = {
    rest: { scale: 1, right: 0 },
    hover: {
      scale: 0.75,
      right: 15
    }
  };

  const textBoxPressVariants = {
    rest: { scale: 1, opacity: 1, right: 0 },
    hover: {
      right: 150
    }
  };

  const textPressVariants = {
    rest: { scale: 1, opacity: 1 },
    hover: {
      fontWeight: 550,
      right: 150
    }
  };

  return (
    <Stack
      component={motion.div}
      sx={{ position: 'relative', width: 70, userSelect: 'none' }}
      spacing={2.5}
      justifyContent="center"
      alignItems="center"
      initial="rest"
      whileHover="hover"
      animate="rest"
    >
      <Box sx={{ position: 'relative', width: 70, height: 70 }}>
        <CircularMenu
          setOpenBackdrop={setOpenBackdrop}
          handleNewRulexClick={handleNewRulexClick}
          handleLeaveGroupClick={handleEditGroupClick}
          handleRemoveGroupClick={handleRemoveGroupClick}
        />

        <Box
          sx={{ position: 'relative', width: 70, height: 70 }}
          component={motion.div}
          variants={pressVariants}
        >
          <Box
            sx={{
              position: 'absolute',
              zIndex: 99,
              left: 0,
              right: 0,
              textAlign: 'center',
              margin: 'auto',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          >
            <Typography
              variant="h2"
              sx={{ color: 'white', opacity: 0.8, cursor: 'pointer', userSelect: 'none' }}
            >
              {title[0].toUpperCase()}
            </Typography>
          </Box>
          <Box
            component={motion.div}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: backgroundColors[index % backgroundColors.length]
            }}
            className="idea-card"
            whileTap={{ scale: 0.95 }}
            animate="rest"
            variants={backgroundVariants}
          />

          {active && (
            <Pulse
              backgroundColor={alpha(backgroundColors[index % backgroundColors.length], 0.4)}
            />
          )}
        </Box>
      </Box>
      <Box
        component={motion.div}
        variants={textBoxPressVariants}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Tooltip title={title} arrow>
          <Typography
            component={motion.div}
            variants={textPressVariants}
            variant="overline"
            sx={{ color: 'text.secondary', fontWeight: 200, cursor: 'pointer' }}
            align="center"
          >
            {title}
          </Typography>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default MeetingAvatar;
