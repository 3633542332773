import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box, Chip, IconButton } from '@mui/material';
import { amber } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import RouletteContext from '../../contexts/RouletteContext';
import SoundContext from '../../contexts/SoundContext';
import UserContext from '../../contexts/UserContext';
import WSContext from '../../contexts/WSContext';
import Timer from '../Timer';

const customIcons = [
  {
    value: 'veryDissatisfied',
    icon: (
      <SentimentVeryDissatisfiedIcon
        sx={{ fontSize: 58, color: (theme) => theme.palette.error.dark }}
      />
    ),
    label: 'Very Dissatisfied'
  },
  {
    value: 'dissatisfied',
    icon: (
      <SentimentDissatisfiedIcon
        sx={{ fontSize: 58, color: (theme) => theme.palette.error.main }}
      />
    ),
    label: 'Dissatisfied'
  },
  {
    value: 'neutral',
    icon: <SentimentSatisfiedIcon sx={{ fontSize: 58, color: amber[600] }} />,
    label: 'Neutral'
  },
  {
    value: 'satisfied',
    icon: (
      <SentimentSatisfiedAltIcon
        sx={{ fontSize: 58, color: (theme) => theme.palette.success.main }}
      />
    ),
    label: 'Satisfied'
  },
  {
    value: 'verySatisfied',
    icon: (
      <SentimentVerySatisfiedIcon
        sx={{ fontSize: 58, color: (theme) => theme.palette.success.light }}
      />
    ),
    label: 'Very Satisfied'
  }
];

export default function SatisfactionPoll({ poll, handleClose }) {
  const wsContext = React.useContext(WSContext);
  const userContext = React.useContext(UserContext);
  const rouletteContext = React.useContext(RouletteContext);
  const soundContext = React.useContext(SoundContext);

  const { t } = useTranslation();

  React.useEffect(() => {
    soundContext.playText('Elige cuál es tu estado de ánimo hoy');
  }, []);

  const handleSelect = (value) => {
    wsContext.eventsSocket.emit('submitPollAnswers', { pollId: poll.id, pollAnswerId: value });
    handleClose();
  };

  return (
    <Stack spacing={1} alignItems="center" my={2}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ textAlign: 'center' }}
      >
        <Typography variant="h3" sx={{ fontWeight: 200 }} color="text.secondary">
          {t('before-we-finish')}, {userContext.user?.name.split(' ')[0]},{' '}
          {t('how-are-you-feeling-today')}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
          mt={2}
          mb={4}
        >
          {customIcons.map((item) => {
            const answer = poll.answers.find((a) => a.title === item.value);

            return (
              <IconButton
                onClick={() => {
                  handleSelect(answer.id);
                }}
                component={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.8 }}
                size="small"
              >
                {item.icon}
              </IconButton>
            );
          })}
        </Stack>

        {rouletteContext.timer &&
          (rouletteContext.timer.polls[poll.id] ? (
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" mt={2}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('remaining-time')}
              </Typography>

              <Timer
                isPositive
                size="small"
                time={rouletteContext.timer && rouletteContext.timer.polls[poll.id]}
              />
            </Stack>
          ) : (
            <Chip label="Finalizada" color="primary" size="small" />
          ))}
      </Box>
    </Stack>
  );
}
