import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WSContext from '../../contexts/WSContext';
import Iconify from '../Iconify';

export default function ChatInput() {
  const wsContext = useContext(WSContext);

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const anchorRef = useRef(null);

  const onChange = (event) => {
    setMessage(event.target.value);
  };

  const onEmojiClick = (event, emojiObject) => {
    setMessage((m) => m + emojiObject.emoji);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onSend = () => {
    if (message !== '') {
      wsContext.eventsSocket.emit('message', message);
      setMessage('');
    }
  };

  function onKeyUp(e) {
    const enterKey = 13; // Key Code for Enter Key
    if (e.which === enterKey) {
      onSend();
    }
  }

  return (
    <Paper
      variant="outlined"
      color="primary"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: 20 }}
    >
      <InputBase
        value={message}
        sx={{ ml: 2, flex: 1 }}
        placeholder={t('write-message')}
        inputProps={{ 'aria-label': 'write a message' }}
        onChange={onChange}
        onKeyPress={(e) => onKeyUp(e)}
      />

      <IconButton onClick={onSend} sx={{ p: '10px' }} aria-label="search">
        <Iconify icon="bxs:send" width={24} height={24} />
      </IconButton>
    </Paper>
  );
}
