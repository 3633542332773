import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import KeyIcon from '@mui/icons-material/Key';
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { amber, deepOrange, deepPurple, grey, pink } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import CurvedText from '../CurvedText';
import './meeting-avatar.scss';

const backgroundVariants = {
  rest: {
    color: 'grey',
    x: 0,
    transition: {
      duration: 2,
      type: 'tween',
      ease: 'easeIn'
    }
  },
  hover: {
    scale: 1.08,
    transition: {
      type: 'spring',
      duration: 0.1
    }
  }
};

const textBoxPressVariants = {
  rest: { scale: 1, opacity: 1, right: 0 },
  hover: {
    right: 150
  }
};

const getPulseColor = (status, isAdmin, theme) => {
  switch (status) {
    case 'left':
      return amber[500];
    case 'disconnected':
      return grey[400];
    default:
      if (isAdmin) return deepPurple.A100;
      return theme.palette.success.main;
  }
};

const getColor = (status, theme) => {
  switch (status) {
    case 'left':
      return amber[500];
    case 'disconnected':
      return grey[400];
    default:
      return theme.palette.success.main;
  }
};

const ColoredPulse = ({ size, color }) => {
  const getScale = () => {
    switch (size) {
      case 'large':
        return 1.3;
      case 'small':
        return 1.1;
      default:
        return 1.2;
    }
  };

  return (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: (theme) => `3px solid ${alpha(color, 0.9)}`,

        borderTopColor: 'transparent',
        borderLeftColor: 'transparent'
      }}
      className="idea-card"
      animate={{ scale: getScale() }}
      transition={{ type: 'spring', duration: 0.5 }}
      variants={backgroundVariants}
    />
  );
};

const Pulse = ({ status, isAdmin, size, color }) => {
  const getScale = () => {
    switch (size) {
      case 'large':
        return 1.35;
      case 'small':
        return 1.1;
      default:
        return 1.2;
    }
  };

  return (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: (theme) => `3px solid ${color || alpha(getPulseColor(status, isAdmin, theme), 0.6)}`
      }}
      className="idea-card"
      animate={{ scale: getScale() }}
      transition={{ type: 'spring', duration: 0.5 }}
      variants={backgroundVariants}
    />
  );
};

export const hoverVariants = {
  rest: { opacity: 0 },
  hover: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const Connected = ({ status, isAdmin, isCreator }) => {
  const theme = useTheme();

  return isCreator ? (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: 20,
        height: 20,
        border: '4px solid white',
        backgroundColor: 'white',
        left: -4,
        top: 0,
        zIndex: 100,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <KeyIcon
        sx={{
          color: (theme) => getColor(status, theme),
          fontSize: 16
        }}
      />
    </Box>
  ) : (
    <Box
      component={motion.div}
      sx={{
        position: 'absolute',
        width: 20,
        height: 20,
        backgroundColor: (theme) => getColor(status, theme),
        border: '4px solid white',
        left: -4,
        top: 0,
        zIndex: 100,
        borderRadius: '50%'
      }}
    />
  );
};

const ConnectionMeetingAvatar = ({
  title,
  active,
  avatar,
  index,
  isActive,
  isAway,
  onSelect,
  status,
  isAdmin,
  isEditor,
  isScrumLeader,
  isCoder,
  isCreator,
  color,
  hideStatus
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userContext = useContext(UserContext);

  const splittedTitle = title.split(' ');

  const backgroundColors = [
    theme.palette.primary.main,
    deepPurple.A100,
    amber[400],
    deepOrange[200],
    pink[200]
  ];

  const getRoleLabel = () => {
    if (isCreator) return 'anfitrión';
    if (isAdmin) return 'admin';
  };

  const ROLES_COLORS = {
    admin: theme.palette.primary.main,
    editor: theme.palette.error.main,
    coder: theme.palette.success.dark,
    'scrum-leader': theme.palette.secondary.main
  };

  return (
    <Stack
      component={motion.div}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      initial="rest"
      whileHover="hover"
      animate="rest"
      sx={{ width: 80 }}
      {...(onSelect && { onClick: onSelect })}
    >
      <Box
        sx={{
          position: 'relative',
          width: 100,
          height: 70,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ position: 'relative', width: 70, height: 70 }}
          component={motion.div}
          whileTap={{ scale: 1.1 }}
          whileHover="hover"
          initial="rest"
        >
          {avatar ? (
            <Box
              component="img"
              src={avatar}
              sx={{
                position: 'absolute',
                height: '100%',
                clipPath: 'circle()',
                ...(status === 'disconnected' && { filter: 'grayscale(100%)', opacity: 0.8 })
              }}
            />
          ) : (
            <Box>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 99,
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                  margin: 'auto',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
              >
                {title ? (
                  <Typography variant="h2" sx={{ color: 'white', opacity: 0.8 }}>
                    {title[0]}
                  </Typography>
                ) : (
                  <DoNotDisturbAltIcon
                    sx={{
                      color: 'white',
                      opacity: 0.8,
                      fontSize: 52,
                      marginTop: 0.5
                    }}
                  />
                )}
              </Box>

              <Box
                component={motion.div}
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    status === 'joined' || status === 'left'
                      ? backgroundColors[index % backgroundColors.length]
                      : grey[400]
                }}
                className="idea-card"
                whileTap={{ scale: 0.95 }}
                animate="rest"
                variants={backgroundVariants}
              />
            </Box>
          )}

          {status !== 'disconnected' && <Pulse status={status} color={color} />}

          {status !== 'disconnected' && isScrumLeader && (
            <ColoredPulse color={ROLES_COLORS['scrum-leader']} status={status} />
          )}

          {status !== 'disconnected' && isAdmin && (
            <ColoredPulse color={ROLES_COLORS.admin} status={status} />
          )}

          {isActive && (isAdmin || isCreator) && (
            <Box
              component={motion.div}
              variants={hoverVariants}
              sx={{
                position: 'absolute',
                right: 10,
                top: 22,
                transform: 'rotate(60deg)',
                userSelect: 'none'
              }}
            >
              <CurvedText text={getRoleLabel()} arc={80} radius={55} />
            </Box>
          )}

          {!hideStatus && <Connected status={status} isAdmin={isAdmin} isCreator={isCreator} />}
        </Box>
      </Box>
      <Box
        component={motion.div}
        variants={textBoxPressVariants}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: 80 }}
      >
        {title
          .split(' ')
          .slice(0, 2)
          .map((t, i) => (
            <Typography
              key={`title-part-${i}`}
              variant="overline"
              sx={{
                color: 'text.secondary',
                fontWeight: 500
              }}
              align="center"
            >
              {t}
            </Typography>
          ))}
      </Box>
    </Stack>
  );
};

export default ConnectionMeetingAvatar;
