import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SoundContext from '../../contexts/SoundContext';
import UserContext from '../../contexts/UserContext';
import WSContext from '../../contexts/WSContext';
import TimeSelector from '../TimeSelector';

const items = [
  {
    id: 'daily',
    title: 'Daily',
    description: 'daily-description',
    subtitle: 'daily-sub',
    duration: '15 min',
    steps: [
      { title: 'Ruleta', description: 'a' },
      { title: 'Resultados', description: 'a' },
      { title: 'Q&A', description: 'a' }
    ],
    image: '/static/illustrations/card-backgrounds/card-background.svg'
  },
  {
    id: 'retro',
    title: 'Retro',
    description: 'retro-description',
    subtitle: 'retro-sub',
    duration: '1 hora',
    disabled: true
  },
  {
    id: 'brainstorming',
    title: 'Brainstorming',
    description: 'brainstorming-description',
    subtitle: 'brainstorming-sub',
    disabled: true,
    duration: '10 min',
    image: '/static/illustrations/card-backgrounds/card-background-b.svg'
  },

  {
    id: 'ipm',
    title: 'Sprint Planning',
    description: 'sprint-planning-description',
    subtitle: 'sprint-planning-sub',
    disabled: true,
    duration: '1h 30min'
  }
];

const backgrounds = [
  'linear-gradient(to right, #fa709a 0%, #fee140 100%)',
  'linear-gradient(to right, #fa709a 0%, #fee140 100%)',
  'linear-gradient(-225deg, #20E2D7 0%, #F9FEA5 100%)'
];

const instructions = ['what-yesterday', 'what-for-today', 'any-stoppers'];

const variants = {
  enter: {
    y: -20,
    opacity: 0
  },
  center: {
    y: 0,
    opacity: 1
  },
  exit: {
    opacity: 0,
    y: -20
  }
};

const MeetingDetails = ({
  selectedItem,
  adminUser,
  time,
  setTime,
  onClose,
  connectedParticipants,
  isAdmin
}) => {
  const [index, setIndex] = useState(0);

  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const wsContext = useContext(WSContext);
  const soundContext = useContext(SoundContext);

  const [loading, setLoading] = useState(false);

  const startDisabled =
    connectedParticipants.filter((p) => p.status !== 'left' && p.status !== 'disconnected').length <
    2;

  const suggestions = t('daily-suggestions', { returnObjects: true });

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Box
      component={motion.div}
      layoutId={selectedItem.id}
      animate={{ boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)' }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        backgroundColor: (theme) => theme.palette.backgrounds[0],
        backgroundImage: 'url("/static/illustrations/card-backgrounds/card-background-a.svg")',
        backgroundSize: 'cover',
        color: 'white',
        backdropFilter: 'blur(4px)',
        width: '100%',
        borderRadius: 4,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: -1,
        zIndex: 999,
        overflow: 'scroll',
        minHeight: 400,
        px: 3,
        pt: 3,
        pb: 3
      }}
    >
      <Box sx={{ position: 'absolute', right: 14, top: 14 }}>
        <IconButton size="small" onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <Typography variant="h3" mb={1}>
          {selectedItem.title.toUpperCase()}
        </Typography>
        <Typography variant="body2">{t(selectedItem.subtitle)}</Typography>

        <Stack mt={2} mx={2} spacing={2}>
          {instructions.map((instruction, i) => (
            <Stack key={`instr-${i}`} direction="row" alignItems="center" spacing={2}>
              <Avatar
                size="small"
                sx={{
                  bgcolor: 'white',
                  color: (theme) => theme.palette.secondary.darker,
                  width: 26,
                  height: 26,
                  fontSize: 14
                }}
              >
                {i + 1}
              </Avatar>
              <Typography variant="body2">{t(instruction)}</Typography>
            </Stack>
          ))}
        </Stack>

        <Box
          display="flex"
          flexDirection="column"
          sx={{
            widht: '100%',

            borderRadius: 4
          }}
          mt={6}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.6 } }}
        >
          <Typography variant="overline" sx={{ fontWeight: 600, mb: 1, color: 'white' }} paragraph>
            {t('time-per-participant')}
          </Typography>
          <TimeSelector time={time} setTime={setTime} />
        </Box>
        <Box
          mt={5}
          mb={3}
          sx={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 60
          }}
        >
          <AnimatePresence>
            <Typography variant="h4" sx={{ fontWeight: 600, color: 'white' }}>
              <motion.span
                variants={variants}
                key={index}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  y: { type: 'spring', stiffness: 300, damping: 200 },
                  opacity: { duration: 0.8 }
                }}
              >
                {suggestions[index % suggestions.length]}
              </motion.span>
            </Typography>
          </AnimatePresence>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" mb={0} mx={8}>
        <LoadingButton
          loading={loading}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => {
            soundContext.loadAudio();
            localStorage.setItem('defaultTimer', time);
            setLoading(true);
            // Add meeting type on start
            wsContext.eventsSocket.emit('start');
          }}
          disableElevation
          sx={{
            width: 200,
            height: 50,
            color: (theme) => theme.palette.primary.main,
            borderRadius: 50,

            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white'
            },
            '&.Mui-disabled': { opacity: 1 }
          }}
        >
          {t('start')}
        </LoadingButton>

        <Typography variant="caption" mt={1.5} color="white" align="center">
          {t('host-will-start-meeting-soon')}
        </Typography>
      </Box>
      {/* {(userContext.user.isAdmin || userContext.user.id === admin) && (
  <LoadingButton
    sx={{ borderRadius: 50 }}
    loading={loading}
    size="large"
    variant="contained"
    onClick={() => {
      soundContext.loadAudio();
      localStorage.setItem('defaultTimer', time);
      setLoading(true);
      wsContext.eventsSocket.emit('start');
    }}
    disabled={
      false && connectedParticipants.filter((p) => p.status !== 'left').length < 2
    }
    disableElevation
  >
    {meetingStatus === 'closing' ? 'Ver resultados' : 'Iniciar daily'}
  </LoadingButton>
)} */}

      {/* <Box
  sx={{
    position: 'absolute',
    width: '100%',
    height: '60%',
    left: 20,
    right: 0,
    bottom: -10,
    zIndex: 1001,
    backdropFilter: 'blur(2px)',
    backgroundColor: 'white',
    borderRadius: (theme) => theme.spacing(5, 5, 5, 5)
  }}
/> */}
    </Box>
  );
};

function Content() {
  return (
    <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="row">helo</div>
      <div className="row" />
      <div className="row" />
    </motion.div>
  );
}

const Item = ({ item, layoutId, onClick, index }) => {
  const { title, subtitle, description, duration, disabled, image } = item;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <Stack
      onClick={onClick}
      layoutId={layoutId}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      spacing={2}
      component={motion.div}
      layout
      initial={false}
      whileTap={{ scale: 0.95 }}
      sx={{
        overflow: 'auto',
        position: 'relative',
        backgroundColor: (theme) => theme.palette.backgrounds[0],
        backgroundImage: `url(${
          image || '/static/illustrations/card-backgrounds/card-background-christmas.svg'
        })`,
        backdropFilter: 'blur(4px)',
        color: 'white',
        fill: 'red',
        filter: disabled && 'grayscale(95%)',
        width: '100%',
        height: 190,
        borderRadius: 4,
        p: 3,
        left: 0,
        right: 0,
        top: 0,
        cursor: !disabled && 'pointer'
      }}
    >
      <Box>
        <Typography variant="h3" mb={1}>
          {title.toUpperCase()}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t(description)}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        align="right"
        color="text.secondary.light"
        sx={{ opacity: 0.8 }}
      >
        {duration}
      </Typography>
    </Stack>
  );
};

export default function MeetingSelector({
  adminUser,
  time,
  setTime,
  connectedParticipants,
  isAdmin
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const theme = useTheme();

  const handleClose = () => setSelectedIndex(null);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <AnimateSharedLayout>
        <Paper
          elevation={0}
          {...(selectedIndex === null && { variant: 'outlined' })}
          sx={{ position: 'relative', height: '100%', borderRadius: 4, overflow: 'hidden' }}
        >
          {/* <Scroll
            radius={theme.spacing(4)}
            width="100%"
            height="100%"
            contentHeight={200 * items.length}
            center
          > */}
          <Box sx={{ overflow: 'scroll', height: '100%' }}>
            <Stack spacing={3} sx={{ borderRadius: 4, px: 2, py: 2 }}>
              {items.map((item, i) => (
                <Item
                  item={item}
                  index={i}
                  layoutId={item.id}
                  key={item.id}
                  onClick={() => !item.disabled && setSelectedIndex(i)}
                />
              ))}
            </Stack>
          </Box>
          {/* </Scroll> */}
        </Paper>

        <AnimatePresence>
          {selectedIndex !== null && (
            <MeetingDetails
              connectedParticipants={connectedParticipants}
              selectedItem={items[selectedIndex]}
              adminUser={adminUser}
              time={time}
              setTime={setTime}
              onClose={handleClose}
              isAdmin={isAdmin}
            />
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Box>
  );
}
