import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { blueGrey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import WSContext from '../contexts/WSContext';
import AddMembersDialog from './animate/AddMembersDialog';

const PersonSelector = ({ users, selected, handleToggle, handleClickOpen }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Typography variant="overline">{t('recipient')}</Typography>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={handleClickOpen}
            aria-label="add members"
            sx={{ border: (theme) => `1px solid ${blueGrey[100]}` }}
          >
            <AddIcon sx={{ color: blueGrey[400] }} />
          </IconButton>
        </Grid>
        {users &&
          users
            .filter((user) => selected.includes(user.id))
            .map((user) => (
              <Grid key={`t-${user.id}`} item xs="auto">
                <Chip
                  onDelete={() => handleToggle(user.id)}
                  avatar={<Avatar alt={user.name} src={user.avatar} />}
                  label={user.name.split(' ')[0]}
                />
              </Grid>
            ))}
      </Grid>
    </Stack>
  );
};

export default function QuestionForm({ users, onClose, open }) {
  const { t } = useTranslation();
  const wsContext = React.useContext(WSContext);

  const [question, setQuestion] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);

  const handleSend = () => {
    wsContext.eventsSocket.emit('createNote', {
      name: question,
      users: selected
    });

    handleClose();
  };

  const handleOpenUsersDialog = () => {
    setOpenUsersDialog(true);
  };

  const handleCloseUsersDialog = () => {
    setOpenUsersDialog(false);
  };

  const handleToggle = (value) => {
    const currentIndex = selected.indexOf(value);
    const newselected = [...selected];

    if (currentIndex === -1) {
      newselected.push(value);
    } else {
      newselected.splice(currentIndex, 1);
    }

    setSelected(newselected);
  };

  const handleClose = () => {
    clear();
    onClose();
  };

  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const clear = () => {
    setQuestion('');
    setSelected([]);
  };

  const isDisabled = !question;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 3,
          p: 1
        }
      }}
    >
      {/* <DialogTitle id="scroll-dialog-title">Nueva pregunta</DialogTitle> */}

      <DialogContent>
        <Stack spacing={4}>
          <TextField
            multiline
            fullWidth
            autoFocus
            rows={3}
            color="primary"
            placeholder={t('write-your-question')}
            value={question}
            onChange={handleChangeQuestion}
          />
          <PersonSelector
            users={users}
            selected={selected}
            handleToggle={handleToggle}
            handleClickOpen={handleOpenUsersDialog}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ mt: 1, mb: 1 }}>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button onClick={handleSend} variant="contained" disableElevation disabled={isDisabled}>
          {t('send')}
        </Button>
      </DialogActions>
      <AddMembersDialog
        selected={selected}
        setSelected={setSelected}
        users={users}
        open={openUsersDialog}
        handleClose={handleCloseUsersDialog}
        handleToggle={handleToggle}
      />
    </Dialog>
  );
}
