import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReviewsIcon from '@mui/icons-material/Reviews';
import {
  alpha,
  AvatarGroup,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  acceptSuggestion,
  likeSuggestion,
  rejectSuggestion,
  removeSuggestion,
  unlikeSuggestion
} from '../../api/SuggestionsAPI';
import GeneralContext from '../../contexts/GeneralContext';
import UserContext from '../../contexts/UserContext';
import { fDate } from '../../utils/formatTime';
import Iconify from '../Iconify';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const ReviewPopover = ({ suggestionId, id, open, anchorEl, onClose, refresh }) => {
  const [comment, setComment] = React.useState('');

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleAcceptSuggestion = () => {
    acceptSuggestion(suggestionId, comment).then((res) => {
      if (res.data) {
        refresh();
        onClose();
      }
    });
  };

  const handleRejectSuggestion = () => {
    rejectSuggestion(suggestionId, comment).then((res) => {
      if (res.data) {
        refresh();
        onClose();
      }
    });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      elevation={4}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      sx={{
        '& .MuiPopover-paper': {
          backgroundColor: alpha('#fff', 0.8),
          backdropFilter: 'blur(3px)'
        }
      }}
    >
      <Box sx={{ px: 2, py: 3, width: '100%', height: '100%' }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <TextField
              label="Comentarios"
              onChange={handleChangeComment}
              required
              fullWidth
              color="primary"
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs="auto">
            <IconButton
              disabled={!comment}
              size="large"
              aria-label="reject"
              color="error"
              onClick={handleAcceptSuggestion}
            >
              <CancelIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs="auto">
            <IconButton
              disabled={!comment}
              size="large"
              aria-label="accept"
              color="success"
              onClick={handleRejectSuggestion}
            >
              <CheckCircleIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item container xs={12} mt={2} justifyContent="flex-end" spacing={1} />
        </Grid>
      </Box>
    </Popover>
  );
};

const Priority = ({ value, sx }) => {
  const { t } = useTranslation();

  let priorityIcon;
  let priorityName;
  let priorityColor;

  switch (value) {
    case 2:
      priorityIcon = 'flat-color-icons:medium-priority';
      priorityName = 'Media';
      priorityColor = 'warning';
      break;
    case 3:
      priorityIcon = 'flat-color-icons:high-priority';
      priorityName = 'Alta';
      priorityColor = 'error';

      break;
    default:
      priorityIcon = 'flat-color-icons:low-priority';
      priorityName = 'Baja';
      priorityColor = 'success';
  }

  // return (
  //   <Avatar
  //     size="small"
  //     sx={{
  //       width: 24,
  //       height: 24,
  //       bgcolor: (theme) => alpha(theme.palette[priorityColor].dark, 0.2),
  //       ...sx
  //     }}
  //   >
  //     <PriorityHighIcon
  //       fontSize="small"
  //       color={priorityColor}
  //       sx={{ color: (theme) => theme.palette[priorityColor].dark }}
  //     />
  //   </Avatar>
  // );

  switch (value) {
    case 2:
      priorityIcon = 'flat-color-icons:medium-priority';
      priorityName = 'Media';
      break;
    case 3:
      priorityIcon = 'flat-color-icons:high-priority';
      priorityName = 'Alta';

      break;
    default:
      priorityIcon = 'flat-color-icons:low-priority';
      priorityName = 'Baja';
  }

  return (
    <Tooltip title={`${t('priority')} ${priorityName}`}>
      <IconButton sx={{ cursor: 'default' }} disableFocusRipple disableRipple>
        <Iconify icon={priorityIcon} width={20} height={20} />{' '}
      </IconButton>
    </Tooltip>
  );
};

const GroupAvatars = ({ users, sx }) => (
  <AvatarGroup max={6} sx={sx}>
    {users.map((user, i) => (
      <Avatar key={`av-${i}`} sx={{ width: 24, height: 24 }} alt={user.name} src={user.avatar}>
        {user.name[0].toUpperCase()}
      </Avatar>
    ))}
  </AvatarGroup>
);

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

// userContext.user.id === '401e345f-078b-4592-9eae-554488b70b9b'

export default function SuggestionCard({ item, isOwner, isAdmin, onDelete, refresh }) {
  const generalContext = React.useContext(GeneralContext);
  const userContext = React.useContext(UserContext);
  const { t } = useTranslation();

  const cardEl = React.useRef(null);

  const [liked, setLiked] = React.useState(
    item.likes && item.likes.filter((l) => l.id === userContext.user.id).length
  );

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showReview, setShowReview] = React.useState(false);

  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);

  const optionsOpen = Boolean(optionsAnchorEl);

  const [comment, setComment] = React.useState('');

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleAcceptSuggestion = () => {
    acceptSuggestion(item.id, comment).then((res) => {
      if (res.data) {
        refresh();
        setShowReview(false);
      }
    });
  };

  const handleRejectSuggestion = () => {
    rejectSuggestion(item.id, comment).then((res) => {
      if (res.data) {
        refresh();
        setShowReview(false);
      }
    });
  };

  const handleClickOptions = (event) => {
    setOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setOptionsAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleLike = () => {
    setLiked(!liked);
    if (liked) unlikeSuggestion(item.id);
    else likeSuggestion(item.id);
  };

  const handleClickReview = (event) => {
    setAnchorEl(cardEl.current);
  };

  const handleCloseReview = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getCardColor = () => {
    switch (item.status) {
      case 1:
        return 'warning';
      case 2:
        return 'success';
      default:
        return 'primary';
    }
  };

  return (
    <Card
      ref={cardEl}
      elevation={0}
      sx={{ backgroundColor: (theme) => alpha(theme.palette[getCardColor()].main, 0.1) }}
    >
      <ReviewPopover
        refresh={refresh}
        suggestionId={item.id}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseReview}
      />

      <CardHeader
        avatar={
          <Avatar src={item.user.avatar} aria-label="recipe">
            {item.user.name[0].toUpperCase()}
          </Avatar>
        }
        action={
          <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
            {(isOwner || isAdmin) && (
              <IconButton
                aria-controls={optionsOpen ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsOpen ? 'true' : undefined}
                onClick={handleClickOptions}
                size="small"
                aria-label="remove"
              >
                <MoreVertIcon size="small" />
              </IconButton>
            )}

            {(isOwner || isAdmin) && (
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={optionsAnchorEl}
                open={optionsOpen}
                onClose={handleCloseOptions}
              >
                {(isOwner || isAdmin) && (
                  <MenuItem
                    onClick={() => {
                      removeSuggestion(item.id);
                      handleCloseOptions();
                    }}
                    disableRipple
                  >
                    <DeleteIcon />
                    {t('remove')}
                  </MenuItem>
                )}
                {isAdmin && item.status === 0 && (
                  <MenuItem
                    onClick={() => {
                      // handleClickReview();
                      setShowReview(true);
                      handleCloseOptions();
                      // generalContext.refreshAllSuggestions();
                    }}
                    disableRipple
                  >
                    <ReviewsIcon />
                    {t('review')}
                  </MenuItem>
                )}
              </StyledMenu>
            )}
          </Stack>
        }
        title={<Typography variant="subtitle1">{item.user.name}</Typography>}
        subheader={fDate(item.createdAt)}
      />

      {showReview ? (
        <>
          <CardContent sx={{ pt: 0, pb: 1 }}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 600, mb: 3 }}>
              {item.name}
            </Typography>

            <TextField
              label="Comentarios"
              onChange={handleChangeComment}
              required
              fullWidth
              color="primary"
              multiline
              rows={2}
            />
          </CardContent>
          <CardActions disableSpacing>
            <Button onClick={() => setShowReview(false)}>{t('cancel')}</Button>

            <Box sx={{ marginLeft: 'auto' }}>
              <IconButton
                disabled={!comment}
                size="large"
                aria-label="reject"
                color="error"
                onClick={handleAcceptSuggestion}
              >
                <CancelIcon fontSize="large" />
              </IconButton>
              <IconButton
                disabled={!comment}
                size="large"
                aria-label="accept"
                color="success"
                onClick={handleRejectSuggestion}
              >
                <CheckCircleIcon fontSize="large" />
              </IconButton>
            </Box>
          </CardActions>
        </>
      ) : (
        <>
          <CardContent sx={{ pt: 0, pb: 1 }}>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <Stack>
                <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
                  {item.name} !!
                </Typography>

                <Typography variant="body2" color="text.secondary.light">
                  {item.description}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
          <CardActions disableSpacing>
            <Priority value={item.priority} />

            <IconButton aria-label="like" onClick={handleLike}>
              <FavoriteIcon color={liked ? 'error' : 'inherit'} />
            </IconButton>

            {item.likes && <GroupAvatars users={item.likes} sx={{ ml: 0.5 }} />}

            {item.status !== 0 && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ marginLeft: 'auto' }}
              >
                <Typography align="right" variant="overline" color="text.secondary">
                  Revisión
                </Typography>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Stack>
            )}
          </CardActions>
          {item.status !== 0 && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent sx={{}}>
                <Box sx={{ borderRadius: '20px', backgroundColor: 'white', p: 2 }}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs>
                      <Box>
                        <Typography variant="overline">Tu sugerencia ha sido </Typography>
                        {item.status === 2 ? (
                          <Typography
                            variant="overline"
                            sx={{ color: (theme) => theme.palette.success.darker }}
                          >
                            ACEPTADA
                          </Typography>
                        ) : (
                          <Typography
                            variant="overline"
                            sx={{ color: (theme) => theme.palette.error.darker }}
                          >
                            DESESTIMADA
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs="auto">
                      <Box>
                        <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
                          <Avatar
                            alt={item.reviewedBy.name}
                            src={item.reviewedBy.avatar}
                            sx={{ width: 24, height: 24 }}
                          >
                            {item.reviewedBy.name[0].toUpperCase()}
                          </Avatar>

                          <Typography variant="body2">{item.reviewedBy.name}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>

                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {item.reviewedComment}
                  </Typography>
                </Box>
              </CardContent>
            </Collapse>
          )}
        </>
      )}
    </Card>
  );
}
