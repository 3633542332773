import {
  alpha,
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EmojiPersonaTime from '../components/EmojiPersonaTime';
import Timer from '../components/Timer';
import RouletteContext from '../contexts/RouletteContext';
import WSContext from '../contexts/WSContext';

const customIcons = [
  {
    value: 'veryDissatisfied',
    icon: (
      <Box sx={{ backgroundColor: '#fdcdcd', borderRadius: 2, px: 2 }}>
        <Typography sx={{ fontSize: 48 }}>😣</Typography>
      </Box>
    ),
    label: 'Very Dissatisfied',
    order: 1
  },
  {
    value: 'dissatisfied',
    icon: (
      <Box sx={{ backgroundColor: '#fde2cd', borderRadius: 2, px: 2 }}>
        <Typography sx={{ fontSize: 48 }}>🙁</Typography>
      </Box>
    ),
    label: 'Dissatisfied',
    order: 2
  },
  {
    value: 'neutral',
    icon: (
      <Box sx={{ backgroundColor: '#FDF2CD', borderRadius: 2, px: 2 }}>
        <Typography sx={{ fontSize: 48 }}>😐</Typography>
      </Box>
    ),
    label: 'Neutral',
    order: 3
  },
  {
    value: 'satisfied',
    icon: (
      <Box sx={{ backgroundColor: '#cdd0fd', borderRadius: 2, px: 2 }}>
        <Typography sx={{ fontSize: 48 }}>🙂</Typography>
      </Box>
    ),
    label: 'Satisfied',
    order: 4
  },
  {
    value: 'verySatisfied',
    icon: (
      <Box sx={{ backgroundColor: '#cefdcd', borderRadius: 2, px: 2 }}>
        <Typography sx={{ fontSize: 48 }}>😃</Typography>
      </Box>
    ),
    label: 'Very Satisfied',
    order: 5
  }
];

const GroupAvatars = ({ users, sx }) => (
  <AvatarGroup max={6} sx={sx}>
    {users.map((user, i) => (
      <Avatar key={`av-${i}`} sx={{ width: 24, height: 24 }} alt={user.name} src={user.avatar}>
        {user.name[0].toUpperCase()}
      </Avatar>
    ))}
  </AvatarGroup>
);

const SatisfactionMean = ({ satisfactionResults }) => {
  const countTotal = satisfactionResults.answers.reduce((a, b) => a + b.count, 0);

  let mean = 0;

  satisfactionResults?.answers?.forEach((a) => {
    mean += (a.order + 1) * a.count;
  });

  mean /= countTotal;

  const found = customIcons.find((item) => item.order >= mean.toFixed(0));

  const finalMean = (mean / 5) * 100;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box>{found?.icon}</Box>
      <Typography variant="overline">
        {finalMean % 1 !== 0 ? finalMean.toFixed(1) : finalMean}%
      </Typography>
    </Stack>
  );
};

export default function ResultsPage({ participants, time, satisfactionResults }) {
  const rouletteContext = useContext(RouletteContext);
  const wsContext = useContext(WSContext);

  const { t } = useTranslation();

  const numParticipants = participants.filter((p) => p.status === 'finished').length;

  const fastestParticipants = participants
    .filter((p) => p.status === 'finished')
    .sort((a, b) => a.time - b.time)
    .slice(0, 1);

  const slowestParticipants = participants
    .filter((p) => p.status === 'finished')
    .sort((a, b) => b.time - a.time)
    .slice(0, 1);

  const onTimeParticipants = participants
    .filter((p) => p.status === 'finished' && p.time === time)
    .slice(0, 1);

  const onClickClose = () => {
    wsContext.eventsSocket.emit('close', { type: 'results' });
  };

  return (
    <Grid item container xs md spacing={6}>
      <Grid item container xs={12} md={12} spacing={6}>
        <Grid item xs>
          <Box mb={1}>
            <Typography
              variant="overline"
              sx={{ color: (theme) => alpha(theme.palette.primary.main, 1) }}
            >
              {t('team-satisfaction')}
            </Typography>
          </Box>
          {satisfactionResults ? (
            <SatisfactionMean satisfactionResults={satisfactionResults} />
          ) : (
            <Typography variant="h1">
              <CircularProgress />
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          <Box mb={1}>
            <Typography
              variant="overline"
              sx={{ color: (theme) => alpha(theme.palette.primary.main, 1) }}
            >
              {t('number-of-participants')}
            </Typography>
          </Box>
          <Typography variant="h1">{numParticipants}</Typography>
        </Grid>
        <Grid item xs>
          <Box alignItems="center">
            <Box mb={1}>
              <Typography
                variant="overline"
                sx={{ color: (theme) => alpha(theme.palette.primary.main, 1) }}
              >
                {t('total-time')}
              </Typography>
            </Box>
            <Timer
              size="extra-large"
              time={rouletteContext.timer ? rouletteContext.timer.roulette.totalTime : 0}
              isPositive
            />
          </Box>
        </Grid>
        <Grid item container xs={12} spacing={6}>
          <Grid item xs="auto">
            <Box mb={2}>
              <Typography variant="overline">{t('the-fastest')}</Typography>
            </Box>
            <Grid item container key="other" spacing={3} alignItems="center">
              {fastestParticipants.map((p, i) => (
                <Grid item xs="auto" key={`pt-res-${i}`}>
                  <EmojiPersonaTime type="fast" size="m-large" persona={p} time={time} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs="auto">
            <Box mb={2}>
              <Typography variant="overline">{t('the-slowest')}</Typography>
            </Box>
            <Grid
              item
              container
              key="other"
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              {slowestParticipants.map((p, i) => (
                <Grid item xs="auto" key={`pt-res-${i}`}>
                  <EmojiPersonaTime type="slow" size="m-large" persona={p} time={time} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {Boolean(onTimeParticipants.length) && (
            <Grid item xs="auto">
              <Box mb={2}>
                <Typography variant="overline">{t('in-time')}</Typography>
              </Box>
              <Grid
                item
                container
                key="other"
                component={motion.div}
                initial={{ opacity: 0, x: -3000 }}
                animate={{
                  opacity: [0, 1],
                  x: 0,
                  transition: { type: 'spring', bounce: 0.2, delay: 0.8 }
                }}
                exit={{ x: '-200%', transition: { duration: 0.5, delay: 2 } }}
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                {onTimeParticipants.map((p, i) => (
                  <Grid item xs="auto" key={`pt-res-${i}`}>
                    <EmojiPersonaTime type="on-time" size="m-large" persona={p} time={time} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="overline" color="primary">
          {t('participants')}
        </Typography>

        <Stack
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ x: -3000, transition: { duration: 0.5 } }}
          spacing={3}
          justifyContent="start"
          alignItems="center"
          mt={3}
          direction="row"
        >
          {participants
            .filter((p) => p.status === 'finished')
            .map((p, i) => (
              <EmojiPersonaTime
                key={`pt-${i}`}
                persona={p}
                time={time}
                type={time - p.time >= 0 ? 'good' : 'very-bad'}
              />
            ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
