import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createGroup, getUsers } from '../api/api';
import { MotionContainer, varBounceIn } from '../components/animate';
import AddMembersDialog from '../components/animate/AddMembersDialog';
import { capitalizeEachWord } from '../utils/formatString';

export default function NewGroupPage() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [name, setName] = useState('');
  const [id, setId] = useState('');

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [users, setUsers] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleChangeName = (event) => {
    setName(event.target.value);
    setId(
      event.target.value
        .toLowerCase()
        .replace(/[^a-z0-9]+|\s+/gim, ' ')
        .split(' ')
        .join('-')
    );
  };

  const handleChangeId = (event) => {
    setId(event.target.value);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const controls = useAnimation();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    getUsers().then((res) => {
      if (res.data) {
        const formattedUsers = res.data.map((u) => ({
          ...u,
          name: capitalizeEachWord(u.name)
        }));
        setUsers(formattedUsers);
      }
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (value) => {
    const currentIndex = selected.indexOf(value);
    const newselected = [...selected];

    if (currentIndex === -1) {
      newselected.push(value);
    } else {
      newselected.splice(currentIndex, 1);
    }

    setSelected(newselected);
  };

  const handleCreate = () => {
    setLoading(true);
    setOpenAlert(false);

    createGroup(name, id, selected).then((res) => {
      setLoading(false);
      if (res.data) navigate('/');
      else setOpenAlert(true);
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        width: '100%',
        height: 'calc(100vh - 54px)'
      }}
    >
      <Container maxWidth="sm" sx={{ pt: 6 }}>
        <Card variant="outlined" sx={{ p: 3 }}>
          <AnimatePresence>
            <CardContent>
              <MotionContainer
                open
                initial={{ opacity: 0, x: -100 }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { type: 'spring', bounce: 0.3 }
                }}
                exit={{ opacity: 0, scasle: 0 }}
              >
                <motion.div variants={varBounceIn} animate={controls}>
                  <Box>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {t('new-group')}
                    </Typography>
                  </Box>
                </motion.div>

                <Divider sx={{ mt: 2, mb: 4 }} />

                <Stack spacing={4}>
                  <TextField
                    required
                    value={name}
                    onChange={handleChangeName}
                    label={t('group-name')}
                    inputProps={{ maxLength: 25 }}
                  />

                  <TextField
                    required
                    value={id}
                    onChange={handleChangeId}
                    label={t('group-id')}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 25 }}
                  />
                  <Stack spacing={2}>
                    <Typography variant="body1">{t('add-members')}</Typography>
                    <Box sx={{ height: 100, overflow: 'scroll' }}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <IconButton
                            size="small"
                            onClick={handleClickOpen}
                            aria-label="add members"
                            sx={{ border: (theme) => `1px solid ${blueGrey[100]}` }}
                          >
                            <AddIcon sx={{ color: blueGrey[400] }} />
                          </IconButton>
                        </Grid>
                        {users &&
                          users
                            .filter((user) => selected.includes(user.id))
                            .map((user) => (
                              <Grid key={`t-${user.id}`} item xs="auto">
                                <Chip
                                  onDelete={() => handleToggle(user.id)}
                                  avatar={<Avatar alt={user.name} src={user.avatar} />}
                                  label={user.name.split(' ')[0]}
                                />
                              </Grid>
                            ))}
                      </Grid>
                    </Box>
                  </Stack>
                </Stack>

                <Divider sx={{ mb: 4, mt: 0.5 }} />

                <Stack direction="row" justifyContent="flex-end" spacing={3} sx={{ mt: 4, mb: 2 }}>
                  <Button sx={{ color: theme.palette.grey[600] }} onClick={() => navigate('/')}>
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    loading={loading}
                    disabled={!name || !id || !selected.length}
                    disableElevation
                    variant="contained"
                    onClick={handleCreate}
                    sx={{ width: 100 }}
                  >
                    {t('create')}
                  </LoadingButton>
                </Stack>
              </MotionContainer>
            </CardContent>
          </AnimatePresence>

          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
              {t('No se ha podido crear el grupo. Inténtalo de nuevo')}
            </Alert>
          </Snackbar>
        </Card>
      </Container>

      <AddMembersDialog
        selected={selected}
        setSelected={setSelected}
        users={users}
        open={open}
        handleClose={handleClose}
        handleToggle={handleToggle}
      />
    </Box>
  );
}
