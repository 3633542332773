import React, { useState } from 'react';
import { getSpeech } from '../api/api';

const SoundContext = React.createContext();

export const SoundProvider = (props) => {
  const loadAudio = () => {
    state.globalPlayer.setAttribute(
      'src',
      'https://rulex-audios.s3.eu-de.cloud-object-storage.appdomain.cloud/Buzz.wav'
    );
    state.globalPlayer.load();

    state.player.load();

    state.playerAlt.load();

    if (localStorage.getItem('default_muted')) {
      state.player.muted = Boolean(localStorage.getItem('default_muted'));
      state.globalPlayer.muted = Boolean(localStorage.getItem('default_muted'));
      state.playerAlt.muted = Boolean(localStorage.getItem('default_muted'));
    }
  };

  const toggleMute = () => {
    state.globalPlayer.muted = !state.globalPlayer.muted;
    state.player.muted = !state.player.muted;
    state.playerAlt.muted = !state.playerAlt.muted;

    if (!state.muted) localStorage.setItem('default_muted', true);
    else localStorage.removeItem('default_muted');

    setState((prevState) => ({
      ...prevState,
      muted: !prevState.muted
    }));
  };

  const playBuzz = () => {
    state.globalPlayer.volume = 0.2;

    // state.globalPlayer.setAttribute(
    //   'src',
    //   'https://rulex-audios.s3.eu-de.cloud-object-storage.appdomain.cloud/Buzz.wav'
    // );
    // state.globalPlayer.load();
    if (state.globalPlayer.duration <= 0 || state.globalPlayer.paused) state.globalPlayer.play();
  };

  const playRouletteSpin = () => {
    state.player.setAttribute(
      'src',
      'https://rulex-audios.s3.eu-de.cloud-object-storage.appdomain.cloud/Mountain-Audio-Digital-Casino-Short-Roulette%20Spin%204.wav'
    );
    state.player.load();
    state.player.play();
  };

  const playWinner = () => {
    state.player.volume = 0.1;
    state.player.setAttribute(
      'src',
      'https://rulex-audios.s3.eu-de.cloud-object-storage.appdomain.cloud/Merch-Select.wav'
    );
    state.player.load();
    state.player.play();
  };

  const playFinish = () => {
    state.player.setAttribute(
      'src',
      'https://rulex-audios.s3.eu-de.cloud-object-storage.appdomain.cloud/Sparkling-Finish.wav'
    );
    state.player.load();
    state.player.play();
  };

  const playWAV = (data) => {
    const blobUrl = URL.createObjectURL(data);

    state.playerAlt.setAttribute('src', blobUrl);
    state.playerAlt.load();
    state.playerAlt.play();
  };

  const playText = (text) => {
    getSpeech(text).then((res) => {
      if (res.data) {
        const blobUrl = URL.createObjectURL(res.data);

        state.playerAlt.setAttribute('src', blobUrl);
        state.playerAlt.load();
        state.playerAlt.play();
      }
    });
  };

  const initialState = {
    musicPlayer: new Audio(),
    globalPlayer: new Audio(),
    player: new Audio(),
    playerAlt: new Audio(),
    muted: Boolean(localStorage.getItem('default_muted')),
    loadAudio,
    playRouletteSpin,
    playWinner,
    playFinish,
    playWAV,
    playText,
    toggleMute,
    playBuzz
  };

  const [state, setState] = useState(initialState);

  return <SoundContext.Provider value={state}>{props.children}</SoundContext.Provider>;
};

export default SoundContext;
