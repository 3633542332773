import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
      <Box
        component="img"
        src="/static/illustrations/rulex-logo-simple.svg"
        sx={{ height: 22, ...sx }}
      />
      {/* <Box
        component="img"
        src="/static/illustrations/christmas/leaf.png"
        sx={{ height: 26, ...sx }}
      /> */}
    </Stack>
  );
}
