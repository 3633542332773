import { Alert, Box, Container, Grid, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getGroupParticipants } from '../api/api';
import { removeLocalSession } from '../api/setupAxios';
import { getRouletteEvents } from '../api/SocketAPI';
import Chat from '../components/Chat/Chat';
import ChatPopover from '../components/ChatPopover';
import ConfigBottomMenu from '../components/ConfigBottomMenu';
import CreatePollForm from '../components/CreatePollForm';
import LoadingBox from '../components/LoadingBox';
import PollForm from '../components/PollForm/PollForm';
import QuestionForm from '../components/QuestionForm';
import ChatContext from '../contexts/ChatContext';
import RouletteContext from '../contexts/RouletteContext';
import SoundContext from '../contexts/SoundContext';
import UserContext from '../contexts/UserContext';
import WSContext from '../contexts/WSContext';
import ConfigPage from './ConfigPage';
import QuestionsPage from './QuestionsPage';
import ResultsPage from './ResultsPage';
import RoulettePage from './RoulettePage';

const RootStyle = ({ children, sx }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      height: 'calc(100vh - 170px)',
      pt: 2,
      ...sx
    }}
  >
    {children}
  </Box>
);

export default function MeetingPage() {
  const wsContext = useContext(WSContext);
  const rouletteContext = useContext(RouletteContext);
  const chatContext = useContext(ChatContext);
  const soundContext = useContext(SoundContext);
  const userContext = useContext(UserContext);

  const { id } = useParams();

  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const pageControls = useAnimation();

  const [status, setStatus] = useState(null);

  const [loading, setLoading] = useState(true);

  const [meetingCreatorId, setMeetingCreatorId] = useState(null);

  const [meetingInfo, setMeetingInfo] = useState(null);

  const [startTime, setStartTime] = useState(null);

  const [numQuestions, setNumQuestions] = useState(0);

  const [participants, setParticipants] = useState([]);
  const [messages, setMessages] = useState([]);

  const [poll, setPoll] = useState(false);
  const [satisfactionResults, setSatisfactionResults] = useState(false);

  const [openCreatePollForm, setOpenCreatePollForm] = useState(false);
  const [openQuestionForm, setOpenQuestionForm] = useState(false);

  const [chatOpened, setChatOpened] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [time, setTime] = useState(null);
  const [groupName, setGroupName] = useState(null);

  const isAdmin = userContext.user?.isAdmin || userContext.user?.id === meetingCreatorId;

  const connectedParticipants =
    participants && participants.filter((p) => p.status !== 'left' && p.status !== 'disconnected');

  const updateTimer = (t) => {
    wsContext.eventsSocket.emit('update', { time: t });
    setTime(t);
  };

  const handleOpenCreatePollForm = () => {
    setOpenCreatePollForm(true);
  };

  const handleCloseCreatePollForm = () => {
    setOpenCreatePollForm(false);
  };

  const handleOpenQuestionForm = () => {
    setOpenQuestionForm(true);
  };

  const handleCloseQuestionForm = () => {
    setOpenQuestionForm(false);
  };

  const handleClickBuzz = () => {
    wsContext.eventsSocket.emit('buzz');
  };

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const toggleChat = () => {
    chatContext.setUnreadMessages(false);
    setChatOpened(!chatOpened);
  };

  const handleClosePollForm = () => {
    setPoll(false);
  };

  const loginListener = (args) => {
    if (args.statusCode !== 200) {
      console.error('Login roulette WS failed');
      console.error('status', args.statusCode);
      console.error('token', localStorage.getItem('authentication-token'));

      removeLocalSession();
      navigate('/login', { state: { from: window.location.pathname } });
    }
    wsContext.eventsSocket.emit('join', { id });
  };

  const joinListener = (args) => {
    if (args.statusCode !== 200) {
      navigate('/404', { replace: true });
    }

    setMeetingInfo(args.message);

    const createdAtDate = new Date(args.message.createdAt);
    const formattedStartTime = `${createdAtDate.getHours()}:${createdAtDate.getMinutes()}`;
    setStartTime(formattedStartTime);

    setStatus(args.message.status);
    setMeetingCreatorId(args.message.adminId);

    setLoading(false);

    setTime(args.message.time);

    if (args.message.group) {
      setGroupName(args.message.group.name);
      getGroupParticipants(args.message.group.id).then((res) => {
        if (res.data) {
          setParticipants((prevParticipants) => {
            const missing = [];
            let cont = prevParticipants.length;
            res.data.forEach((p) => {
              const user = {
                ...p,
                active: false,
                index: cont,
                status: 'disconnected'
              };

              const found = prevParticipants.find((element) => element.id === user.id);
              if (!found) {
                missing.push(user);
                cont += 1;
              }
            });

            return [...prevParticipants, ...missing];
          });
        }
      });
    }
  };

  const userListener = (args) => {
    const user = {
      ...args.message.user,
      status: args.message.status || 'disconnected',
      time: args.message.time
    };

    setParticipants((participants) => {
      const found = participants.find((element) => element.id === user.id);
      if (found) {
        found.status = args.message.status;
        found.createdAt = args.message.createdAt;
        found.time = args.message.time;

        return [...participants];
      }

      return [...participants, { ...user, index: participants.length }];
    });
  };

  const rouletteListener = (args) => {
    setStatus(args.message.roulette.status);
    setNumQuestions(args.message.roulette.nQuestions);
    setTime(args.message.roulette.time);
  };

  const messageListener = (args) => {
    if (!args.message) return;

    const newMessage = {
      content: args.message.data,
      time: args.message.createdAt,
      user: args.message.user
    };

    setMessages((prev) => [newMessage, ...prev]);
  };

  const winnerListener = (args) => {
    rouletteContext.setWinner(args.message.user);
  };

  const timerListener = (args) => {
    rouletteContext.setTimer(args.message);
  };

  const pollCreationListener = (args) => {
    setPoll(args.message);
  };

  const pollResultsListener = (args) => {
    if (args.message.type === 'mood') setSatisfactionResults(args.message);
    else setPoll(args.message);
  };

  const buzzListener = (args) => {
    soundContext.playBuzz();

    setTimeout(
      () =>
        pageControls.start({
          x: [20, 0, -20, 0],
          transition: { type: 'spring', bounce: 1, duration: 0.2 }
        }),
      100
    );
  };

  useEffect(() => {
    const socket = getRouletteEvents();
    wsContext.setEventsSocket(socket);

    return () => socket.close();
  }, [wsContext.setEventsSocket]);

  useEffect(() => {
    if (wsContext.eventsSocket) {
      wsContext.eventsSocket.on('login', loginListener);
      wsContext.eventsSocket.on('join', joinListener);
      wsContext.eventsSocket.on('user', userListener);
      wsContext.eventsSocket.on('roulette', rouletteListener);
      wsContext.eventsSocket.on('message', messageListener);
      wsContext.eventsSocket.on('winner', winnerListener);
      wsContext.eventsSocket.on('timer', timerListener);
      wsContext.eventsSocket.on('createPoll', pollCreationListener);
      wsContext.eventsSocket.on('pollResults', pollResultsListener);
      wsContext.eventsSocket.on('buzz', buzzListener);

      if (!wsContext.eventsSocket.connected) wsContext.eventsSocket.connect();
    }

    return () => {
      if (wsContext.eventsSocket) {
        wsContext.eventsSocket.off('login', loginListener);
        wsContext.eventsSocket.off('join', joinListener);
        wsContext.eventsSocket.off('user', userListener);
        wsContext.eventsSocket.off('roulette', rouletteListener);
        wsContext.eventsSocket.off('message', messageListener);
        wsContext.eventsSocket.off('winner', winnerListener);
        wsContext.eventsSocket.off('timer', timerListener);
        wsContext.eventsSocket.off('createPoll', pollCreationListener);
        wsContext.eventsSocket.off('pollResults', pollResultsListener);
        wsContext.eventsSocket.off('buzz', buzzListener);
      }
    };
  }, [wsContext.eventsSocket]);

  useEffect(() => {
    if (messages.length && !chatContext.anchorEl && !chatOpened)
      chatContext.setUnreadMessages(true);

    return () => {};
  }, [messages]);

  const showContent = () => {
    if (loading || !wsContext.eventsSocket)
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 'calc(90vh - 64px - 120px)' }}
        >
          <LoadingBox />
        </Box>
      );

    // if (!status)
    //   return (
    //     <ConfigPage
    //       meetingCreatorId={meetingCreatorId}
    //       meetingStatus={status}
    //       time={time}
    //       setTime={updateTimer}
    //       connectedParticipants={participants}
    //       chatOpened={chatOpened}
    //       isAdmin={isAdmin}
    //     />
    //   );

    switch (status) {
      case 'running':
      case 'finished':
        return <RoulettePage participants={participants} time={time} isAdmin={isAdmin} />;
      case 'closed':
        return <Navigate to="/" replace />;
      case 'results':
        return (
          <ResultsPage
            participants={participants}
            time={time}
            satisfactionResults={satisfactionResults}
          />
        );
      case 'notes':
        return <QuestionsPage socket={wsContext.eventsSocket} isAdmin={isAdmin} />;
      default:
        return (
          <ConfigPage
            meetingName={meetingInfo.name}
            meetingCreatorId={meetingCreatorId}
            meetingStatus={status}
            startTime={startTime}
            time={time}
            setTime={updateTimer}
            connectedParticipants={participants}
            chatOpened={chatOpened}
            isAdmin={isAdmin}
          />
        );
    }
  };

  return (
    <Container maxWidth="xl">
      <RootStyle>
        <AnimatePresence>
          <Grid
            rowSpacing={1}
            columnSpacing={4}
            component={motion.div}
            animate={pageControls}
            container
            direction="row"
            justifyContent="center"
            alignItems="start"
            sx={{ height: '100%' }}
          >
            {showContent()}
            {!loading && chatOpened && (
              <Grid
                component={motion.div}
                initial={{ opacity: 0, x: -500, y: 500, scale: 0, transition: { delay: 0.5 } }}
                animate={{ opacity: 1, x: 0, y: 0, scale: 1, transition: { duration: 0.3 } }}
                exit={{ opacity: 0, x: -500, y: 500, scale: 0, transition: { duration: 0.3 } }}
                item
                md={3.5}
                sx={{
                  height: '100%',
                  display: { xs: 'none', md: 'none', lg: 'block' },
                  zIndex: 100
                }}
              >
                <Box sx={{ height: '100%', minHeight: 480, minWidth: 300, py: 2 }}>
                  <Chat key="chat" outlined messages={messages} />
                </Box>
              </Grid>
            )}
          </Grid>
        </AnimatePresence>
      </RootStyle>

      <ConfigBottomMenu
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          bottom: '20px',
          borderRadius: '40px',
          right: 0,
          left: 0,
          margin: 'auto',
          width: 'fit-content',
          zIndex: 900
        }}
        onClickChat={toggleChat}
        onClickQuestion={handleOpenQuestionForm}
        onClickPoll={handleOpenCreatePollForm}
        onClickBuzz={handleClickBuzz}
        openSnackbar={handleClickSnackbar}
        status={status}
        isAdmin={isAdmin}
        numQuestions={numQuestions}
        satisfactionResults={satisfactionResults}
      />

      {matches && <ChatPopover messages={messages} />}
      <CreatePollForm open={openCreatePollForm} onClose={handleCloseCreatePollForm} />
      <PollForm key={poll?.id} poll={poll} onClose={handleClosePollForm} />

      <QuestionForm
        users={connectedParticipants}
        open={openQuestionForm}
        onClose={handleCloseQuestionForm}
      />

      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Enlace copiado al portapapeles
        </Alert>
      </Snackbar>
    </Container>
  );
}
