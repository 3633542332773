import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useTranslation } from 'react-i18next';
import { toHHMMSS } from '../../utils/formatTime';

const SampleNextArrow = ({ className, style, onClick }) => (
  <div
    style={{
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Box>
      <IconButton aria-label="delete" onClick={onClick} size="small">
        <NavigateNext />
      </IconButton>
    </Box>
  </div>
);

const SamplePrevArrow = ({ className, style, onClick }) => (
  <div
    style={{
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999
    }}
  >
    <Box>
      <IconButton aria-label="delete" onClick={onClick} size="small">
        <NavigateBefore />
      </IconButton>
    </Box>
  </div>
);

const settings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

const Satisfaction = ({ value, variation }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2}>
      <Stack justifyContent="center" alignItems="center" spacing={1}>
        <GaugeChart
          id="gauge-chart2"
          hideText
          nrOfLevels={5}
          percent={value / 100}
          textColor="#000"
          colors={['#FF5F6D', '#8bc34a']}
          style={{ width: 100 }}
        />
      </Stack>
      <Box>
        <Stack direction="row" spacing={1} sx={{ mb: '-10px' }} alignItems="center">
          <Typography variant="h4">{value !== undefined ? Math.floor(value) : '-'}%</Typography>
          {/* <TrendingUp sx={{ color: (theme) => theme.palette.success.main }} />
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.success.main }}>
              {variation > 0 ? '+' : '-'}
              {variation}%
            </Typography> */}
        </Stack>
        <Typography variant="caption" sx={{ color: grey[400] }}>
          {t('last-30-days')}
        </Typography>
      </Box>
    </Stack>
  );
};

const Time = ({ value, variation }) => {
  const formattedTime = toHHMMSS(value);

  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2}>
      <Box>
        <Stack direction="row" spacing={1} sx={{ mb: '-8px' }} alignItems="center">
          {value ? (
            <Typography variant="h4">
              {formattedTime[0] !== undefined && formattedTime[0] !== '00'
                ? `${formattedTime[0]} h`
                : ''}{' '}
              {formattedTime[1]}min {Math.trunc(formattedTime[2])}seg
            </Typography>
          ) : (
            <Typography variant="h4">- min - seg</Typography>
          )}
          {/* <TrendingUp sx={{ color: (theme) => theme.palette.error.main }} />
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.error.main }}>
              {variation > 0 ? '+' : '-'}
              {variation}%
            </Typography> */}
        </Stack>
        <Typography variant="caption" sx={{ color: grey[400] }}>
          {t('last-30-days')}
        </Typography>
      </Box>
    </Stack>
  );
};

const Chart = ({ type, value, variation }) => {
  switch (type) {
    case 'satisfaction':
      return <Satisfaction value={value} variation={variation} />;
    default:
      return <Time value={value} variation={variation} />;
  }
};

const Statistic = ({ title, value, variation, type }) => (
  <Card
    variant="outlined"
    sx={{
      borderRadius: '15px !important',
      width: '100%',
      backgroundColor: grey[900],
      color: 'white',
      height: '100%'
    }}
  >
    <Box
      px={2}
      py={1}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Chart type={type} value={value} variation={variation} />

      <Typography
        mt={1}
        noWrap
        align="end"
        variant="overline"
        color="text.light"
        sx={{ fontWeight: 700 }}
      >
        {title}
      </Typography>
    </Box>
  </Card>
);

export default function TeamStatistics({ groupName, avgSatisfaction, avgTime }) {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState(null);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="overline" noWrap color="text.secondary" mb={10}>
          {groupName}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6} md={12}>
          <Statistic
            title={t('team-satisfaction')}
            value={avgSatisfaction}
            variation={2.5}
            type="satisfaction"
          />
        </Grid>

        <Grid item xs={6} md={12}>
          <Statistic title={t('roulette-time')} value={avgTime} variation={2.5} />
        </Grid>
      </Grid>
    </Box>
  );
}
