import { Skeleton, Stack, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInternationalDay } from '../../api/api';
import Iconify from '../../components/Iconify';

export const TodayDate = () => {
  const theme = useTheme();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const { i18n } = useTranslation();

  const locale = `${i18n.language}-${i18n.language.toUpperCase()}`;

  const [inernationalDay, setInernationalDay] = useState(null);

  const currentLanguage = localStorage.getItem('language') || 'en';

  useEffect(() => {
    const init = async () => {
      const datesArray = (await getInternationalDay()).data;
      setInernationalDay(datesArray[0]);
    };
    init();
  }, []);

  return (
    <Stack
      display="inline-flex"
      direction="row"
      justifyContent="center"
      alignItems="center"
      mr={{ xs: 2.5, sm: 6 }}
      spacing={1.5}
      sx={{ height: '100%' }}
    >
      <Iconify
        icon="clarity:world-line"
        width={18}
        height={18}
        color={alpha(theme.palette.secondary.main, 0.5)}
      />

      <Typography variant="caption" sx={{ fontWeight: 500, maxWidth: 800 }} color="text.secondary">
        {}
        {currentLanguage === 'en'
          ? new Date().toLocaleDateString(locale, options)
          : inernationalDay || <Skeleton sx={{ width: 100 }} />}
      </Typography>
    </Stack>
  );
};
