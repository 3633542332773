import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';

const BajajAreaChartCard = ({ data }) => {
  const theme = useTheme();

  // Agrupar datos por semana
  const groupByMonth = (inputData) => {
    const months = {};
    inputData.forEach((item) => {
      const date = new Date(item.createdAt);
      const month = `${date.getFullYear()}-${date.getMonth()}`;
      if (!months[month]) {
        months[month] = [];
      }
      months[month].push(item);
    });
    return months;
  };

  const monthlyData = groupByMonth(data && data.actual);
  const categories = Object.keys(monthlyData)
    .sort((a, b) => {
      const [yearA, monthA] = a.split('-');
      const [yearB, monthB] = b.split('-');
      return yearA === yearB ? monthA - monthB : yearA - yearB;
    })
    .map((month) => {
      const [year, monthNumber] = month.split('-');
      const date = new Date(year, monthNumber);
      const monthName = date.toLocaleString('default', { month: 'long' });

      return `${monthName} ${year}`;
    });

  const series = [
    {
      data: Object.values(monthlyData).map(
        (monthItems) => monthItems.reduce((total, item) => total + item.mood, 0) / monthItems.length
      )
    }
  ];

  const options = {
    chart: {
      // toolbar: {
      //   show: false,
      //   tools: {
      //     download: false,
      //     selection: false,
      //     zoom: false,
      //     zoomin: false,
      //     zoomout: false,
      //     pan: false,
      //     reset: false,
      //     customIcons: []
      //   }
      // }
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          const moodCategories = [
            'Muy insatisfecho',
            'Insatisfecho',
            'Satisfecho',

            'Muy satisfecho'
          ];
          const index = Math.min(Math.round(val) - 1, moodCategories.length - 1);
          return moodCategories[index];
        }
      },
      min: 1,
      max: 4,
      tickAmount: 3
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: 'smooth'
    },
    tooltip: {
      shared: false
    },
    xaxis: {
      categories
    }
  };

  return (
    <>
      <Chart options={options} series={series} type="line" />
    </>
  );
};

export default BajajAreaChartCard;
