import {
  alpha,
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
// material
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GeneralContext from '../contexts/GeneralContext';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool
};

function NavItem({ item, active, onClick }) {
  const { t } = useTranslation();

  const theme = useTheme();
  const generalContext = useContext(GeneralContext);

  const isActiveRoot = active;

  const { title, id, icon, info, children } = item;

  const num = generalContext[`${id}Suggestions`]?.length;
  const unread = generalContext.unreadSuggestions[id]?.length;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={onClick}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={t(title)} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={t(title)} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      onClick={onClick}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={t(title)} />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mr: 2, ml: 1 }}
      >
        {/* {Boolean(unread) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: (theme) => theme.palette.error.main,
              p: (theme) => theme.spacing(0.5, 1, 0.5, 1),
              borderRadius: '20px'
            }}
          >
            <Typography variant="caption" color="white" sx={{ fontWeight: 700 }}>
              {unread} nuevas
            </Typography>
          </Box>
        )} */}

        <Typography variant="overline">{num}</Typography>
      </Stack>

      {/* <Typography
        variant="overline"
        sx={{
          mr: 2,
          ml: 1,
          backgroundColor: 'teal',
          borderRadius: '50%',
          p: 1,
          width: 20,
          height: 20
        }}
      >
        {num}
      </Typography> */}
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, onChange, selected, ...other }) {
  return (
    <Box {...other}>
      <List sx={{ p: 1 }}>
        {navConfig.map((item, index) => (
          <NavItem
            key={item.title}
            item={item}
            onClick={() => {
              onChange(index);
            }}
            active={selected === index}
          />
        ))}
      </List>
    </Box>
  );
}
