import { Box, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GeneralContext from '../contexts/GeneralContext';
import UserContext from '../contexts/UserContext';
import GroupSelectorForm from './GroupSelectorForm';
import ActiveMeetingAvatar from './MeetingAvatar/ActiveMeetingAvatar';
import NewMeetingMenu from './NewMeetingMenu';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { scale: 0 },
  show: { scale: 1 }
};

export default function Meetings({ setOpenBackdrop, setOpenAlert }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const generalContext = useContext(GeneralContext);
  const userContext = useContext(UserContext);

  const [link, setLink] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const activeGroups =
    generalContext.groups && generalContext.groups.filter((g) => g.activeRoulettes.length);

  const handleJoin = () => {
    const meetingParts = link.split('/');
    const meetingId = meetingParts[meetingParts.length - 1];
    if (meetingId) navigate(`/roulettes/${meetingId}`);
  };

  const handleChange = (event) => {
    setLink(event.target.value);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Typography variant="h3" noWrap sx={{ fontWeight: 100, mb: 2 }}>
        {t('meetings-progress')}
      </Typography>

      <>
        <Stack
          data-tut="new_rulex"
          jusitfyContent="center"
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ widht: '100%' }}
          mb={4}
        >
          <NewMeetingMenu
            disabled={!activeGroups}
            handleClickOpen={handleClickOpen}
            setOpenBackdrop={setOpenBackdrop}
            setOpenAlert={setOpenAlert}
          />

          <Stack
            alignItems="flex-end"
            justifyContent="flex-start"
            direction="row"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <TextField
              sx={{ maxWidth: 320 }}
              fullWidth
              label={t('meeting-link')}
              value={link}
              onChange={handleChange}
            />
            <AnimatePresence>
              {link !== '' && (
                <Box
                  component={motion.div}
                  animate={{ opacity: [0, 1], scale: [1, 1.1, 1] }}
                  exit={{ opacity: 0 }}
                >
                  <Link component="button" size="large" underline="none" onClick={handleJoin}>
                    {t('join')}
                  </Link>
                </Box>
              )}
            </AnimatePresence>
          </Stack>
        </Stack>
        {!activeGroups ||
          (!activeGroups.length && (
            <Typography variant="body2" color="text.secondary">
              {t('no-meetings')}
            </Typography>
          ))}
      </>

      <Grid
        container
        spacing={5}
        component={motion.ol}
        variants={container}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        <AnimatePresence>
          {activeGroups &&
            activeGroups.map((group, i) =>
              group.activeRoulettes.map((activeRoulette, index) => (
                <Grid
                  key={`active-avatar-${activeRoulette.groupId}-${index}`}
                  item
                  xs="auto"
                  component={motion.div}
                  variants={item}
                >
                  <ActiveMeetingAvatar
                    redirectId={activeRoulette.id}
                    title={group.name}
                    subtitle={activeRoulette.name || `${t('room')} ${index + 1}`}
                    index={index}
                  />
                </Grid>
              ))
            )}
        </AnimatePresence>
      </Grid>

      <GroupSelectorForm
        open={openDialog}
        handleClose={handleCloseDialog}
        groups={userContext.user?.isAdmin ? generalContext.allGroups : generalContext.groups}
        setOpenBackdrop={setOpenBackdrop}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
}
