import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(2)
}));

export default function NoConnectionPage() {
  return (
    <RootStyle title="Network error">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Hmmm...
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              No hemos podido contactar con el servidor. Por favor, comprueba tu conexión a Internet
              y vuelve a intentarlo más tarde
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/no-connection.png"
                sx={{ height: 300, mx: 'auto', my: { xs: 2, sm: 4 } }}
              />
            </motion.div>

            <Button
              startIcon={<RefreshIcon />}
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
              disableElevation
            >
              Reintentar
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
