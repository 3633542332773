import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import GeneralPoll from './GeneralPoll';
import SatisfactionPoll from './SatisfactionPoll';

export default function PollForm({ onClose, poll }) {
  const getPoll = () => {
    switch (poll.type) {
      case 'mood':
        return <SatisfactionPoll poll={poll} handleClose={onClose} />;
      default:
        return <GeneralPoll poll={poll} handleClose={onClose} />;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      disableEscapeKeyDown
      onBackdropClick={() => {}}
      open={Boolean(poll)}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 5
        }
      }}
    >
      <DialogContent>{getPoll()}</DialogContent>
    </Dialog>
  );
}
