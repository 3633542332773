import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

export default function StartTutorialDialog({ open, handleClose, handleOpenTutorial }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 10
        }
      }}
      maxWidth="xs"
    >
      <Box
        sx={{
          px: 4,
          py: 6,
          background: 'linear-gradient(9deg, rgba(255,255,255,0) 25%, rgba(120,142,237,1) 100%)'
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paper elevation={12} sx={{ px: 4, py: 5, width: 'fit-content', borderRadius: 10 }}>
          <Logo />
        </Paper>
        <Box mt={8}>
          <Typography variant="h5">{t('onboarding-title')} </Typography>
          <Typography variant="body1">{t('onboarding-subtitle')}</Typography>
        </Box>

        <Stack direction="row" spacing={2} mt={3} mb={1} alignSelf="flex-start">
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              handleClose();
              handleOpenTutorial();
            }}
          >
            {t('onboarding-start-action')}
          </Button>
          <Button onClick={handleClose}>{t('onboarding-discard-action')}</Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
