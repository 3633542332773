import { Box, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { generateLoginToken } from '../api/AuthenticationAPI';
import { setLocalSession } from '../api/setupAxios';
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: 'calc(95vh - 64px)',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function LoginPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const location = useLocation();
  const from = location.state?.from?.pathname || localStorage.getItem('originLink') || '/';

  const animateControls = useAnimation();
  const showLogin = !code;

  useEffect(() => {
    const codeVerifier = localStorage.getItem('codeChallenge');

    if (code && codeVerifier)
      generateLoginToken(code, codeVerifier).then((res) => {
        if (res.data) {
          const { accessToken } = res.data;
          setLocalSession(accessToken);
          navigate(from || '/', { replace: true });
        }
      });
  }, []);

  return (
    <RootStyle title="Ruulex">
      {showLogin ? (
        <>
          <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box
              component={motion.img}
              animate={animateControls}
              src="/static/illustrations/initial.png"
              sx={{ width: 280 }}
            />
            {/* <Box
              component={motion.img}
              animate={animateControls}
              src="/static/illustrations/christmas/santa-tree.png"
              sx={{ width: 380 }}
            /> */}
          </SectionStyle>

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack sx={{ mb: 5 }}>
                <Box
                  mb={3}
                  component="img"
                  src="/static/illustrations/rulex-logo-simple.svg"
                  sx={{ width: 200 }}
                />
                <Typography variant="h4" gutterBottom>
                  {t('login')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{t('optimize-meetings')}</Typography>
              </Stack>

              <LoginForm animateControls={animateControls} />
            </ContentStyle>
          </Container>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 'calc(88vh - 64px)', width: '100%' }}
        >
          <Stack spacing={6} justifyContent="center" alignItems="center">
            <Box
              component={motion.img}
              animate={{ scale: [1, 1.3, 1.3, 1.3, 1, 1], rotate: [0, 0, 10, 0, 0] }}
              transition={{ repeat: Infinity, duration: 2.2 }}
              src="/static/illustrations/initial.png"
              sx={{ width: 180, mx: 'auto' }}
            />
            <Typography
              sx={{ color: 'text.secondary' }}
              component={motion.div}
              animate={{ rotate: [0, 0, 10, 0, 0] }}
              transition={{ repeat: Infinity, duration: 2.2, delay: 0.2 }}
            >
              {t('login-progress')}...
            </Typography>
          </Stack>
        </Box>
      )}
    </RootStyle>
  );
}
